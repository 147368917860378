/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.metadata-buttons__button {
  margin-right: 30px !important; }

.gl-table td {
  text-align: center; }

.meta-status.validation-error {
  color: #ffffff;
  background: #e63f3f; }

.meta-status.validation-success {
  color: #ffffff;
  background: #00aa55; }
