.withTooltip {
  position: relative;
  display: inline-block;
  width: auto;
}

.withTooltip[type='button'] {
  cursor: pointer;
  height: 20px
}

.withTooltip svg {
  width: 20px;
}

.withTooltip:before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
  z-index: 99;
  opacity: 0;
}

/* Tooltip text */
.withTooltip:after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
  color: #fff;
  font-size: 14px;
  min-width: 80px;
  border-radius: 5px;
  pointer-events: none;
  padding: 4px 4px;
  z-index: 99;
  opacity: 0;
  white-space: normal;
  word-wrap: break-word;
}

/* Show the tooltip text when you mouse over the tooltip container */
.withTooltip:hover::before,
.withTooltip:hover::after {
  opacity: 1;
}
