.AdminPanel {
  position: relative;

  &--header {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }
  
  .gl-icon {
    display: none;
  }
}

.edit-workgroup-border {
  border: solid 1px black;
}

h5 {
  margin: 1rem 0 !important;
}
.user-created-success-message {
  margin-top: 5vh;;
  color: #00aa55;
}
.user-created-failure-message {
  margin-top: 5vh;;
  color: #e63f3f
}
.container-row-input-mail {
  width: 100%;
}
.user-deletion-status-table {
  height: fit-content !important;
}