/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.date-hidden .gl-tooltip__button {
  cursor: not-allowed;
  pointer-events: none;
  color: #c0c0c0;
  background-color: #ffffff;
  visibility: hidden !important; }

.gl-tooltip--disabled {
  opacity: 0.6; }

.player-info {
  background: #d9dcdd;
  width: 100%;
  border: 1px solid #d9dcdd;
  border-bottom: none; }
  .player-info__column {
    padding: 15px; }
  .player-info__checkbox-column {
    max-width: 10px; }

.playlist-info {
  margin-bottom: 30px;
  width: 100%;
  border-collapse: collapse; }
  .playlist-info--disabled {
    opacity: 0.6; }
  .playlist-info .playlist-info__header {
    background-color: #ebedee;
    border-color: #d9dcdd;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #000000;
    text-align: center;
    font-size: 13px; }
    .playlist-info .playlist-info__header--left {
      text-align: left; }
  .playlist-info__button--disabled {
    opacity: 0.6; }
  .playlist-info__column {
    padding: 15px;
    border: 1px solid #ebedee;
    text-align: center; }
    .playlist-info__column .date-hidden {
      cursor: not-allowed;
      pointer-events: none;
      color: #c0c0c0;
      background-color: #ffffff;
      visibility: hidden !important; }
    .playlist-info__column .react-datepicker__time-container {
      width: 95px; }
      .playlist-info__column .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width: 93px; }
    .playlist-info__column .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 100px; }
    .playlist-info__column--left {
      text-align: left; }
    .playlist-info__column .gl-tooltip__panel--large {
      width: 530px; }
      .playlist-info__column .gl-tooltip__panel--large .react-datepicker {
        padding: 0; }
      .playlist-info__column .gl-tooltip__panel--large .react-datepicker__time-container--with-today-button {
        right: -100px; }
  .playlist-info__checkbox-column {
    max-width: 10px; }
  .playlist-info__column--center {
    display: flex;
    justify-content: left;
    align-items: center; }
    .playlist-info__column--center .gl-tooltip__button {
      margin: 0; }
  .playlist-info__select-day {
    padding: 5px 10px;
    border: 1px solid #000000;
    background: #ffffff;
    margin: 0 10px 10px; }
    .playlist-info__select-day--selected {
      color: #ffffff;
      background: #000000; }
  .playlist-info--archived {
    opacity: 0.8; }
  .playlist-info .react-datepicker__day-name,
  .playlist-info .react-datepicker__day,
  .playlist-info .react-datepicker__time-name {
    width: 48px !important; }
  .playlist-info .react-datepicker--time-only {
    margin-right: 30px; }

.playlist-info__column-checkbox {
  margin-left: 20px; }

.player-info__column--status {
  text-align: right; }
  .player-info__column--status .gl-cta {
    margin-left: 20px; }

.schedule-playlist-info {
  margin-bottom: 30px;
  width: 100%;
  border-collapse: collapse; }
  .schedule-playlist-info--disabled {
    opacity: 0.6; }
  .schedule-playlist-info .playlist-info__header {
    background-color: #ebedee;
    border-color: #d9dcdd;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #000000;
    text-align: center;
    font-size: 13px; }
    .schedule-playlist-info .playlist-info__header--left {
      text-align: left; }
  .schedule-playlist-info__button--disabled {
    opacity: 0.6; }
  .schedule-playlist-info__column .date-hidden {
    cursor: not-allowed;
    pointer-events: none;
    color: #c0c0c0;
    background-color: #ffffff;
    visibility: hidden !important; }
  .schedule-playlist-info__column .react-datepicker__time-container {
    width: 95px; }
    .schedule-playlist-info__column .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 93px; }
  .schedule-playlist-info__column .react-datepicker__day {
    width: 48px !important; }
  .schedule-playlist-info__column .react-datepicker {
    border: 1px solid #aeaeae !important;
    border-radius: 0.3rem !important;
    display: inline-block !important;
    outline: 0px !important; }
  .schedule-playlist-info__column .react-datepicker__navigation--next {
    right: 10px !important;
    top: 8px !important; }
  .schedule-playlist-info__column .react-datepicker__navigation--previous {
    left: 10px !important;
    top: 8px !important; }
  .schedule-playlist-info__column .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 100px; }
  .schedule-playlist-info__column--left {
    text-align: left; }
  .schedule-playlist-info__column .gl-tooltip__panel--large {
    width: 530px; }
    .schedule-playlist-info__column .gl-tooltip__panel--large .react-datepicker__time-container--with-today-button {
      right: -100px; }
  .schedule-playlist-info__checkbox-column {
    max-width: 10px; }
  .schedule-playlist-info__column--center {
    display: flex;
    justify-content: left;
    align-items: center; }
    .schedule-playlist-info__column--center .gl-tooltip__button {
      margin: 0; }
  .schedule-playlist-info__select-day {
    padding: 5px 10px;
    border: 1px solid #000000;
    background: #ffffff;
    margin: 0 10px 10px; }
    .schedule-playlist-info__select-day--selected {
      color: #ffffff;
      background: #000000; }
  .schedule-playlist-info--archived {
    opacity: 0.8; }
  .schedule-playlist-info .react-datepicker__day-name,
  .schedule-playlist-info .react-datepicker__day,
  .schedule-playlist-info .react-datepicker__time-name {
    width: 48px !important; }
  .schedule-playlist-info .react-datepicker--time-only {
    margin-right: 30px; }

.player-container {
  position: relative;
  margin-bottom: 15px; }
  .player-container .gl-callout {
    width: 100%;
    max-width: 100%;
    text-align: center; }

.scheduling-needs-attention {
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  background: rgba(255, 194, 194, 0.9);
  z-index: 100;
  display: flex;
  text-align: center;
  padding: 20px 0;
  margin: 0 20px;
  color: #000; }
  .scheduling-needs-attention .gl-checkbox,
  .scheduling-needs-attention .gl-form-item {
    display: inline-block;
    opacity: 1; }
  .scheduling-needs-attention div {
    margin: auto;
    flex: 1; }

.react-select__placeholder {
  white-space: nowrap !important; }

.react-select__value-container {
  min-width: 70px; }

.react-select__value-container {
  width: 70px; }

.react-select__option {
  text-align: left !important; }

.player-description {
  margin-top: 10px;
  font-size: 18px;
  font-weight: normal; }

.player-sections-header {
  font-size: 22px;
  margin-bottom: 20px !important; }

.player-sections-header-inactive {
  margin-top: 30px !important; }

.player-status__column {
  font-size: 16px;
  padding: 0px !important; }

.padding-right {
  padding-right: 10px !important; }

.gl-toggle .gl-menu__item {
  border: none !important;
  border-bottom: 1px solid #d9dcdd !important; }

.margin-top-5 {
  margin-top: 5px; }

.select-frame {
  margin-top: 15px;
  font-weight: normal;
  font-style: inherit;
  margin-right: 15px;
  text-transform: uppercase;
  color: gr;
  font-size: 13px;
  letter-spacing: 2px; }

.status-dot {
  height: 21px;
  width: 21px;
  margin-right: 12px;
  border-radius: 50%;
  display: inline-block; }

.status-active {
  background-color: #00aa55; }

.status-inactive {
  background-color: #e32b2b; }

.status-unknown {
  background-color: #767677; }

.status-unhealthy {
  background-color: #ede734; }

.margin-auto-l-r {
  margin-left: auto;
  margin-right: auto; }

.margin-top-40 {
  margin-top: 50px !important; }

.gl-toggle {
  margin: 0 0 15px 0 !important; }

.gl-form-item {
  margin: auto !important; }

.checkbox-styling-no-center .gl-form-item {
  margin: 0px !important;
  padding-right: 10px; }

.checkbox-styling-no-center {
  font-size: 14px;
  display: inline-flex; }

.gl-checkbox__input {
  margin: 0px !important; }

.deactivate-section .gl-form-item {
  padding-bottom: 10px; }

.deactivate-section .gl-checkbox__text {
  padding-left: 10px !important; }

.scheduling-table-step3, .schedule-details-table .genetic-table-container {
  height: auto !important;
  max-height: none !important;
  margin-bottom: 5px;
  overflow: inherit !important; }

.scheduling-table-step3 {
  overflow: none !important; }

.deactivate-button {
  margin: auto 30px auto 10px !important; }

.scheduleDetails .generic-table__label--action, .scheduleDetails2 .generic-table__label--action {
  width: 10% !important; }

.scheduleDetails .generic-table__label--playlistname, .scheduleDetails2 .generic-table__label--playlistname {
  width: 30% !important; }

.scheduleDetails .generic-table__label--startdate, .scheduleDetails2 .generic-table__label--startdate,
.scheduleDetails .generic-table__label--enddate, .scheduleDetails2 .generic-table__label--enddate,
.scheduleDetails .generic-table__label--playforever, .scheduleDetails2 .generic-table__label--playforever,
.scheduleDetails .generic-table__label--takeover, .scheduleDetails2 .generic-table__label--takeover {
  width: 15% !important; }

.schedule-playlist-info__column .gl-tooltip {
  margin-left: 0px !important; }

.scheduleDetails .generic-table__column--playlistname, .scheduleDetails2 .generic-table__column--playlistname,
.scheduleDetails .generic-table__column--startdate, .scheduleDetails2 .generic-table__column--startdate,
.scheduleDetails .generic-table__column--enddate, .scheduleDetails2 .generic-table__column--enddate,
.scheduleDetails .generic-table__column--playforever, .scheduleDetails2 .generic-table__column--playforever,
.scheduleDetails .generic-table__column--takeover, .scheduleDetails2 .generic-table__column--takeover {
  padding-left: 15px; }

.frame-names-toggle .gl-toggle {
  height: fit-content !important; }

.tooltip-apply-button {
  display: flex !important; }

.apply-button {
  margin-right: 5px !important; }

.validation-success {
  color: #00aa55; }

.player-name-styling {
  margin: 0px !important;
  padding: 10px 0px !important;
  color: black;
  font-size: 20px; }
