.badge {
  background-color: #e6e6e6;
  border-radius: 2px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  position: relative;
  &--outline {
    background-color: #ffffff;
    border: 1px solid #000000;
    margin: 0 5px 5px 0;
    padding: 0;
    border-radius: 0;
    cursor: pointer;
    .badge__label {
      padding-right: 10px;
    }
    .badge__remove {
      min-height: 25px;
      border-radius: 0;
      border-left: 1px solid #000;
    }
    &.selected {
      .badge__label {
        font-weight: 600;
      }
      &:after {
        display: block;
      }
    }
    &:after {
      bottom: 0px;
      content: '';
      display: none;
      height: 1px;
      left: -1px;
      position: absolute;
      width: calc(100% + 2px);
      background-color: #000;
    }
    &:hover {
      transition: all 0.1s cubic-bezier(0.3, 0, 0.45, 1);
      &:after {
        display: block;
      }
    }
  }
  &__label {
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    overflow: hidden;
    padding: 3px;
    padding-left: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }
  &__remove {
    align-items: center;
    border-radius: 2px;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    min-height: 24px;
    &:hover {
      background-color: #ffbdad;
      cursor: pointer;
      svg {
        fill: #de350b;
      }
    }
  }
}
