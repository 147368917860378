.playlist-selected__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inputs-wrapper {
    display: flex;

    & > div:not(:first-child) {
      margin-left: 0.5rem;
    }
  }

  .basic-multi-select {
    max-width: 440px;
  }
}

.playlist-selected__header-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-bottom: 1%;

  h4 {
    margin-bottom: 0;
    word-break: break-all;
  }
}

.playlist-selected__header--data {
  span {
    margin-right: 15px;
  }
}

.playlist-selected__icon {
  cursor: pointer;
  margin-left: 0.5rem;
}

.playlist-selected_input {
  min-width: 300px;
  width: 25vw;
}

.playlist-selected {
  
  .table-option__container {
    top: 23px;
  }
}
