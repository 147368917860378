.health-logs {
  &__container {
    .gl-table__scrollable .generic-table {
      &__row {
        td {
          white-space: normal;
          text-align: left;
        }
      }
      &__column {
        &--description {
          max-width: 700px;
        }
        &--details {
          li {
            font-size: 13px;
          }
        }
      }
    }
  }
}
