.logs {
    &__container {
      &__filter_toggle {
        display: flex;
        justify-content: flex-end;
      }
      &__filters {
        margin-bottom: 40px;
        border: 1px solid #ebedee;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        flex-direction: column;
        &--loading {
          width: 100%;
          text-align: center;
          margin-top: 10px;
        }
        &--hidden {
          display: none;
        }
        &--error {
          padding: 20px;
        }
        &__refine {
          width: 100%;
          margin: 0 !important;
          padding: 10px;
          display: flex;
          align-items: center;
          font-weight: normal;
          justify-content: flex-end;
          &--buttons {
            text-align: center;
            button:last-child {
              margin-left: 15px;
            }
            .gl-loader {
              margin-top: 20px;
            }
          }
        }
        &__item {
          width: 100%;
          padding: 15px;
          border-bottom: 1px solid #ebedee;
          &--user {
            order: 2;
          }
          &--operationType {
            order: 3;
          }
          &--playlistName {
            border-bottom: none;
            order: 5;
          }
          &--scheduleType {
            order: 4;
          }
          &--playerName {
            order: 1;
          }
          .gl-label {
            font-size: 1rem;
          }
          .react-select__input {
            input {
              height: 30px !important;
            }
          }
        }
      }
    }
  }
  .schedulingLogs {
    margin-top: 0;
    .generic-table__column {
      &--operationtype,
      &--scheduletype {
        text-transform: capitalize;
      }
      &--createtimestamp {
        max-width: 190px;
      }
    }
  }
  
  .activity-log-title {
    margin-bottom: 0px !important;
  }