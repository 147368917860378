/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.drag-and-drop__overlay__media {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(45deg, #a7c1b9 16.67%, #ffffff 16.67%, #ffffff 50%, #a7c1b9 50%, #a7c1b9 66.67%, #ffffff 66.67%, #ffffff 100%);
  background-size: 4.24px 4.24px;
  opacity: 0.7;
  text-align: center;
  color: #000000;
  z-index: 999;
  font-size: 56px;
  margin-top: -1%; }

.media-tags {
  font-size: inherit;
  line-height: inherit;
  list-style: none; }

.generic-table__column--showtags ul li {
  display: block !important; }

.drag-and-drop__overlay--message__media {
  margin-top: 20%;
  text-transform: uppercase; }
