/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.player-status__icon {
  margin-right: 20px; }
  .player-status__icon title {
    display: none; }
  .player-status__icon--error {
    fill: #e63f3f; }
  .player-status__icon--success {
    fill: #00aa55; }
  .player-status__icon--warning {
    fill: #ede734; }

.player-status__column {
  display: flex;
  align-items: center;
  padding: 0 35px 0 0; }
  .player-status__column svg {
    margin-right: 20px;
    width: 30px; }
  .player-status__column--active {
    color: #00aa55; }
    .player-status__column--active svg {
      fill: #00aa55; }
  .player-status__column--inactive, .player-status__column--overdue {
    color: #ede734; }
    .player-status__column--inactive svg, .player-status__column--overdue svg {
      fill: #ede734; }

.player-status__playback {
  font-weight: bold; }
  .player-status__playback .health__link {
    cursor: pointer; }
    .player-status__playback .health__link:hover, .player-status__playback .health__link:focus {
      text-decoration: underline; }
  .player-status__playback--healthy {
    color: #00aa55; }
  .player-status__playback--unhealthy, .player-status__playback--overdue {
    color: #e63f3f; }

.player-status-table {
  height: auto !important; }

.health__link {
  cursor: pointer; }
  .health__link:hover, .health__link:focus {
    text-decoration: underline; }
