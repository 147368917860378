/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.custom-modal__button-container {
  margin-top: 2rem; }

.custom-modal__button--primary {
  margin-right: 1rem; }

.custom-modal__item {
  height: 40px;
  word-break: break-all; }
  .custom-modal__item td {
    border-bottom: 1px solid #98989a;
    max-width: 100%; }

.custom-modal__item:last-child {
  padding-bottom: 1rem; }

.custom-modal__list {
  margin: 1rem 0;
  width: 100%; }

.marginTop {
  margin-top: 20px; }

.validation-error {
  color: #e63f3f; }

.validation-success {
  color: #00aa55; }

.basic-multi-select {
  min-width: 300px; }

.edit-table-2__column--grey {
  background: #f7f7f7; }

.edit-table-2__column--full-width {
  max-width: 100% !important; }

.edit-table-2 {
  display: flex;
  flex-wrap: wrap; }
  .edit-table-2__column {
    max-width: 50%;
    flex: 1 0 50%;
    flex-basis: 0;
    padding: 0 15px 20px;
    margin-right: 20px;
    margin-top: 20px;
    border: 1px solid #98989a; }

.validity-row {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 1px solid #98989a;
  padding: 15px 15px 5px;
  border-radius: 4px;
  position: relative;
  flex-wrap: wrap; }
  .validity-row__column {
    width: 33%;
    margin-left: 0.25%; }
    .validity-row__column--button {
      margin-bottom: 10px; }
  .validity-row__header {
    position: absolute;
    font-size: 14px;
    top: -11px;
    left: 12px;
    margin-top: 0;
    padding: 2px 5px 1px;
    background-color: #ffffff;
    color: #98989a; }
  .validity-row .gl-tooltip__panel {
    width: 290px; }
    .validity-row .gl-tooltip__panel .react-datepicker {
      border: 1px solid #aeaeae !important;
      border-radius: 0.3rem !important;
      display: inline-block !important;
      outline: 0px !important; }
    .validity-row .gl-tooltip__panel .react-datepicker__navigation--next {
      right: 10px !important;
      top: 8px !important; }
    .validity-row .gl-tooltip__panel .react-datepicker__navigation--previous {
      left: 10px !important;
      top: 8px !important; }
  .validity-row__applyAll {
    margin-top: 20px;
    width: 100%; }
  .validity-row__expandAll {
    display: flex;
    justify-content: flex-end; }
  .validity-row__adminExpandAll {
    display: flex;
    justify-content: space-between; }
    .validity-row__adminExpandAll .expandAll--button {
      margin-top: 1rem;
      white-space: nowrap;
      height: fit-content; }
    .validity-row__adminExpandAll .expand-collapse-all--buttons {
      display: flex; }

.file-upload__container {
  margin-bottom: 2%; }
  .file-upload__container .file-input {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer; }
    .file-upload__container .file-input:hover {
      background-color: black;
      color: white; }
    .file-upload__container .file-input input[type='file'] {
      display: none; }

.scrollableContent {
  max-height: 340px;
  margin: 0;
  box-sizing: border-box; }

.custom-modal--fullscreen .gl-modal__main {
  height: 580px !important;
  max-height: 100vh !important;
  overflow: hidden; }

.custom-modal--fullscreen .generic-container {
  margin: 0px; }

.custom-modal--fullscreen .selectMediaToPlaylists {
  height: 230px !important; }

.custom-modal--fullscreen .selectMediaToPlaylists-height {
  height: 180px !important; }

.custom-modal--fullscreen .selectsubPlaylistsToPlaylists {
  height: 230px !important; }

.custom-modal--fullscreen .selectsubPlaylistsToPlaylists-height {
  height: 180px !important; }

.custom-modal--fullscreen .selectPlaylistsToAddMedia {
  height: 265px !important; }

.custom-modal--fullscreen .selectPlaylistsToAddMedia-height {
  height: 220px !important; }

.custom-modal--fullscreen .logs__container__filters__item {
  padding: 0px 10px 0px 0px !important; }

.button-styling {
  position: fixed; }

.custom-modal--fullscreen .gl-menu .gl-toggle {
  margin: 0px !important; }

.custom-modal--fullscreen .gl-modal--large .gl-modal__main,
.custom-modal--fullscreen .gl-modal--large .gl-modal__dialog,
.custom-modal--fullscreen .gl-modal__dialog {
  max-height: 90vh;
  max-width: 90vw;
  width: 90vw !important;
  max-width: 90vw !important; }

.custom-modal--fullscreen {
  height: 92vh !important; }

.multi-column-list {
  columns: 4;
  width: 100%;
  list-style-position: inside;
  overflow-y: auto; }

.multi-column-list-height {
  height: 64px; }

.custom-modal--fullscreen .gl-modal--large .gl-modal__main,
.gl-modal__main {
  overflow-x: hidden; }

.custom-modal--fullscreen .modal-header {
  display: flex;
  align-items: baseline; }

.custom-modal--fullscreen .modal-header .secondary-info {
  padding-left: 14px; }

.custom-modal .generic-table-container {
  margin: 0; }

.custom-modal .gl-callout {
  width: 100%;
  max-width: 100%; }

.custom-modal__thumb {
  width: 30px;
  float: left;
  margin-right: 15px;
  font-size: 10px;
  text-align: center; }

.preview-modal .gl-modal__main {
  position: relative;
  min-height: 90vh;
  height: 90vh;
  overflow: visible; }

.preview-modal .gl-modal__main-content,
.preview-modal .custom-modal {
  width: 100%;
  height: 100%; }

.preview-modal h4 {
  word-break: break-all;
  height: auto;
  display: flex;
  margin: 0;
  padding: 0;
  margin-bottom: 16px; }

.preview-modal img,
.preview-modal video {
  margin: auto;
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: relative; }

.preview-modal .modal-items__wrapper {
  display: flex;
  width: 100%;
  position: relative;
  height: 100%;
  max-height: 100%; }

.preview-modal .modal-items__wrapper-inner {
  display: flex;
  flex-direction: column;
  height: 81vh;
  max-height: 81vh;
  width: 100%; }

.preview-modal .modal-items__preview,
.preview-modal .no-preview-info {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  float: left;
  margin-left: 10px; }

.preview-modal .modal-items__preview-playlist {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  float: left;
  margin-left: 45px;
  width: 40%;
  object-fit: cover; }

.preview-modal .modal-items__description {
  height: auto;
  display: flex;
  flex-direction: column;
  float: right;
  width: 30%;
  height: 500px; }

.preview-modal .modal-items__description-playlist {
  height: auto;
  display: flex;
  flex-direction: column;
  float: right;
  margin-right: 45px;
  height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden; }

.preview-modal__wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 100%; }

.preview-modal .item-number {
  text-align: center;
  position: relative; }

.preview-modal .no-preview-info {
  width: 30%;
  margin-top: 200px;
  margin-left: 200px;
  justify-content: center; }

.preview-modal .no-preview-info p {
  text-align: center;
  vertical-align: middle;
  display: table-cell; }

.modal-items__table .generic-table__column {
  min-width: 240px;
  max-width: 240px;
  height: auto;
  word-break: break-all; }

.gl-input__field:disabled {
  background: #ebedee; }

.adding-items__loader .custom-loader {
  justify-content: left; }
  .adding-items__loader .custom-loader .gl-loader {
    width: 30px;
    height: 30px;
    border-width: 1px; }

.selected-items {
  margin-bottom: 2rem; }
  .selected-items li {
    list-style: disc;
    margin-left: 1em; }

.selected-items-list {
  margin-bottom: 0 !important; }

.custom-modal__name {
  word-break: break-all; }

.marginLeft2 {
  margin-left: 2rem; }

.marginRight {
  margin-right: 1rem; }

.marginLeft {
  margin-left: 1rem; }

.preview-items__tooltip {
  margin-top: 15px; }

.tags__container,
.workgroup__container {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 1px solid #98989a;
  padding: 15px;
  border-radius: 4px;
  position: relative;
  flex-wrap: wrap; }
  .tags__container--button,
  .workgroup__container--button {
    margin-top: 10px; }

.tags__header,
.workgroup__header {
  position: absolute;
  font-size: 14px;
  top: -11px;
  left: 12px;
  margin-top: 0;
  padding: 2px 5px 1px;
  background-color: #ffffff;
  color: #98989a; }

.tags__selectedtags,
.workgroup__selectedtags {
  margin-bottom: 20px; }

.tags__badges,
.workgroup__badges {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  box-sizing: border-box; }

.tags__selections,
.workgroup__selections {
  display: flex;
  width: 65%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 20px;
  border-right: 1px dashed #e6e6e6; }
  .tags__selections.tags__multiple,
  .workgroup__selections.tags__multiple {
    width: 100%;
    padding: 0;
    border: none; }
  .tags__selections .gl-label,
  .workgroup__selections .gl-label {
    width: 100%; }
  .tags__selections .tags__maincategory,
  .workgroup__selections .tags__maincategory {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px; }
  .tags__selections .tags__subcategory,
  .workgroup__selections .tags__subcategory {
    width: 100%; }
    .tags__selections .tags__subcategory .react-select__multi-value,
    .workgroup__selections .tags__subcategory .react-select__multi-value {
      display: none; }
    .tags__selections .tags__subcategory .react-select__clear-indicator,
    .workgroup__selections .tags__subcategory .react-select__clear-indicator {
      display: none; }

.tags__selectedtags,
.workgroup__selectedtags {
  padding-left: 20px;
  width: 35%; }
  .tags__selectedtags.tags__multiple,
  .workgroup__selectedtags.tags__multiple {
    width: 100%;
    border: none;
    padding: 20px 0 0; }

.desc__container textarea {
  border-radius: 4px; }

.desc__header {
  position: absolute;
  font-size: 14px;
  top: -11px;
  left: 12px;
  margin-top: 0;
  padding: 2px 5px 1px;
  background-color: #ffffff;
  color: #98989a; }

.messages-input .gl-form-item {
  width: 90%; }

.messages-input .gl-input {
  min-height: auto; }

.messages-title {
  font-weight: bold; }

.height-auto {
  height: auto; }

.display-flex {
  display: flex;
  width: 100%; }

.padding-right-5 {
  padding-right: 5px; }

.padding-left-5 {
  padding-left: 5px; }

.margin-bottom-15 {
  margin-bottom: 15px; }

.margin-top-15 {
  margin-top: 15px; }

.red-text {
  color: red; }

.uppercase {
  text-transform: uppercase; }

.font-size-14 {
  font-size: 14px; }

.capitalize {
  text-transform: capitalize; }

.screenshot-error-screen .gl-box--urgent {
  margin: auto; }

.screenshot-error-screen {
  text-align: center;
  margin: auto;
  width: 80%;
  height: 100%;
  display: flex; }

.screenshot-content {
  max-height: 450px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center; }

.screenshot-image {
  max-height: 400px; }

.screenshot-image-container {
  overflow: auto;
  height: 350px; }

.width-100 {
  width: 100%; }

.display-flex {
  display: flex; }

.margin-left-auto {
  margin-left: auto; }

.margin-top-15 {
  margin-top: 15px; }

.link-style {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  margin-left: auto; }

@media (min-width: 960px) {
  .gl-modal__dialog .gl-modal__main {
    max-height: 100vh; }
  .gl-modal__dialog {
    margin-right: 10px !important;
    margin-left: 10px !important; } }

.confirm-frame-edit-modal {
  z-index: 6 !important; }

.confirm-frame-edit-modal .gl-modal__main {
  padding: 20px !important; }

.preview-modals-table tr:nth-child(odd) {
  background-color: #dededed0; }

.preview-modals-table .generic-table__column {
  width: 20px;
  height: 2px;
  word-break: break-all; }

.frame-edit-modal .gl-modal__main {
  margin-right: 20px !important;
  margin-left: 20px !important; }

.frame-edit-modal .gl-modal__close {
  margin-right: 20px !important; }

.fixed-buttons {
  position: fixed;
  margin-top: 0px; }

.fixed-buttons-height {
  margin-top: 0px; }

.fixed-buttons-media {
  position: fixed;
  margin-top: 55px; }

.fixed-buttons-media-height {
  margin-top: 0px; }

.select-items-header {
  margin-bottom: 10px; }

.selected-media {
  margin-top: 2%;
  width: 50%;
  float: left; }

.selected-sub-playlists {
  margin-top: 2%;
  width: 50%;
  float: left; }

.addition-summary {
  margin-top: 10%;
  clear: both; }

.addition-summary-table {
  max-height: 200px !important;
  height: 349px !important; }

.truncate-items-list {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold; }

.marginLeftValidityDates {
  margin-left: -3.9% !important; }

.validity-row-input .gl-checkbox__text {
  margin-left: 3%; }

.leftAlignEditModal {
  margin-left: -15px !important; }

.marginLeftTags {
  margin-left: -1% !important; }
