/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.table-option__container {
  left: -6px;
  position: absolute; }
  .table-option__container .gl-link:not(.gl-cta--disabled):focus:not(:active),
  .table-option__container .gl-link:not(.gl-cta--disabled):hover:not(:active) .gl-tooltip__button {
    background: none !important; }
  .table-option__container .gl-tooltip__button {
    width: 10px; }
    .table-option__container .gl-tooltip__button:hover, .table-option__container .gl-tooltip__button:active {
      background: none !important; }
      .table-option__container .gl-tooltip__button:hover .header-user__ellipsis, .table-option__container .gl-tooltip__button:active .header-user__ellipsis {
        fill: #a7c1b9; }
  .table-option__container .header-user__ellipsis {
    fill: black; }
  .table-option__container .table-options__row {
    display: flex;
    margin-bottom: 7px; }
    .table-option__container .table-options__row.disabled {
      opacity: 0.3;
      cursor: not-allowed; }
    .table-option__container .table-options__row--zero-column, .table-option__container .table-options__row--first-column, .table-option__container .table-options__row--second-column, .table-option__container .table-options__row--third-column {
      display: flex;
      justify-content: center;
      align-items: center; }
    .table-option__container .table-options__row--zero-column {
      flex: 0 0 8px;
      margin-right: -2%;
      margin-block-start: 0.6ch; }
    .table-option__container .table-options__row--first-column {
      flex: 0 0 38px;
      font-family: monospace; }
    .table-option__container .table-options__row--second-column {
      flex: 0 0 40px; }
    .table-option__container .table-options__row--third-column {
      flex: 0 0 auto; }

.while-sorting {
  z-index: 9999;
  display: flex;
  opacity: 0.5; }
  .while-sorting .table-options__row--zero-column,
  .while-sorting .table-options__row--first-column,
  .while-sorting .table-options__row--second-column,
  .while-sorting .table-options__row--third-column {
    display: flex;
    justify-content: center;
    align-items: center; }
  .while-sorting .table-options__row--zero-column {
    flex: 0 0 18px; }
  .while-sorting .table-options__row--first-column {
    flex: 0 0 38px; }
  .while-sorting .table-options__row--second-column {
    flex: 0 0 40px; }
  .while-sorting .table-options__row--third-column {
    flex: 0 0 auto; }
