/**
 * adidas
 * 1. Support
 * 2. Imports
 * 3. Core
 */
/**
 * 1. Support
 */
/* stylelint-disable declaration-property-value-blacklist  */
/**
 * Shared mixins
 * 1. Plumbing
 * 2. Porcelain
 */
/**
 * 1. Plumbing
 * Internal mixins, only used by foundations
 */
/**
 * Font face manager
 * @param {string} $font-name - The font family name. First element is used if this value is a list.
 * @param {string} $file-path - The file path to the font family.
 * @param {string} $font-weight [normal] - The font weight of the font family.
 * @param {string} $font-style [normal] - The font style of the font family.
 * @param {map} $font-extra-type - Contains an extra font type definition *format* and *type*.
 */
/**
 * 2. Porcelain
 * Public mixins
 */
/**
 * Font definition
 * @param {string} $fontFamily - The name of the font family you want to use.
 * @param {string} $weight [null] - Weight for selected font family.
 * @param {number} $size [null] - Size for selected font family.
 * @param {number} $lineHeight [null] - Line height for selected font family.
 */
/**
 * Pick the specific font from the body typeface
 * @param {string} $variant [null] - weight variant
 */
/*
 * Pick the specific font from the heading typeface
 * Doesn't change font-size, letter-spacing, line-height or text-transform.
 * Should only be used on HTML headings elements (h1, h2, h3, h4 or h5)
 * @param {string} $variant
 */
/*
 * Label
 * @param {string} $variant
 * @param {string} $weight
 * @param {bool} $condensed
 */
/**
 * Responsive breakpoint manager
 * @param {string} $breakpoint - The breakpoint for the element. Check available [breakpoints](#grid-variable-breakpoints)
 * @param {string} $direction [$media-direction] - The default `$media-direction` will be used.
 * @param {string} $custom-breakpoint [null] - Only use if extremely necessary.
 */
/**
 * Themification mixin
 * For providing separate styling per theme
 * @param {string} $attr [null] - Theme
 * @example
 *   // Will only generate CSS in Reebok CSS.
 *   @include themify('reebok') { color: green; }
 */
/**
 * 2. Imports
 */
/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
/**
* General Typography Configuration
*
* 1. Configuration
* 2. Variables Assignments
* 3. Mappings
*/
/**
* 1. Configuration
*/
/**
* 2. Variables Assignments
* 2.1 Body
*/
/**
* 2.2 Headings
*/
/**
* 2.2.1 Headings Variants
*/
/**
* 2.3 Prices
*/
/**
* 2.4 Labels
*/
/**
* 2.6 Bold
*/
/**
* 3. Mappings
*/
/**
 * adidas specific mappings for gl-buttons
 */
/**
 * adidas Theme Definitions
 */
/**
 * 3. Core
 */
/**
 * Core
 *
 * The config folder holds configuration definitions to be used across our foundations.
 * The base folder holds boilerplate content. It holds the styles to use in any context, either apps, .com, creative services crm, etc..
 * The components folder holds all your micro layout files. Your styles for buttons and navigation and similar page components.
 * Misc styles for external or fetched content that we are not able to style directly.
 *
 * 1. Config
 * 2. Base
 * 3. Components
 * 4. Misc
 */
/**
 * 1. Config
 */
/**
 * General Animations Variables
 */
/**
 * General Grid Specification Variables
 */
/**
 * 2.  Base
 */
/* stylelint-disable declaration-no-important  */
/**
 * General Helpers
 * 1. Visibility
 * 2. Positioning
 *  2.1. Text Alignments
 * 3. Vertical spacing
 * 4. No margin
 * 5. screen readers
 */
/**
 * 1. Visibility
 */
/**
 * Use `.gl-hidden-s` to hide any element in small devices such as mobiles.
 */
@media (max-width: 599px) {
  .gl-hidden-s {
    display: none !important; } }

/**
 * Use `.gl-hidden-s-m` to hide any element in small and medium devices such as mobiles and tablets.
 */
@media (max-width: 959px) {
  .gl-hidden-s-m {
    display: none !important; } }

/**
 * Use `.gl-hidden-m` to hide any element on medium devices such as tablets.
 */
@media (min-width: 600px) {
  .gl-hidden-m {
    display: none !important; } }

/**
 * Use `.gl-hidden-l` to hide any element on large devices such as desktops.
 */
@media (min-width: 960px) {
  .gl-hidden-l {
    display: none !important; } }

/**
 * Use `.gl-hidden-xl` to hide any element on extra large devices such as desktops.
 */
@media (min-width: 1280px) {
  .gl-hidden-xl {
    display: none !important; } }

/**
 * Use `.gl-hidden-hg` to hide any element on huge devices such as uncommon desktop sizes.
 */
@media (min-width: 1600px) {
  .gl-hidden-hg {
    display: none !important; } }

/**
 * 2. Positioning
 * 2.1. Text Alignments
 */
.gl-text-center {
  text-align: center; }

.gl-text-start {
  text-align: left;
  text-align: start;
  /* stylelint-disable-line declaration-block-no-duplicate-properties */ }

.gl-text-end {
  text-align: right;
  text-align: end;
  /* stylelint-disable-line declaration-block-no-duplicate-properties */ }

/**
 * Deprecated classes since v2.0.4
 */
.gl-text-left {
  text-align: left; }

.gl-text-right {
  text-align: right; }

/**
 * 3. Vertical spacing

  avoid using old vertical spacing, refer to new vertical spacing
 */
.gl-vspacing-s {
  margin-bottom: 10px; }
  @media (min-width: 600px) {
    .gl-vspacing-s-small {
      margin-bottom: 10px; }
    .gl-vspacing-s-medium {
      margin-bottom: 20px; }
    .gl-vspacing-s-large {
      margin-bottom: 40px; }
    .gl-vspacing-s-huge {
      margin-bottom: 80px; } }

.gl-vspacing-m {
  margin-bottom: 20px; }
  @media (min-width: 960px) {
    .gl-vspacing-m-small {
      margin-bottom: 10px; }
    .gl-vspacing-m-medium {
      margin-bottom: 20px; }
    .gl-vspacing-m-large {
      margin-bottom: 40px; }
    .gl-vspacing-m-huge {
      margin-bottom: 80px; } }

.gl-vspacing-l {
  margin-bottom: 40px; }
  @media (min-width: 1280px) {
    .gl-vspacing-l-small {
      margin-bottom: 10px; }
    .gl-vspacing-l-medium {
      margin-bottom: 20px; }
    .gl-vspacing-l-large {
      margin-bottom: 40px; }
    .gl-vspacing-l-huge {
      margin-bottom: 80px; } }

.gl-vspacing-xl {
  margin-bottom: 80px; }
  @media (min-width: 1600px) {
    .gl-vspacing-xl-small {
      margin-bottom: 10px; }
    .gl-vspacing-xl-medium {
      margin-bottom: 20px; }
    .gl-vspacing-xl-large {
      margin-bottom: 40px; }
    .gl-vspacing-xl-huge {
      margin-bottom: 80px; } }

.gl-vspacing-all-small {
  margin-bottom: 10px; }

.gl-vspacing-all-medium {
  margin-bottom: 20px; }

.gl-vspacing-all-large {
  margin-bottom: 40px; }

.gl-vspacing-all-huge {
  margin-bottom: 80px; }

/**
* 4. No margin
*/
.gl-no-margin-bottom {
  margin-bottom: 0 !important; }

/**
 * 5. screen readers
 * Only display content to screen readers
 * See: https://a11yproject.com/posts/how-to-hide-content/
 */
.gl-screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/**
 * Reset
 * 1. Body Styles
 * 2. Input Styles
 * 3. Heading Styles
 * 4. Tag Styles
 */
*,
*:after,
*:before {
  box-sizing: border-box; }

/**
 * 1. Body Styles
 */
html,
body {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  /**
   * Prevent "centering jumps" when there is enough content loaded in the page
   * to have a vertical scroll
   */
  overflow-y: scroll; }

/**
 * 2. Input Styles
 */
input:focus,
select:focus,
textarea:focus,
button:focus,
[tabindex]:focus {
  outline: none !important;
  /* stylelint-disable-line declaration-no-important */ }

a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  box-shadow: none;
  outline: none; }

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
  -webkit-appearance: none; }

button {
  border: none;
  background: none;
  padding: 0;
  font: inherit;
  text-align: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit; }

button,
input,
optgroup,
select,
textarea {
  margin: 0; }

fieldset {
  border: none; }

form {
  width: 100%; }

input:focus,
textarea:focus {
  outline: none; }
  input:focus::placeholder,
  textarea:focus::placeholder {
    opacity: 0; }

input::-ms-clear {
  width: 0;
  height: 0; }

/**
 * 3. Headings Styles
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  /* stylelint-disable-line declaration-property-value-blacklist */ }

/**
 * 4. Tag Styles
 */
p {
  margin: 0;
  padding: 0; }

i,
em {
  font-style: normal;
  /* stylelint-disable-line declaration-property-value-blacklist */ }

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none; }

a {
  cursor: pointer;
  text-decoration: none; }

/**
 * Theme Fonts
 * 1. Loaded Fonts
 */
/**
* 1. Loaded Fonts
*/
@font-face {
  font-family: "AdihausDIN";
  src: url("./adidas-foundation/dist/adidas/assets/fonts/adidas/AdihausDIN-Regular.woff2") format("woff2"), url("./adidas-foundation/dist/adidas/assets/fonts/adidas/AdihausDIN-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "AdihausDIN";
  src: url("./adidas-foundation/dist/adidas/assets/fonts/adidas/AdihausDIN-Bold.woff2") format("woff2"), url("./adidas-foundation/dist/adidas/assets/fonts/adidas/AdihausDIN-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "AdineuePRO";
  src: url("./adidas-foundation/dist/adidas/assets/fonts/adidas/adineuePRO-Regular.woff2") format("woff2"), url("./adidas-foundation/dist/adidas/assets/fonts/adidas/adineuePRO-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "AdineuePRO";
  src: url("./adidas-foundation/dist/adidas/assets/fonts/adidas/adineuePRO-Bold.woff2") format("woff2"), url("./adidas-foundation/dist/adidas/assets/fonts/adidas/adineuePRO-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "AdineuePRO";
  src: url("./adidas-foundation/dist/adidas/assets/fonts/adidas/adineuePRO-Black.woff2") format("woff2"), url("./adidas-foundation/dist/adidas/assets/fonts/adidas/adineuePRO-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

/* stylelint-disable declaration-property-value-blacklist, no-duplicate-selectors*/
/**
 * Typography
 *
 * 1. Body
 * 1.1. Default Styles
 * 1.2. Weight Variations
 * 2. Headings
 * 2.1. Default Styles
 * 2.2. Weight Variations
 * 2.4. Drop shadow Variation
 * 3. Labels
 * 3.1. Default Styles
 * 3.2. Size Variations
 * 3.3. Weight Variations
 * 4. Lists
 * 4.1. Unordered list
 * 4.2. Ordered list
 * 5. Prices
 * 5.1. Default Styles
 */
/**
 * 1. Body
 * 1.1. Default Styles
 */
body,
html {
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px; }

a {
  color: inherit; }

p {
  line-height: 22px;
  margin-bottom: 16px; }

strong,
.gl-strong {
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal; }

/**
 * 1.2. Weight Variations
 */
.gl-body--large {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 18px; }

.gl-body--small {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 14px; }

/**
 * 2. Headings
 * 2.1. Default Styles
 */
h1,
h2,
h3,
h4,
h5,
h6,
.gl-heading {
  font-family: "AdineuePRO", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 1.5px; }

/**
 * 2.2. Sizes Variations
 */
.gl-heading--xxl,
h1 {
  font-size: 56px;
  line-height: 48px; }
  @media (min-width: 1280px) {
    .gl-heading--xxl,
    h1 {
      font-size: 66px;
      line-height: 56px; } }

.gl-heading--xl,
h2 {
  font-size: 44px;
  line-height: 38px; }
  @media (min-width: 1280px) {
    .gl-heading--xl,
    h2 {
      font-size: 52px;
      line-height: 44px; } }

.gl-heading--l,
h3 {
  font-size: 36px;
  line-height: 32px; }
  @media (min-width: 1280px) {
    .gl-heading--l,
    h3 {
      font-size: 42px;
      line-height: 38px; } }

.gl-heading--m,
h4 {
  font-size: 26px;
  line-height: 24px; }
  @media (min-width: 1280px) {
    .gl-heading--m,
    h4 {
      font-size: 30px;
      line-height: 28px; } }

.gl-heading--s,
h5 {
  font-size: 18px;
  line-height: 16px; }
  @media (min-width: 1280px) {
    .gl-heading--s,
    h5 {
      font-size: 20px;
      line-height: 18px; } }

/**
 * 2.3. Weight Variations
 */
.gl-heading {
  /**
   * 2.4. Drop shadow Variation
   */ }
  .gl-heading--regular {
    font-family: "AdineuePRO", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal; }
  .gl-heading--black {
    font-family: "AdineuePRO", Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-style: normal; }
  .gl-heading--drop-shadow {
    text-shadow: 1px 1px #ffffff; }

/**
 * 3. Labels
 * 3.1. Default Styles
 */
.gl-label {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal; }
  .gl-label--small {
    font-size: 11px;
    line-height: 15px; }
  .gl-label--medium {
    font-size: 12px;
    line-height: 16px; }
  .gl-label--large {
    font-size: 13px;
    line-height: 17px; }
  .gl-label--extra-large {
    font-size: 14px;
    line-height: 18px; }
  .gl-label--bold {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700; }
  .gl-label--condensed {
    letter-spacing: .5px; }

/**
 * 4. Lists
 * 4.1. Default Styles
 */
ol,
ul,
.gl-list {
  font-size: 16px;
  line-height: 22px; }

.gl-list {
  list-style: disc inside; }
  .gl-list li, .gl-list__item {
    padding-left: 12px; }

/**
 * 4.2.  Ordered list.
 */
.gl-list-ordered {
  list-style: decimal inside; }
  .gl-list-ordered li, .gl-list-ordered__item {
    padding-left: 12px; }

/**
 * 5. Prices
 * 5.1. Default Styles
 */
.gl-price {
  display: flex;
  flex-wrap: wrap;
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 16px; }
  .gl-price--small {
    font-size: 12px; }
  .gl-price__value {
    color: #000000;
    margin-right: 5px; }
    .gl-price__value--crossed {
      color: #98989a;
      text-decoration: line-through; }
    .gl-price__value--sale {
      color: #e63f3f; }
    .gl-price__value:last-child {
      margin-right: 0; }

/**
 * General Grid Specification Styles
 * 1. Container
 * 2. Rows
 * 3. Columns
 *  3.1. Base Columns
 *  3.2. Medium Columns
 *  3.3. Large Columns
 *  3.4. Huge Columns
 * 4. Offsets
 *  4.1. Base Offsets
 *  4.2. Medium Offsets
 *  4.3. Large Offsets
 *  4.4. Huge Offsets
 * 5. No Gutters
 * 6. Vertical Alignments
 * 7. Stack Alignments
 */
*,
*:after,
*:before {
  box-sizing: border-box; }

/**
 * 1. Container
 */
.container {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  @media (min-width: 960px) {
    .container {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (min-width: 1280px) {
    .container {
      width: 1280px; } }
  @media (min-width: 1600px) {
    .container {
      width: 1600px; } }

/**
 * 2. Rows
 */
.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

/**
 * 3. Columns
 */
[class*='col-s'],
[class*='col-m'],
[class*='col-l'],
[class*='col-xl'],
[class*='col-hg'] {
  padding: 0 15px;
  float: left; }

/* stylelint-disable declaration-no-important  */
/**
 * 3.1. Base Columns
 */
.col-s-1 {
  width: 8.33333%; }

.col-s-2 {
  width: 16.66667%; }

.col-s-3 {
  width: 25%; }

.col-s-4 {
  width: 33.33333%; }

.col-s-5 {
  width: 41.66667%; }

.col-s-6 {
  width: 50%; }

.col-s-7 {
  width: 58.33333%; }

.col-s-8 {
  width: 66.66667%; }

.col-s-9 {
  width: 75%; }

.col-s-10 {
  width: 83.33333%; }

.col-s-11 {
  width: 91.66667%; }

.col-s-12 {
  width: 100%; }

/**
 * 3.2. Medium Columns
 */
@media (min-width: 600px) {
  .col-m-1 {
    width: 8.33333%; }
  .col-m-2 {
    width: 16.66667%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33333%; }
  .col-m-5 {
    width: 41.66667%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33333%; }
  .col-m-8 {
    width: 66.66667%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33333%; }
  .col-m-11 {
    width: 91.66667%; }
  .col-m-12 {
    width: 100%; } }

/**
 * 3.3. Large Columns
 */
@media (min-width: 960px) {
  .col-l-1 {
    width: 4.16667%; }
  .col-l-2 {
    width: 8.33333%; }
  .col-l-3 {
    width: 12.5%; }
  .col-l-4 {
    width: 16.66667%; }
  .col-l-5 {
    width: 20.83333%; }
  .col-l-6 {
    width: 25%; }
  .col-l-7 {
    width: 29.16667%; }
  .col-l-8 {
    width: 33.33333%; }
  .col-l-9 {
    width: 37.5%; }
  .col-l-10 {
    width: 41.66667%; }
  .col-l-11 {
    width: 45.83333%; }
  .col-l-12 {
    width: 50%; }
  .col-l-13 {
    width: 54.16667%; }
  .col-l-14 {
    width: 58.33333%; }
  .col-l-15 {
    width: 62.5%; }
  .col-l-16 {
    width: 66.66667%; }
  .col-l-17 {
    width: 70.83333%; }
  .col-l-18 {
    width: 75%; }
  .col-l-19 {
    width: 79.16667%; }
  .col-l-20 {
    width: 83.33333%; }
  .col-l-21 {
    width: 87.5%; }
  .col-l-22 {
    width: 91.66667%; }
  .col-l-23 {
    width: 95.83333%; }
  .col-l-24 {
    width: 100%; } }

/**
 * 3.4. Extra large Columns
 */
@media (min-width: 1280px) {
  .col-xl-1 {
    width: 4.16667%; }
  .col-xl-2 {
    width: 8.33333%; }
  .col-xl-3 {
    width: 12.5%; }
  .col-xl-4 {
    width: 16.66667%; }
  .col-xl-5 {
    width: 20.83333%; }
  .col-xl-6 {
    width: 25%; }
  .col-xl-7 {
    width: 29.16667%; }
  .col-xl-8 {
    width: 33.33333%; }
  .col-xl-9 {
    width: 37.5%; }
  .col-xl-10 {
    width: 41.66667%; }
  .col-xl-11 {
    width: 45.83333%; }
  .col-xl-12 {
    width: 50%; }
  .col-xl-13 {
    width: 54.16667%; }
  .col-xl-14 {
    width: 58.33333%; }
  .col-xl-15 {
    width: 62.5%; }
  .col-xl-16 {
    width: 66.66667%; }
  .col-xl-17 {
    width: 70.83333%; }
  .col-xl-18 {
    width: 75%; }
  .col-xl-19 {
    width: 79.16667%; }
  .col-xl-20 {
    width: 83.33333%; }
  .col-xl-21 {
    width: 87.5%; }
  .col-xl-22 {
    width: 91.66667%; }
  .col-xl-23 {
    width: 95.83333%; }
  .col-xl-24 {
    width: 100%; } }

/**
 * 3.5. Huge Columns
 */
@media (min-width: 1600px) {
  .col-hg-1 {
    width: 4.16667%; }
  .col-hg-2 {
    width: 8.33333%; }
  .col-hg-3 {
    width: 12.5%; }
  .col-hg-4 {
    width: 16.66667%; }
  .col-hg-5 {
    width: 20.83333%; }
  .col-hg-6 {
    width: 25%; }
  .col-hg-7 {
    width: 29.16667%; }
  .col-hg-8 {
    width: 33.33333%; }
  .col-hg-9 {
    width: 37.5%; }
  .col-hg-10 {
    width: 41.66667%; }
  .col-hg-11 {
    width: 45.83333%; }
  .col-hg-12 {
    width: 50%; }
  .col-hg-13 {
    width: 54.16667%; }
  .col-hg-14 {
    width: 58.33333%; }
  .col-hg-15 {
    width: 62.5%; }
  .col-hg-16 {
    width: 66.66667%; }
  .col-hg-17 {
    width: 70.83333%; }
  .col-hg-18 {
    width: 75%; }
  .col-hg-19 {
    width: 79.16667%; }
  .col-hg-20 {
    width: 83.33333%; }
  .col-hg-21 {
    width: 87.5%; }
  .col-hg-22 {
    width: 91.66667%; }
  .col-hg-23 {
    width: 95.83333%; }
  .col-hg-24 {
    width: 100%; } }

/**
 * 4. Offsets
 */
/**
 * 4.1. Base Offsets
 */
.offset-s-0 {
  margin-left: 0; }

.offset-s-1 {
  margin-left: 8.33333%; }

.offset-s-2 {
  margin-left: 16.66667%; }

.offset-s-3 {
  margin-left: 25%; }

.offset-s-4 {
  margin-left: 33.33333%; }

.offset-s-5 {
  margin-left: 41.66667%; }

.offset-s-6 {
  margin-left: 50%; }

.offset-s-7 {
  margin-left: 58.33333%; }

.offset-s-8 {
  margin-left: 66.66667%; }

.offset-s-9 {
  margin-left: 75%; }

.offset-s-10 {
  margin-left: 83.33333%; }

.offset-s-11 {
  margin-left: 91.66667%; }

.offset-s-12 {
  margin-left: 100%; }

/**
 * 4.2. Medium Offsets
 */
@media (min-width: 600px) {
  .offset-m-0 {
    margin-left: 0; }
  .offset-m-1 {
    margin-left: 8.33333%; }
  .offset-m-2 {
    margin-left: 16.66667%; }
  .offset-m-3 {
    margin-left: 25%; }
  .offset-m-4 {
    margin-left: 33.33333%; }
  .offset-m-5 {
    margin-left: 41.66667%; }
  .offset-m-6 {
    margin-left: 50%; }
  .offset-m-7 {
    margin-left: 58.33333%; }
  .offset-m-8 {
    margin-left: 66.66667%; }
  .offset-m-9 {
    margin-left: 75%; }
  .offset-m-10 {
    margin-left: 83.33333%; }
  .offset-m-11 {
    margin-left: 91.66667%; }
  .offset-m-12 {
    margin-left: 100%; } }

/**
 * 4.3. Large Offsets
 */
@media (min-width: 960px) {
  .offset-l-0 {
    margin-left: 0; }
  .offset-l-1 {
    margin-left: 4.16667%; }
  .offset-l-2 {
    margin-left: 8.33333%; }
  .offset-l-3 {
    margin-left: 12.5%; }
  .offset-l-4 {
    margin-left: 16.66667%; }
  .offset-l-5 {
    margin-left: 20.83333%; }
  .offset-l-6 {
    margin-left: 25%; }
  .offset-l-7 {
    margin-left: 29.16667%; }
  .offset-l-8 {
    margin-left: 33.33333%; }
  .offset-l-9 {
    margin-left: 37.5%; }
  .offset-l-10 {
    margin-left: 41.66667%; }
  .offset-l-11 {
    margin-left: 45.83333%; }
  .offset-l-12 {
    margin-left: 50%; }
  .offset-l-13 {
    margin-left: 54.16667%; }
  .offset-l-14 {
    margin-left: 58.33333%; }
  .offset-l-15 {
    margin-left: 62.5%; }
  .offset-l-16 {
    margin-left: 66.66667%; }
  .offset-l-17 {
    margin-left: 70.83333%; }
  .offset-l-18 {
    margin-left: 75%; }
  .offset-l-19 {
    margin-left: 79.16667%; }
  .offset-l-20 {
    margin-left: 83.33333%; }
  .offset-l-21 {
    margin-left: 87.5%; }
  .offset-l-22 {
    margin-left: 91.66667%; }
  .offset-l-23 {
    margin-left: 95.83333%; }
  .offset-l-24 {
    margin-left: 100%; } }

/**
 * 4.4. Extra large Offsets
 */
@media (min-width: 1280px) {
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; }
  .offset-xl-24 {
    margin-left: 100%; } }

/**
 * 4.5. Huge Offsets
 */
@media (min-width: 1600px) {
  .offset-hg-0 {
    margin-left: 0; }
  .offset-hg-1 {
    margin-left: 4.16667%; }
  .offset-hg-2 {
    margin-left: 8.33333%; }
  .offset-hg-3 {
    margin-left: 12.5%; }
  .offset-hg-4 {
    margin-left: 16.66667%; }
  .offset-hg-5 {
    margin-left: 20.83333%; }
  .offset-hg-6 {
    margin-left: 25%; }
  .offset-hg-7 {
    margin-left: 29.16667%; }
  .offset-hg-8 {
    margin-left: 33.33333%; }
  .offset-hg-9 {
    margin-left: 37.5%; }
  .offset-hg-10 {
    margin-left: 41.66667%; }
  .offset-hg-11 {
    margin-left: 45.83333%; }
  .offset-hg-12 {
    margin-left: 50%; }
  .offset-hg-13 {
    margin-left: 54.16667%; }
  .offset-hg-14 {
    margin-left: 58.33333%; }
  .offset-hg-15 {
    margin-left: 62.5%; }
  .offset-hg-16 {
    margin-left: 66.66667%; }
  .offset-hg-17 {
    margin-left: 70.83333%; }
  .offset-hg-18 {
    margin-left: 75%; }
  .offset-hg-19 {
    margin-left: 79.16667%; }
  .offset-hg-20 {
    margin-left: 83.33333%; }
  .offset-hg-21 {
    margin-left: 87.5%; }
  .offset-hg-22 {
    margin-left: 91.66667%; }
  .offset-hg-23 {
    margin-left: 95.83333%; }
  .offset-hg-24 {
    margin-left: 100%; } }

/**
 * 5. No Gutters
 */
.no-gutters {
  padding-right: 0;
  padding-left: 0; }

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

.no-gutters-s {
  padding-right: 0;
  padding-left: 0; }
  @media (min-width: 960px) {
    .no-gutters-s {
      padding-left: 15px;
      padding-right: 15px; } }

@media (min-width: 960px) {
  .no-gutters-l {
    padding-right: 0;
    padding-left: 0; } }

/**
 * 6. Vertical Alignments
 */
.gl-align-items-start {
  align-items: flex-start !important; }

.gl-align-items-center {
  align-items: center !important; }

.gl-align-items-end {
  align-items: flex-end !important; }

/**
 * 7. Stack Alignments
 */
.stack {
  margin-top: 30px;
  padding-bottom: 30px; }
  @media (min-width: 960px) {
    .stack {
      margin-top: 60px;
      padding-bottom: 60px; } }

/**
 * 3.  Components
 */
/**
 * Alerts
 * 1. General Styles
 * 2. Specific Alert Styles
 * 2.1. Info
 * 2.2. Warning
 * 2.3. Error
 * 2.4. Success
 */
/**
 * 1. General Styles
 */
.gl-alert {
  padding: 25px;
  color: #000000;
  position: relative;
  /* Content Styling */
  /**
   * 2. Specific Alert Styles
   */
  /**
   * 2.1. Alert info
   */
  /**
   * 2.2. Alert & Error warning
   */
  /**
   * 2.4. Alert success
   */ }
  .gl-alert__content p {
    margin-bottom: 0;
    line-height: 1.5em; }
  .gl-alert__content div:last-child p:last-child {
    margin-bottom: 0; }
  .gl-alert__title {
    text-transform: uppercase;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0; }
    @media (min-width: 1280px) {
      .gl-alert__title {
        font-size: 20px;
        line-height: 18px; } }
  .gl-alert[class^='icon-'] {
    display: none; }
  .gl-alert--info {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #000000; }
    .gl-alert--info:after {
      border-top-color: #ffffff; }
  .gl-alert--warning, .gl-alert--error {
    color: #ffffff;
    border: 1px solid #000000;
    background-color: #000000; }
  .gl-alert--success {
    color: #000000;
    background-color: #ebedee;
    border: 1px solid #000000; }
    .gl-alert--success:after {
      border-top-color: #ebedee; }

/* stylelint-disable declaration-property-value-blacklist */
/**
 * Buttons
 *
 * 0. Desktop CTA hover mixin
 * 1. Shared Styles
 * 2. Primary CTAs
 * 3. Secondary CTAs
 * 4. Tertiary CTAs
 * 5. Icon CTAs
 * 6. Loaders
 * 7. Disabled
 * 8. Specific Button Styles
 * 8.1 Paypal Button
 * 8.2 App store Button
 * 8.3 Play store Button
 * 9. Link
 */
/**
 * 0. Desktop CTA hover mixin
 * Only with a mouse to avoid the hover styles to stay visible after tap
 * No hover styles on disabled CTA
 * Duplicate hover styles on focus for minimal a11y but should be custom
 */
/**
 * 1. Shared Styles
 */
/**
 * 2. Primary CTAs
 */
.gl-cta {
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 50px;
  padding: 0 21px;
  line-height: 50px;
  background-color: #000000;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1), color 0.1s cubic-bezier(0.3, 0, 0.45, 1), border 0.1s cubic-bezier(0.3, 0, 0.45, 1), background 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: 700;
  /**
   * 3. Secondary CTAs
   */
  /**
   * 4. Tertiary CTAs
   */
  /**
   * 5. Icon CTAs
   */
  /**
   * 6. Loader
   */
  /**
   * 7. Disabled
   */
  /**
   * 8. Specific Button Styles
   */
  /**
   * 8.1. Paypal Button
   */
  /**
   * 8.2. App store Button
   */
  /**
   * 8.3. Play store Button
   */ }
  .gl-cta:not(.gl-cta--disabled):active {
    transform: scale(0.97); }
  @media (pointer: fine) {
    .gl-cta:not(.gl-cta--disabled):focus:not(:active), .gl-cta:not(.gl-cta--disabled):hover:not(:active) {
      color: #98989a;
      text-decoration: none; } }
  @supports (-moz-appearance: none) {
    .gl-cta:not(.gl-cta--disabled):focus:not(:active), .gl-cta:not(.gl-cta--disabled):hover:not(:active) {
      color: #98989a;
      text-decoration: none; } }
  .gl-cta--primary {
    height: 50px;
    transform: translate(-3px, -3px); }
    .gl-cta--primary::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 1px #000000;
      transform: translate(3px, 3px);
      transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-cta--primary:not(.gl-cta--disabled):active {
      transform: translate(0, 0) scale(1); }
      .gl-cta--primary:not(.gl-cta--disabled):active:after {
        transform: translate(0, 0) scale(1); }
    .gl-cta--primary.gl-cta--disabled:after {
      box-shadow: inset 0 0 0 1px #98989a; }
  .gl-cta--primary-light {
    height: 50px;
    background-color: #ffffff;
    color: #000000;
    border-color: #ffffff; }
    .gl-cta--primary-light::after {
      box-shadow: inset 0 0 0 1px #ffffff; }
    @media (pointer: fine) {
      .gl-cta--primary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--primary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        border-color: #ffffff;
        background-color: none; } }
    @supports (-moz-appearance: none) {
      .gl-cta--primary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--primary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        border-color: #ffffff;
        background-color: none; } }
    .gl-cta--primary-light:after {
      border-color: #ffffff; }
  .gl-cta--full-width {
    width: 100%;
    justify-content: space-between; }
  .gl-cta--secondary {
    border: 1.01565px solid currentColor;
    color: #000000;
    border-color: #000000;
    height: 50px;
    background-color: #ffffff; }
    @media (pointer: fine) {
      .gl-cta--secondary:not(.gl-cta--disabled):focus:not(:active), .gl-cta--secondary:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        background-color: none; } }
    @supports (-moz-appearance: none) {
      .gl-cta--secondary:not(.gl-cta--disabled):focus:not(:active), .gl-cta--secondary:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        background-color: none; } }
    .gl-cta--secondary:after {
      content: normal; }
  .gl-cta--secondary-light {
    height: 50px;
    color: #ffffff;
    border-color: #ffffff;
    background-color: #000000; }
    @media (pointer: fine) {
      .gl-cta--secondary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--secondary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        border-color: #ffffff;
        background-color: none; } }
    @supports (-moz-appearance: none) {
      .gl-cta--secondary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--secondary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        border-color: #ffffff;
        background-color: none; } }
    .gl-cta--secondary-light:after {
      border-color: #000000; }
  .gl-cta--tertiary {
    margin-left: -2px;
    margin-right: -2px;
    padding: 2px;
    min-height: 0;
    color: #000000;
    background-color: transparent;
    max-width: 100%;
    white-space: unset;
    text-align: left;
    line-height: 13px;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700; }
    .gl-cta--tertiary::before {
      content: '';
      display: block;
      position: absolute;
      left: 2px;
      bottom: 2px;
      width: calc(100% - (2px * 2));
      border-bottom-width: 1.01565px;
      border-bottom-style: solid;
      border-color: #000000;
      transition: 0.2s cubic-bezier(0.3, 0, 0.45, 1) border-color; }
    @media (pointer: fine) {
      .gl-cta--tertiary:not(.gl-cta--disabled):focus:not(:active), .gl-cta--tertiary:not(.gl-cta--disabled):hover:not(:active) {
        color: #ffffff;
        background-color: #000000; }
        .gl-cta--tertiary:not(.gl-cta--disabled):focus:not(:active):before, .gl-cta--tertiary:not(.gl-cta--disabled):hover:not(:active):before {
          border-color: #ffffff; } }
    @supports (-moz-appearance: none) {
      .gl-cta--tertiary:not(.gl-cta--disabled):focus:not(:active), .gl-cta--tertiary:not(.gl-cta--disabled):hover:not(:active) {
        color: #ffffff;
        background-color: #000000; }
        .gl-cta--tertiary:not(.gl-cta--disabled):focus:not(:active):before, .gl-cta--tertiary:not(.gl-cta--disabled):hover:not(:active):before {
          border-color: #ffffff; } }
    .gl-cta--tertiary.gl-cta--loading::before {
      right: 30px;
      width: auto; }
    .gl-cta--tertiary.gl-cta--loading .gl-cta__loading-spinner {
      margin-left: 10px; }
  .gl-cta--tertiary-light {
    color: #ffffff; }
    .gl-cta--tertiary-light::before {
      border-color: #ffffff; }
    @media (pointer: fine) {
      .gl-cta--tertiary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--tertiary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #000000;
        background-color: #ffffff; }
        .gl-cta--tertiary-light:not(.gl-cta--disabled):focus:not(:active):before, .gl-cta--tertiary-light:not(.gl-cta--disabled):hover:not(:active):before {
          border-color: #000000; } }
    @supports (-moz-appearance: none) {
      .gl-cta--tertiary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--tertiary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #000000;
        background-color: #ffffff; }
        .gl-cta--tertiary-light:not(.gl-cta--disabled):focus:not(:active):before, .gl-cta--tertiary-light:not(.gl-cta--disabled):hover:not(:active):before {
          border-color: #000000; } }
  .gl-cta--icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    padding: 0;
    color: #000000;
    background-color: #ffffff;
    border: 1.01565px solid #000000;
    letter-spacing: 0;
    line-height: 47px; }
    .gl-cta--icon.gl-cta--no-border {
      border: 0; }
    @media (pointer: fine) {
      .gl-cta--icon:not(.gl-cta--disabled):focus:not(:active), .gl-cta--icon:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        background-color: none; } }
    @supports (-moz-appearance: none) {
      .gl-cta--icon:not(.gl-cta--disabled):focus:not(:active), .gl-cta--icon:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        background-color: none; } }
    .gl-cta--icon:after {
      content: normal; }
    .gl-cta--icon .gl-cta__icon {
      margin: 0; }
    .gl-cta--icon .gl-cta__loading-spinner {
      margin-left: 0; }
  .gl-cta__icon {
    flex-shrink: 0;
    margin-left: 20px; }
  .gl-cta--loading {
    align-items: center; }
    .gl-cta--loading .gl-cta__icon {
      display: none; }
  .gl-cta__loading-spinner {
    display: none;
    align-items: center;
    border-radius: 50%;
    margin: 0 0 0 20px;
    vertical-align: middle;
    transform: translateZ(0);
    animation: loader 1.3s infinite linear;
    background: none;
    border-color: transparent transparent transparent currentColor;
    border-style: solid;
    width: 20px;
    height: 20px;
    border-width: 2px;
    justify-content: center; }
    .gl-cta--loading .gl-cta__loading-spinner {
      display: inline-flex; }
  .gl-cta--disabled {
    cursor: not-allowed;
    background-color: #98989a; }
  .gl-cta--paypal {
    color: #000000;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    line-height: 48px; }
    @media (pointer: fine) {
      .gl-cta--paypal:not(.gl-cta--disabled):focus:not(:active), .gl-cta--paypal:not(.gl-cta--disabled):hover:not(:active) {
        border: 1px solid #e2e2e2;
        background-color: #e2e2e2; } }
    @supports (-moz-appearance: none) {
      .gl-cta--paypal:not(.gl-cta--disabled):focus:not(:active), .gl-cta--paypal:not(.gl-cta--disabled):hover:not(:active) {
        border: 1px solid #e2e2e2;
        background-color: #e2e2e2; } }
    .gl-cta--paypal:before {
      content: '';
      width: 100%;
      min-width: 100px;
      height: 48px;
      background: url("./adidas-foundation/dist/adidas/assets/img/shared/icon-paypal-logo.svg") no-repeat left; }
  .gl-cta--app-store {
    padding: 0;
    background-color: transparent; }
    .gl-cta--app-store:before {
      content: '';
      min-width: 151px;
      height: 50px;
      background: url("./adidas-foundation/dist/adidas/assets/img/shared/img-app-store-cta.svg") no-repeat left;
      background-size: contain; }
  .gl-cta--play-store {
    padding: 0;
    background-color: transparent; }
    .gl-cta--play-store:before {
      content: '';
      min-width: 170px;
      height: 50px;
      background: url("./adidas-foundation/dist/adidas/assets/img/shared/img-play-store-cta.svg") no-repeat left;
      background-size: contain; }

/**
* 9. Link
*/
.gl-link {
  display: inline;
  vertical-align: bottom;
  padding-left: 1px;
  padding-right: 1px;
  color: #000000;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
  @media (pointer: fine) {
    .gl-link:not(.gl-cta--disabled):focus:not(:active), .gl-link:not(.gl-cta--disabled):hover:not(:active) {
      color: #ffffff;
      background-color: #000000; } }
  @supports (-moz-appearance: none) {
    .gl-link:not(.gl-cta--disabled):focus:not(:active), .gl-link:not(.gl-cta--disabled):hover:not(:active) {
      color: #ffffff;
      background-color: #000000; } }
  .gl-link:active {
    color: #ffffff;
    background-color: #000000; }
  .gl-link .gl-icon {
    position: relative;
    top: 1px;
    margin-right: 7px; }
  .gl-link--light {
    color: #ffffff; }
    @media (pointer: fine) {
      .gl-link--light:not(.gl-cta--disabled):focus:not(:active), .gl-link--light:not(.gl-cta--disabled):hover:not(:active) {
        color: #000000;
        background-color: #ffffff; } }
    @supports (-moz-appearance: none) {
      .gl-link--light:not(.gl-cta--disabled):focus:not(:active), .gl-link--light:not(.gl-cta--disabled):hover:not(:active) {
        color: #000000;
        background-color: #ffffff; } }
    .gl-link--light:active {
      color: #000000;
      background-color: #ffffff; }

/**
 * Badges
 * 1. General Styles
 * 2. Badges Sizes
 * 3. Badges Variances
 */
/**
 * 1. General Styles
 */
.gl-badge {
  font-size: 12px;
  color: #000000;
  background-color: #ffffff;
  padding: 1px 2px;
  text-align: center;
  display: inline-block;
  line-height: 1.1em;
  position: absolute;
  left: 7px;
  top: 7px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 2px;
  transform: translate(-100%, 0) rotate(270deg);
  transform-origin: right top;
  /**
   * 2. Badges Sizes
   */
  /**
   * 3. Variations
   */ }
  @media (min-width: 960px) {
    .gl-badge {
      left: 10px;
      top: 10px; } }
  .gl-badge--small {
    line-height: 11px;
    font-size: 11px;
    padding: 3px;
    letter-spacing: 2px; }
    @media (min-width: 960px) {
      .gl-badge--small {
        font-size: 12px;
        padding: 4px; } }
  .gl-badge--large {
    padding: 4px;
    font-size: 13px; }
    @media (min-width: 960px) {
      .gl-badge--large {
        top: 0;
        left: 0;
        transform: none;
        padding: 7px;
        margin: 30px 30px 30px 0; } }
  .gl-badge--urgent {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal; }
  .gl-badge--semi-urgent {
    font-style: italic; }
  .gl-badge--inactive {
    color: #98989a; }

/**
 * Box
 */
.gl-box {
  position: relative;
  background-color: #ebedee;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden; }
  @media (min-width: 600px) {
    .gl-box {
      margin-left: 0;
      margin-right: 0; } }
  .gl-box__content {
    position: relative;
    padding: 30px 20px 35px; }
  .gl-box--urgent {
    padding: 15px;
    background: url("./adidas-foundation/dist/adidas/assets/img/shared/bg-pattern-dashed.svg") left top repeat;
    background-size: 7px; }
    .gl-box--urgent .gl-box__content {
      padding: 30px 20px 35px;
      background-color: #ffffff; }
  .gl-box--very-urgent {
    color: #ffffff;
    background-color: #000000; }

/**
 * Callouts
 */
.gl-callout {
  max-width: 523px; }
  .gl-callout .gl-box__content p:last-child {
    margin-bottom: 0; }
  .gl-callout--top::before, .gl-callout--top::after, .gl-callout--bottom::before, .gl-callout--bottom::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none; }
  .gl-callout--left::before, .gl-callout--right::before {
    height: 20px;
    background: #ffffff; }
  .gl-callout--left::after, .gl-callout--right::after {
    height: 20px;
    background: #ffffff; }
  .gl-callout--top::before, .gl-callout--top::after {
    top: 0; }
  .gl-callout--top.gl-box--urgent {
    padding-top: 35px; }
  .gl-callout--top:not(.gl-box--urgent) .gl-box__content {
    padding-top: 50px; }
  .gl-callout--bottom::before, .gl-callout--bottom::after {
    top: auto;
    bottom: 0; }
  .gl-callout--bottom::before {
    transform: skew(30deg); }
  .gl-callout--bottom::after {
    transform: skew(-30deg); }
  .gl-callout--bottom:not(.gl-box--urgent) .gl-callout .gl-box__content {
    padding-bottom: 50px; }
  .gl-callout--bottom.gl-box--urgent {
    padding-bottom: 35px; }
  .gl-callout--left::before {
    left: -10px;
    width: 45px;
    height: 20px;
    background: #ffffff;
    transform: skew(-30deg); }
  .gl-callout--left::after {
    left: 46px;
    right: 0;
    height: 20px;
    width: calc(100% - 40px);
    background: #ffffff;
    transform: skew(30deg); }
  .gl-callout--right::before {
    left: -6px;
    width: calc(100% - 40px); }
  .gl-callout--right::after {
    left: auto;
    right: -10px;
    width: 45px; }

/**
 * Carousel
 */
.gl-carousel__content {
  position: relative; }

.gl-carousel__container {
  overflow: hidden; }

.gl-carousel--overflow-visible .gl-carousel__container {
  overflow: visible; }

.gl-carousel__row {
  position: relative;
  margin-left: -0.5px;
  margin-right: -0.5px;
  user-select: none; }

.gl-carousel__slider {
  display: flex;
  width: 100%; }
  .gl-carousel__slider--dragging {
    cursor: grab; }

.gl-carousel__item-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 0.5px;
  margin: 0; }

.gl-carousel__prev-next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 50%;
  padding: 5px;
  cursor: pointer;
  transform: translateY(-50%);
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1), opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1);
  background-color: transparent;
  color: #000000; }
  .gl-carousel__prev-next-button:hover {
    background-color: #000000;
    color: #ffffff; }
  .gl-carousel__prev-next-button--hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1), visibility 0.2s cubic-bezier(0.3, 0, 0.45, 1) 0.2s; }
  .gl-carousel__prev-next-button--next {
    right: -15px; }
    @media (min-width: 600px) {
      .gl-carousel__prev-next-button--next {
        right: -20px; } }
  .gl-carousel__prev-next-button--prev {
    left: -15px; }
    @media (min-width: 600px) {
      .gl-carousel__prev-next-button--prev {
        left: -20px; } }
    .gl-carousel__prev-next-button--prev .gl-carousel__prev-next-icon {
      transform: rotate(180deg); }

.gl-carousel--show-page-indicator .gl-carousel__prev-next-button {
  top: calc(50% - (30px / 2)); }

/**
 * Comparison Bar
 * 1. General Styles
 */
/**
 * 1. General Styles
 */
.gl-comparison-bar {
  position: relative; }
  .gl-comparison-bar__bg {
    height: 4px;
    background-color: #d9dcdd;
    overflow: hidden;
    position: relative; }
  .gl-comparison-bar__indicator {
    z-index: 3;
    width: 49px;
    background-color: #000000;
    height: 4px;
    position: absolute;
    top: 0; }
  .gl-comparison-bar__bar {
    z-index: 1; }
  .gl-comparison-bar__step-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .gl-comparison-bar__steps {
    width: 4px;
    height: 4px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    z-index: 2; }
    .gl-comparison-bar__steps--second {
      left: 25%;
      margin-left: -2px; }
    .gl-comparison-bar__steps--third {
      left: 50%;
      margin-left: -2px; }
    .gl-comparison-bar__steps--fourth {
      left: 75%;
      margin-left: -2px; }
  .gl-comparison-bar__label--first {
    text-align: left; }
  .gl-comparison-bar__label--second, .gl-comparison-bar__label--third, .gl-comparison-bar__label--fourth {
    text-align: center; }
  .gl-comparison-bar__label--last {
    text-align: right; }
  .gl-comparison-bar__label-wrapper {
    display: flex;
    justify-content: space-between;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    margin-top: 5px; }
  .gl-comparison-bar__caption {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 14px;
    margin-top: 10px; }
  .gl-comparison-bar__title {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    margin-bottom: 7px;
    text-align: left; }
  .gl-comparison-bar--triangle .gl-comparison-bar__bg {
    overflow: visible; }
  .gl-comparison-bar--triangle .gl-comparison-bar__indicator {
    width: 0;
    background-color: transparent;
    height: auto;
    border: 10px solid transparent;
    border-bottom-width: 20px;
    border-top: 20px solid #000000;
    top: -8px; }
  .gl-comparison-bar--triangle .gl-comparison-bar__label-wrapper {
    margin-top: 10px; }

/**
* Dividers
*/
.gl-divider {
  border: 0;
  margin: 0 0 20px 0;
  border-bottom: 1px;
  border-bottom-color: #d9dcdd;
  border-bottom-style: solid; }
  .gl-divider--dark {
    border-bottom-color: #98989a; }

/* stylelint-disable declaration-property-value-blacklist, function-url-quotes */
/**
 * Form
 * 1. Shared
 * 2. Checkbox
 * 3. Recaptcha
 */
/**
 * 1. Shared
 */
/*
 * General
 */
.gl-form__element {
  display: flex;
  min-height: 80px;
  margin-bottom: 10px; }
  .gl-form__element--checkbox {
    min-height: 54px; }
  .gl-form__element .gl-form-item {
    -ms-flex: 1; }

.gl-form-item {
  position: relative; }
  .gl-form-item--inline {
    display: inline-block; }
  .gl-form-item__input {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    min-height: 50px;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.01565px solid #98989a;
    padding: 0 18px 0 18px;
    width: 100%;
    margin: 0; }
  .gl-form-item__input-error {
    background-image: url("./adidas-foundation/dist/adidas/assets/img/shared/icon-form-cross2x.png");
    background-position: calc(100% - 18px) 17px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding-right: 38px;
    border-color: #e63f3f;
    box-shadow: 0 1px #e63f3f; }
    .gl-form-item__input-error:focus {
      border-style: solid; }
  .gl-form-item__input::placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }

.gl-form-label {
  font-size: 11px;
  top: -9px;
  left: 17px;
  margin-top: 0;
  padding: 2px 5px 1px;
  background-color: #ffffff;
  letter-spacing: 1px;
  color: #000;
  position: absolute;
  text-transform: uppercase;
  pointer-events: none;
  z-index: 1; }
  .gl-form-item--error .gl-form-label {
    color: #e63f3f; }

.gl-form-label-hint {
  color: #e63f3f;
  display: none;
  margin-left: 3px; }
  .gl-form-label-hint:after {
    content: '*'; }
  .gl-form-item--required .gl-form-label-hint {
    display: inline-block; }

.gl-form-hint,
.gl-form-message {
  font-size: 14px;
  line-height: 20px;
  padding-left: 21px; }

.gl-form-hint {
  color: #98989a; }

.gl-form-hint--error,
.gl-form-message {
  font-weight: bold;
  color: #e63f3f;
  display: none; }
  .gl-form-item--error .gl-form-hint--error, .gl-form-hint--error--show, .gl-form-item--error
  .gl-form-message,
  .gl-form-message--show {
    display: block; }

/**
 * 2. Checkbox
 */
.gl-checkbox .gl-form-message,
.gl-checkbox .gl-form-hint {
  padding-left: 0; }

.gl-checkbox__label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 24px;
  margin-bottom: 0;
  cursor: pointer; }

.gl-checkbox__input {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin: 0 14px 0 0;
  color: #98989a;
  opacity: 0;
  cursor: pointer;
  pointer-events: all; }

.gl-checkbox__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  color: #ffffff;
  background-color: #ffffff;
  pointer-events: none;
  box-shadow: inset 0 0 0 2px #98989a;
  transition: box-shadow 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
  .gl-form-item--has-focus .gl-checkbox__icon {
    border-color: #000000; }

.gl-checkbox__text {
  display: inline-flex;
  padding-left: 1px;
  padding-right: 1px;
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
  .gl-form-item--has-focus .gl-checkbox__text {
    background-color: #000000;
    color: #ffffff; }

.gl-checkbox--checked .gl-checkbox__icon {
  background-color: #000000;
  box-shadow: inset 0 0 0 2px #000000; }

.gl-checkbox:hover .gl-checkbox__icon {
  box-shadow: inset 0 0 0 2px #000000; }

:not(.gl-checkbox) > label > input[type='checkbox'] {
  flex-shrink: 0;
  color: #98989a;
  width: 24px;
  height: 24px;
  margin: 0 15px 0 0;
  cursor: pointer;
  opacity: 0;
  left: 0;
  pointer-events: all; }
  :not(.gl-checkbox) > label > input[type='checkbox']:checked ~ .gl-checkbox__icon,
  :not(.gl-checkbox) > label > input[type='checkbox']:checked ~ i {
    font-size: 14px;
    background-color: #000000;
    border-color: #000000; }
  :not(.gl-checkbox) > label > input[type='checkbox'] ~ .gl-checkbox__icon,
  :not(.gl-checkbox) > label > input[type='checkbox'] ~ i {
    font-size: 0;
    background-color: #ffffff;
    color: #ffffff;
    border: 2px solid #98989a;
    width: 24px;
    height: 24px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    position: absolute; }

/**
 * 3. Recaptcha
 */
.gl-recaptcha {
  min-height: 101px; }

.gl-input {
  position: relative;
  min-height: calc(50px + 30px);
  margin-bottom: 10px; }
  .gl-input__field {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    min-height: 50px;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.01565px solid #98989a;
    padding: 0 18px 0 18px;
    width: 100%;
    margin: 0; }
    .gl-input__field--with-icon {
      padding-right: 50px; }
    .gl-form-item--has-focus .gl-input__field {
      border-bottom: 1.01565px solid #000000;
      box-shadow: 0 1px 0 0 #000000; }
  .gl-input__label {
    display: flex;
    font-size: 16px;
    letter-spacing: 1px;
    /* stylelint-disable-line  */
    color: #98989a;
    position: absolute;
    pointer-events: none;
    text-transform: uppercase;
    left: 21px;
    top: 14px;
    transition: .2s ease all; }
    .gl-input__label--hoisted {
      font-size: 14px;
      top: -11px;
      left: 17px;
      margin-top: 0;
      padding: 2px 5px 1px;
      background-color: #ffffff; }
  .gl-form-item--success .gl-input__icon {
    color: #00aa55; }
  .gl-form-item--success .gl-input__field {
    border-bottom: 1.01565px solid #00aa55;
    box-shadow: 0 1px 0 0 #00aa55; }
  .gl-form-item--error .gl-input__icon {
    color: #e63f3f; }
  .gl-form-item--error .gl-input__field {
    border-bottom: 1.01565px solid #e63f3f;
    box-shadow: 0 1px 0 0 #e63f3f; }
  .gl-input__icon {
    position: absolute;
    top: 14px;
    right: 20px; }

/**
  * Legacy
  * TODO remove when all inputs are using the Gl component
  */
:not(.gl-input) > input[type='text'],
:not(.gl-input) > input[type='email'],
:not(.gl-input) > input[type='password'],
:not(.gl-input) > input[type='tel'] {
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  height: 50px;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border: 1.01565px solid #98989a;
  padding: 0 18px;
  width: 100%;
  margin: 0;
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), border 0.1s cubic-bezier(0.3, 0, 0.45, 1), box-shadow 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
  :not(.gl-input) > input[type='text']:focus,
  :not(.gl-input) > input[type='email']:focus,
  :not(.gl-input) > input[type='password']:focus,
  :not(.gl-input) > input[type='tel']:focus {
    border-color: #000000;
    box-shadow: 0 1px #000000; }
  .gl-form-item--error :not(.gl-input) > input[type='text'], .gl-form-item--error
  :not(.gl-input) > input[type='email'], .gl-form-item--error
  :not(.gl-input) > input[type='password'], .gl-form-item--error
  :not(.gl-input) > input[type='tel'] {
    background-image: url("./adidas-foundation/dist/adidas/assets/img/shared/icon-form-cross2x.png");
    background-position: calc(100% - 18px) 17px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding-right: 38px;
    border-color: #e63f3f;
    box-shadow: 0 1px #e63f3f; }
    .gl-form-item--error :not(.gl-input) > input[type='text']:focus, .gl-form-item--error
    :not(.gl-input) > input[type='email']:focus, .gl-form-item--error
    :not(.gl-input) > input[type='password']:focus, .gl-form-item--error
    :not(.gl-input) > input[type='tel']:focus {
      border-style: solid; }
  :not(.gl-input) > input[type='text']::placeholder,
  :not(.gl-input) > input[type='email']::placeholder,
  :not(.gl-input) > input[type='password']::placeholder,
  :not(.gl-input) > input[type='tel']::placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  :not(.gl-input) > input[type='text']::-webkit-input-placeholder, :not(.gl-input) > input[type='text']:-ms-input-placeholder, :not(.gl-input) > input[type='text']::-ms-input-placeholder, :not(.gl-input) > input[type='text']:-moz-placeholder,
  :not(.gl-input) > input[type='email']::-webkit-input-placeholder,
  :not(.gl-input) > input[type='email']:-ms-input-placeholder,
  :not(.gl-input) > input[type='email']::-ms-input-placeholder,
  :not(.gl-input) > input[type='email']:-moz-placeholder,
  :not(.gl-input) > input[type='password']::-webkit-input-placeholder,
  :not(.gl-input) > input[type='password']:-ms-input-placeholder,
  :not(.gl-input) > input[type='password']::-ms-input-placeholder,
  :not(.gl-input) > input[type='password']:-moz-placeholder,
  :not(.gl-input) > input[type='tel']::-webkit-input-placeholder,
  :not(.gl-input) > input[type='tel']:-ms-input-placeholder,
  :not(.gl-input) > input[type='tel']::-ms-input-placeholder,
  :not(.gl-input) > input[type='tel']:-moz-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000; }
  :not(.gl-input) > input[type='text'].gl-form-item__input,
  :not(.gl-input) > input[type='email'].gl-form-item__input,
  :not(.gl-input) > input[type='password'].gl-form-item__input,
  :not(.gl-input) > input[type='tel'].gl-form-item__input {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    min-height: 50px;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.01565px solid #98989a;
    padding: 0 18px 0 18px;
    width: 100%;
    margin: 0; }
  :not(.gl-input) > input[type='text'].gl-form-item__input-error,
  :not(.gl-input) > input[type='email'].gl-form-item__input-error,
  :not(.gl-input) > input[type='password'].gl-form-item__input-error,
  :not(.gl-input) > input[type='tel'].gl-form-item__input-error {
    background-image: url("./adidas-foundation/dist/adidas/assets/img/shared/icon-form-cross2x.png");
    background-position: calc(100% - 18px) 17px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding-right: 38px;
    border-color: #e63f3f;
    box-shadow: 0 1px #e63f3f; }
    :not(.gl-input) > input[type='text'].gl-form-item__input-error:focus,
    :not(.gl-input) > input[type='email'].gl-form-item__input-error:focus,
    :not(.gl-input) > input[type='password'].gl-form-item__input-error:focus,
    :not(.gl-input) > input[type='tel'].gl-form-item__input-error:focus {
      border-style: solid; }
  :not(.gl-input) > input[type='text'].gl-form-item__input::placeholder,
  :not(.gl-input) > input[type='email'].gl-form-item__input::placeholder,
  :not(.gl-input) > input[type='password'].gl-form-item__input::placeholder,
  :not(.gl-input) > input[type='tel'].gl-form-item__input::placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }

/**
 * Dropdown
 */
.gl-dropdown {
  position: relative;
  display: block;
  cursor: pointer; }
  .gl-dropdown__select, .gl-dropdown__option {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.01565px solid #98989a;
    padding: 0 18px;
    width: 100%;
    margin: 0; }
  .gl-dropdown__select {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    cursor: pointer;
    transition: border 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-dropdown__select:focus, .gl-dropdown__select:hover {
      border-color: #000000; }
  .gl-dropdown__select-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .gl-dropdown__select-icon {
    flex-shrink: 0;
    transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    margin-left: 10px; }
  .gl-dropdown__options {
    background-color: #ffffff;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 195px;
    overflow: auto;
    border: 1.01565px solid #98989a;
    transform: translateY(-1px);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s cubic-bezier(0.3, 0, 0.45, 1), visibility 0.1s cubic-bezier(0.3, 0, 0.45, 1) 0.1s; }
    .gl-dropdown__options--squared {
      padding: 20px 10px; }
    .gl-dropdown__options--with-before .gl-square-list {
      padding-top: 16px; }
    .gl-dropdown__options--with-after .gl-square-list {
      padding-bottom: 16px; }
  .gl-dropdown__option {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0;
    border-bottom: 1.01565px solid #ebedee;
    cursor: pointer;
    transition: background 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-dropdown__option:focus, .gl-dropdown__option:hover {
      background-color: #ebedee; }
    .gl-dropdown__option--selected {
      font-weight: 700; }
  .gl-dropdown__item:last-child .gl-dropdown__option:last-of-type {
    border-bottom: 0; }
  .gl-dropdown__select-element {
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: block; }
    @media (min-width: 600px) {
      .gl-dropdown__select-element {
        display: none; } }
  .gl-dropdown--open .gl-dropdown__select {
    border-color: #000000; }
  .gl-dropdown--open .gl-dropdown__select-icon {
    transform: scaleY(-1); }
  .gl-dropdown--open .gl-dropdown__options {
    border-color: #000000;
    border-top-color: #ebedee;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.1s cubic-bezier(0.3, 0, 0.45, 1), visibility 0.1s cubic-bezier(0.3, 0, 0.45, 1) 0s; }
  .gl-dropdown--disabled {
    cursor: not-allowed; }
  .gl-dropdown--disabled .gl-dropdown__select {
    color: #8c8c8c;
    border-color: #8c8c8c; }
  .gl-dropdown--small .gl-dropdown__select, .gl-dropdown--small .gl-dropdown__option {
    padding-left: 15px;
    padding-right: 15px; }
  .gl-dropdown--small .gl-dropdown__select-icon {
    margin-left: 5px; }
  .gl-dropdown--no-max-height .gl-dropdown__options {
    max-height: none; }

/**
 * GlIcon
 */
.gl-icon {
  display: inline-block;
  vertical-align: text-top;
  width: 24px;
  height: 24px; }
  .gl-icon--light {
    color: #ffffff; }
  .gl-icon--size-communication {
    width: 19px;
    height: 19px; }

/**
 * Loader
 * 1. General Styles
 */
/**
 * 1. General Styles
 */
.gl-loader {
  content: '';
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  transform: translateZ(0);
  box-sizing: border-box;
  animation: loader 1.3s infinite linear;
  background: none;
  border-color: transparent transparent transparent #ffffff;
  border-style: solid;
  width: 90px;
  height: 90px;
  border-width: 4px; }
  .gl-loader--black {
    border-color: transparent transparent transparent #000000; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/**
 * Menu
 */
.gl-menu {
  padding: 0;
  margin: 0; }
  .gl-menu__item {
    position: relative;
    list-style: none; }
    .gl-menu__item--active {
      font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-style: normal;
      z-index: 1;
      position: relative; }
      .gl-menu__item--active .gl-menu__element {
        color: #000000; }
  .gl-menu__element {
    width: 100%;
    cursor: pointer;
    transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-menu__element:focus, .gl-menu__element:hover {
      color: #000000; }

/**
 * Modal
 * 1. General Styles
 * 2. Active
 * 3. Variations
 */
/**
 * 1. General Styles
 */
.gl-modal {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: fixed;
  z-index: 2;
  width: 100vw;
  left: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;
  touch-action: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0s linear 0.3s, visibility 0s linear 0.3s;
  transform: translateZ(0);
  /**
   * 2. Active / visible
   */
  /**
   * 3. Variations
   */ }
  @media (min-width: 960px) {
    .gl-modal {
      justify-content: center;
      align-items: center; } }
  .gl-modal__overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    pointer-events: all;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1) 0.1s, visibility 0s linear 0.3s; }
  .gl-modal__dialog, .gl-modal__main {
    max-height: 90vh; }
    @media (min-width: 960px) {
      .gl-modal__dialog, .gl-modal__main {
        max-height: 92vh; } }
  .gl-modal__dialog {
    position: relative;
    max-width: 90vw;
    pointer-events: all;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1), visibility 0s linear 0.2s; }
    @media (min-width: 960px) {
      .gl-modal__dialog {
        margin-right: 30px;
        margin-left: 30px; } }
  .gl-modal__main {
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    background-color: #ffffff;
    padding: 30px 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    @media (min-width: 960px) {
      .gl-modal__main {
        border-left: 1px solid #000000;
        border-bottom: 1px solid #000000;
        padding: 20px 15px; } }
  .gl-modal__title {
    padding-right: 15px; }
    @media (min-width: 960px) {
      .gl-modal__title {
        padding-right: auto; } }
  .gl-modal__close {
    position: absolute;
    top: 5px;
    right: 5px;
    transform: translate(50%, -50%);
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #000000;
    transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-modal__close:active {
      transform: translate(50%, -50%) scale(0.94); }
  .gl-modal--active {
    opacity: 1;
    visibility: visible;
    transition: opacity 0s, visibility 0s; }
  .gl-modal--active .gl-modal__overlay {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1), visibility 0s; }
  .gl-modal--active .gl-modal__dialog {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.45s cubic-bezier(0.3, 0, 0.45, 1) 0.1s, visibility 0s; }
  @media (min-width: 960px) {
    .gl-modal--small .gl-modal__main, .gl-modal--small .gl-modal__dialog {
      max-height: 80vh; } }
  @media (min-width: 960px) {
    .gl-modal--small .gl-modal__main {
      padding: 58px 30px; } }
  @media (min-width: 960px) {
    .gl-modal--large .gl-modal__main, .gl-modal--large .gl-modal__dialog {
      max-height: 80vh; } }
  @media (min-width: 960px) {
    .gl-modal--large .gl-modal__dialog {
      max-width: 1200px; } }
  @media (max-width: 959px) {
    .gl-modal--mobile-full .gl-modal__dialog {
      /* Fixed position makes sure the 100% height accounts for the URL bar on mobile Chrome,
        * instead of flowing under the bottom of the screen.
        * https://developers.google.com/web/updates/2016/12/url-bar-resizing
        */
      position: fixed;
      max-width: 100vw;
      height: auto;
      bottom: 0; }
    .gl-modal--mobile-full .gl-modal__main {
      border-right: 0; }
    .gl-modal--mobile-full .gl-modal__close {
      top: 0;
      right: 15px;
      transform: translate3d(0, -50%, 0); }
      .gl-modal--mobile-full .gl-modal__close:hover {
        will-change: transform; }
      .gl-modal--mobile-full .gl-modal__close:active {
        transform: translate3d(0, -50%, 0) scale(0.94); } }
  .gl-modal--left .gl-modal__dialog,
  .gl-modal--right .gl-modal__dialog {
    margin-right: 0;
    margin-left: 0; }
  .gl-modal--left .gl-modal__main,
  .gl-modal--right .gl-modal__main {
    border-bottom: 0; }
    @media (min-width: 960px) {
      .gl-modal--left .gl-modal__main,
      .gl-modal--right .gl-modal__main {
        padding: 50px 48px 48px 48px; } }
    @media (min-width: 1280px) {
      .gl-modal--left .gl-modal__main,
      .gl-modal--right .gl-modal__main {
        padding: 54px 48px 48px 48px; } }
  .gl-modal--left {
    justify-content: flex-start;
    align-items: flex-end; }
  .gl-modal--left .gl-modal__main {
    border-left: 0;
    padding-right: 35px; }
  .gl-modal--right {
    justify-content: flex-end;
    align-items: flex-end; }
  .gl-modal--right .gl-modal__main {
    border-left: 1px solid #000000;
    border-right: 0;
    padding-left: 35px; }
  .gl-modal--right .gl-modal__close {
    right: auto;
    left: 5px;
    transform: translate3d(-50%, -50%, 0); }
    .gl-modal--right .gl-modal__close:active {
      transform: translate3d(-50%, -50%, 0) scale(0.94); }
  @media (min-width: 960px) {
    .gl-modal .container {
      padding: 0; } }

/**
 * PageIndicator
 * @TODO 2.0: change flex to inline-flex and remove justify-content center on the component
   the positioning should be handled outside of the component
 */
.gl-page-indicator {
  display: flex;
  justify-content: center;
  padding: 0 8px;
  list-style: none;
  color: #000000;
  pointer-events: auto;
  margin-top: 20px; }
  .gl-page-indicator__item {
    display: flex;
    align-items: flex-end;
    height: 12px;
    padding: 4px 3px;
    cursor: pointer; }
  @media (pointer: fine) {
    .gl-page-indicator__item:hover .gl-page-indicator__dot {
      height: 100%; } }
  .gl-page-indicator__dot {
    display: block;
    width: 18px;
    height: 25%;
    background-color: currentColor;
    transform-origin: 50% 100%;
    transition: height 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-page-indicator__dot--selected {
      height: 100%; }
  .gl-page-indicator--light {
    color: #ffffff; }
  .gl-page-indicator--shadow .gl-page-indicator__dot {
    box-shadow: 1px 1px 0 #ffffff; }
  .gl-page-indicator--shadow.gl-page-indicator--light .gl-page-indicator__dot {
    box-shadow: 1px 1px 0 #000000; }
  @media (min-width: 600px) {
    .gl-page-indicator--autoplay {
      color: #ffffff; }
      .gl-page-indicator--autoplay .gl-page-indicator__dot {
        box-shadow: 1px 1px 0 #000000; } }

/**
 * Product cards
 */
/**
  * These variables will be removed after Reebok redesign (End of Q1 2019)
  */
.gl-product-card {
  position: relative;
  width: 100%;
  color: #000000;
  background-color: #ffffff;
  cursor: pointer; }
  @media (min-width: 960px) {
    .gl-product-card {
      border: 1px solid transparent; } }
  .gl-product-card:hover {
    z-index: 1; }
    @media (min-width: 960px) {
      .gl-product-card:hover {
        border-color: #d9dcdd; } }
  .gl-product-card a {
    display: block; }
  .gl-product-card__image, .gl-product-card__image-hover {
    display: block;
    width: 100%; }
  .gl-product-card__image-hover {
    position: absolute;
    top: 0;
    visibility: hidden;
    opacity: 0; }
  .gl-product-card__media {
    background-color: #eceef0; }
    .gl-product-card__media:hover .gl-product-card__image-hover {
      visibility: visible;
      opacity: 1; }
  .gl-product-card__broken-image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    color: #98989a; }
    .gl-product-card__broken-image:before {
      content: '';
      display: block;
      padding-top: 100%; }
  .gl-product-card__wishlist {
    color: #000000;
    position: absolute;
    right: 14px;
    top: 14px; }
    @media (min-width: 960px) {
      .gl-product-card__wishlist {
        right: 16px;
        top: 16px; } }
  .gl-product-card__details {
    padding: 10px;
    overflow: auto;
    clear: both; }
    @media (min-width: 960px) {
      .gl-product-card__details {
        padding: 10px 10px 20px; } }
    .gl-product-card__details a {
      display: block;
      min-height: 75px; }
  .gl-product-card__details-top {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
    min-height: 17px; }
  .gl-product-card__category, .gl-product-card__color {
    font-size: 12px; }
  .gl-product-card__category {
    color: #98989a;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .gl-product-card__details-icons {
    display: flex; }
  .gl-product-card__details-icon {
    position: relative;
    width: 17px;
    height: 17px;
    margin-left: 8px;
    font-size: 0; }
    .gl-product-card__details-icon--personalization, .gl-product-card__details-icon--customization {
      width: 17px;
      height: 17px; }
  .gl-product-card__details-main {
    line-height: normal;
    height: 60px; }
  .gl-product-card__name {
    letter-spacing: .5px;
    margin: 0 0 5px 0;
    max-height: 30px;
    overflow: hidden; }
  .gl-product-card .gl-price {
    font-size: 12px; }
  .gl-product-card__details-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .gl-product-card__reviews {
    color: #98989a;
    display: flex;
    align-items: flex-start;
    font-size: 10px; }
  .gl-product-card__reviews-number {
    font-size: 12px;
    line-height: 1;
    padding-left: 5px; }
  .gl-product-card__color {
    line-height: 1;
    text-transform: lowercase;
    color: #98989a; }
  .gl-product-card .gl-product-card__stars {
    color: #98989a; }
  .gl-product-card .gl-star-rating__star {
    height: 11px;
    width: 11px; }

.gl-product-card-container {
  padding: 0 1px; }
  @media (min-width: 960px) {
    .gl-product-card-container {
      padding: 0 5px; } }
  .gl-product-card-container .gl-product-card__carousel {
    display: none; }

/**
 * Carousel
 */
@media (min-width: 960px) {
  .gl-product-card-container.show-variation-carousel:hover {
    position: relative; }
    .gl-product-card-container.show-variation-carousel:hover .gl-product-card {
      position: absolute;
      left: 5px;
      width: calc(100% - 10px);
      z-index: 1; } }
  @media (min-width: 960px) and (min-width: 960px) {
    .gl-product-card-container.show-variation-carousel:hover .gl-product-card__carousel {
      display: block; } }

.gl-product-card-container.show-variation-carousel .gl-product-card__carousel {
  background: #eceef0;
  position: relative;
  margin: 3px 0 0 0;
  overflow-x: hidden;
  width: 100%;
  padding: 0 20px; }
  .gl-product-card-container.show-variation-carousel .gl-product-card__carousel-item.active, .gl-product-card-container.show-variation-carousel .gl-product-card__carousel-item:hover,
  .gl-product-card-container.show-variation-carousel .gl-product-card__carousel img.active,
  .gl-product-card-container.show-variation-carousel .gl-product-card__carousel img:hover {
    border-bottom: 2px solid #000000; }
  .gl-product-card-container.show-variation-carousel .gl-product-card__carousel img {
    box-sizing: border-box;
    height: auto;
    min-height: 1px;
    width: 100%; }
  .gl-product-card-container.show-variation-carousel .gl-product-card__carousel .gl-carousel__prev-next-button {
    height: 100%;
    margin: 0;
    width: 20px; }

.gl-radio-group .gl-form-message,
.gl-radio-group .gl-form-hint {
  padding-left: 0; }

.gl-radio-input {
  display: flex;
  /*
   * Direction variations
   */ }
  .gl-radio-input__option {
    margin-right: 25px;
    flex-shrink: 0;
    margin-bottom: 0;
    position: relative;
    cursor: pointer; }
  .gl-radio-input__label {
    height: 24px;
    display: inline-flex;
    line-height: 24px; }
    .gl-radio-input__label::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      color: #98989a;
      border: 2px solid currentColor;
      background-color: #ffffff;
      text-transform: none;
      border-radius: 50%;
      cursor: pointer;
      pointer-events: none; }
  .gl-radio-input__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    padding: 0; }
    .gl-radio-input__input:checked ~ .gl-radio-input__label::before {
      color: #000000;
      box-shadow: inset 0 0 0 6px currentColor; }
  .gl-radio-input--horizontal {
    flex-direction: row; }
  .gl-radio-input--vertical {
    flex-direction: column; }
    .gl-radio-input--vertical .gl-radio-input__option {
      margin-right: 0; }
    .gl-radio-input--vertical .gl-radio-input__option + .gl-radio-input__option {
      margin-top: 15px; }

.gl-square-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
  max-height: none;
  padding: 0;
  margin: 0 -5px -5px 0; }
  .gl-square-list .gl-menu__element {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    line-height: 1.1;
    position: relative;
    cursor: pointer;
    height: 40px;
    width: auto;
    min-width: 40px;
    padding: 0 14px;
    margin: 0 5px 5px 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9dcdd;
    transition: border 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-square-list .gl-menu__element::after {
      content: '';
      display: block;
      background-color: #d9dcdd;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -1px;
      left: 0;
      transform: scaleY(0);
      transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-square-list .gl-menu__element:active, .gl-square-list .gl-menu__element:hover, .gl-square-list .gl-menu__element:focus {
      outline: none; }
      .gl-square-list .gl-menu__element:active::after, .gl-square-list .gl-menu__element:hover::after, .gl-square-list .gl-menu__element:focus::after {
        background-color: #000000;
        transform: scaleY(1); }
  .gl-square-list .gl-menu__item--active .gl-menu__element, .gl-square-list .gl-menu__item--active .gl-menu__element:active, .gl-square-list .gl-menu__item--active .gl-menu__element:hover, .gl-square-list .gl-menu__item--active .gl-menu__element:focus {
    border-color: #000000; }
    .gl-square-list .gl-menu__item--active .gl-menu__element::after, .gl-square-list .gl-menu__item--active .gl-menu__element:active::after, .gl-square-list .gl-menu__item--active .gl-menu__element:hover::after, .gl-square-list .gl-menu__item--active .gl-menu__element:focus::after {
      background-color: #000000;
      transform: scaleY(1); }
  .gl-square-list--condensed .gl-menu__element {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: .5px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    padding: 0 5px; }

/**
 * Star rating
 */
.gl-star-rating {
  display: inline-flex;
  color: #000000; }
  .gl-star-rating__star {
    height: 1em;
    width: 1em;
    fill: #ffffff; }

/**
 * Tables
 * 1. Default Styles
 * 2. Horizontal Tables
 * 3. Animations
 */
/**
 * 1. Default Styles
 */
.gl-table {
  /**
  * 2. Horizontal Tables
  */ }
  .gl-table table {
    border-collapse: collapse; }
  .gl-table th,
  .gl-table td {
    min-width: 80px;
    height: 60px;
    border-style: solid;
    border-width: 1px;
    border-color: #ebedee; }
  .gl-table__content {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; }
  .gl-table .gl-table__label {
    position: relative;
    padding: 0 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #000000;
    text-align: center;
    background-color: #ebedee;
    border-color: #d9dcdd; }
  .gl-table .gl-table__highlight-cell {
    color: #ffffff;
    background-color: #000000;
    border: 2px solid #000000; }
  .gl-table .gl-table__highlight-column {
    border-left: 2px solid #000000;
    border-right: 2px solid #000000; }
  .gl-table .gl-table__highlight-row {
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000; }
  .gl-table .gl-table__highlight-label {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    background-color: #000000;
    color: #ffffff;
    border: 2px solid #000000; }
  .gl-table .gl-table__row-label {
    color: #000000;
    text-transform: uppercase; }
  .gl-table__select {
    display: block;
    appearance: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer; }
  .gl-table__switcher .icon-arrow-down {
    padding-left: 10px; }
  .gl-table__legend {
    margin: 0; }
  .gl-table--horizontal {
    margin-bottom: 0; }
  .gl-table--horizontal .gl-table__scrollable {
    margin-bottom: 0; }
    .gl-table--horizontal .gl-table__scrollable th,
    .gl-table--horizontal .gl-table__scrollable td {
      white-space: nowrap; }
    .gl-table--horizontal .gl-table__scrollable[data-blink='true'] {
      animation: gl-anim-move-x 3s 1; }
      .gl-table--horizontal .gl-table__scrollable[data-blink='true']:before {
        background-color: #000000;
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        animation: gl-anim-blink 3s 1; }
  .gl-table--horizontal .gl-table__container {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row; }
  .gl-table--horizontal .gl-table__fixed-columns {
    background-color: #ffffff;
    flex: 0 1 auto;
    white-space: nowrap;
    width: auto; }
    .gl-table--horizontal .gl-table__fixed-columns table {
      width: 100%;
      border-left: none;
      border-right: 2px solid #000000; }
  .gl-table--horizontal .gl-table__scrollable-columns {
    flex: 100% 1 1;
    overflow-x: auto;
    margin-bottom: 0;
    -webkit-overflow-scrolling: touch; }

/**
 * 3. Animations
 */
@keyframes gl-anim-move-x {
  0% {
    transform: translate3d(0, 0, 0); }
  50% {
    transform: translate3d(-10%, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes gl-anim-blink {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.2; }
  90% {
    opacity: 0; }
  100% {
    display: none; } }

/**
 * Textareas
 */
.gl-textarea {
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border: 1.01565px solid #98989a;
  width: 100%;
  margin: 0;
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  padding: 15px 31px 15px 21px;
  resize: none; }
  .gl-textarea:focus {
    border-color: #000000;
    box-shadow: 0 1px #000000; }
  .gl-form-item--error .gl-textarea {
    border-color: #e63f3f;
    box-shadow: 0 1px #e63f3f; }
  .gl-textarea::placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  .gl-textarea::-webkit-input-placeholder, .gl-textarea:-ms-input-placeholder, .gl-textarea::-ms-input-placeholder, .gl-textarea:-moz-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000; }
  .gl-textarea__label {
    display: flex;
    font-size: 16px;
    letter-spacing: 1px;
    /* stylelint-disable-line  */
    color: #98989a;
    position: absolute;
    pointer-events: none;
    text-transform: uppercase;
    left: 21px;
    top: 14px;
    transition: .2s ease all; }
    .gl-form-item--hasFocus .gl-textarea__label,
    .gl-form-item--has-focus .gl-textarea__label {
      font-size: 14px;
      top: -11px;
      left: 17px;
      margin-top: 0;
      padding: 2px 5px 1px;
      background-color: #ffffff; }
  .gl-textarea__icon {
    position: absolute;
    top: 10px;
    right: 10px; }
    .gl-form-item--error .gl-textarea__icon {
      color: #e63f3f; }
    .gl-form-item--success .gl-textarea__icon {
      color: #00aa55; }

/**
 * Time
 * 1. General Styles
 */
.gl-time-progress {
  text-align: center; }
  .gl-time-progress__bar {
    background: url("./adidas-foundation/dist/adidas/assets/img/shared/bg-pattern-dashed.svg") top left repeat;
    background-size: 7px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    opacity: .5; }
  .gl-time-progress .gl-box__content {
    padding: 15px 10px 17.5px; }
  @media (min-width: 600px) {
    .gl-time-progress .gl-box__content {
      padding: 30px 20px 35px; } }

/**
 * Toggle
 */
.gl-toggle {
  display: flex;
  padding: 0;
  margin: 0 0 20px 0; }
  .gl-toggle .gl-menu__item {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    line-height: 16px;
    position: relative;
    list-style: none;
    text-align: center;
    border: 1px solid #d9dcdd;
    margin-left: -1px; }
    .gl-toggle .gl-menu__item:first-child {
      margin-left: 0; }
    .gl-toggle .gl-menu__item--active {
      border-color: #000000;
      box-shadow: inset 0 -2px 0 #000000;
      z-index: 1;
      position: relative; }
      .gl-toggle .gl-menu__item--active .gl-menu__element {
        color: #000000; }
  .gl-toggle .gl-menu__element {
    width: 100%;
    padding: 16px 10px;
    color: #98989a;
    cursor: pointer;
    transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-toggle .gl-menu__element:focus, .gl-toggle .gl-menu__element:hover {
      color: #000000; }

/**
 * Tooltips
 */
.gl-tooltip {
  position: relative;
  margin-left: 10px;
  display: inline-block;
  /**
    * 2. Tooltip icon
    */ }
  .gl-tooltip__panel {
    position: absolute;
    z-index: 2;
    background-color: #ebedee;
    text-align: left;
    text-align: start;
    /* stylelint-disable-line declaration-block-no-duplicate-properties */
    width: 290px;
    max-width: calc(100vw - 40px); }
    .gl-tooltip__panel--small {
      width: 200px; }
    .gl-tooltip__panel--large {
      width: 500px; }
  .gl-tooltip__arrow {
    position: absolute;
    overflow: hidden;
    width: 22px;
    height: 18px;
    z-index: 3; }
    .gl-tooltip__arrow::after {
      content: '';
      position: absolute;
      bottom: auto;
      display: block;
      width: 0;
      border-style: solid;
      border-width: 0 11px 18px 11px;
      border-color: #ebedee transparent; }
    .gl-tooltip__arrow--right {
      left: -20px;
      transform: rotate(-90deg) translateX(-2px); }
    .gl-tooltip__arrow--left {
      right: -20px;
      transform: rotate(90deg) translateX(-2px); }
    .gl-tooltip__arrow--top {
      bottom: -18px;
      transform: rotate(180deg); }
    .gl-tooltip__arrow--bottom {
      top: -18px;
      transform: translateX(-1px); }
  .gl-tooltip__inner {
    padding: 20px;
    min-width: 200px; }
    .gl-tooltip__inner p {
      margin-bottom: 0;
      line-height: 20px; }
  .gl-tooltip__title,
  .gl-tooltip h5 {
    font-size: 18px;
    line-height: 16px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0;
    margin-bottom: 10px; }
    @media (min-width: 1280px) {
      .gl-tooltip__title,
      .gl-tooltip h5 {
        font-size: 20px;
        line-height: 18px; } }
  .gl-tooltip__button {
    letter-spacing: 0; }
    .gl-tooltip__button p:last-child {
      margin-bottom: 0; }
  .gl-tooltip--with-icon .gl-tooltip__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 2px;
    background-color: #ebedee;
    cursor: pointer;
    transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    margin-bottom: 0; }
    .gl-tooltip--with-icon .gl-tooltip__button:focus, .gl-tooltip--with-icon .gl-tooltip__button:hover {
      color: #98989a; }
  .gl-tooltip__icon {
    max-width: 100%;
    max-height: 100%; }

/**
 * 4.  Misc
 */
/**
 * Misc
 * 1. Fetched content
 */
/**
 * 1. Fetched content
 */
.gl-fetched-content ul {
  list-style: disc inside; }
  .gl-fetched-content ul li {
    padding-left: 12px; }

.gl-fetched-content ol {
  list-style: decimal inside; }
  .gl-fetched-content ol li {
    padding-left: 12px; }

.gl-fetched-content a {
  display: inline;
  vertical-align: bottom;
  padding-left: 1px;
  padding-right: 1px;
  color: #000000;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
  @media (pointer: fine) {
    .gl-fetched-content a:not(.gl-cta--disabled):focus:not(:active), .gl-fetched-content a:not(.gl-cta--disabled):hover:not(:active) {
      color: #ffffff;
      background-color: #000000; } }
  @supports (-moz-appearance: none) {
    .gl-fetched-content a:not(.gl-cta--disabled):focus:not(:active), .gl-fetched-content a:not(.gl-cta--disabled):hover:not(:active) {
      color: #ffffff;
      background-color: #000000; } }
  .gl-fetched-content a:active {
    color: #ffffff;
    background-color: #000000; }
  .gl-fetched-content a .gl-icon {
    position: relative;
    top: 1px;
    margin-right: 7px; }

.gl-fetched-content hr {
  border: 0;
  margin: 0 0 20px 0;
  border-bottom: 1px;
  border-bottom-color: #d9dcdd;
  border-bottom-style: solid;
  border-bottom-color: #98989a; }

.gl-fetched-content h1 {
  font-size: 36px;
  line-height: 32px; }
  @media (min-width: 1280px) {
    .gl-fetched-content h1 {
      font-size: 42px;
      line-height: 38px; } }

.gl-fetched-content h2 {
  font-size: 26px;
  line-height: 24px; }
  @media (min-width: 1280px) {
    .gl-fetched-content h2 {
      font-size: 30px;
      line-height: 28px; } }

.gl-fetched-content h3,
.gl-fetched-content h4 {
  font-size: 18px;
  line-height: 16px; }
  @media (min-width: 1280px) {
    .gl-fetched-content h3,
    .gl-fetched-content h4 {
      font-size: 20px;
      line-height: 18px; } }

body {
  overflow-y: auto; }

.svgiconsprite {
  display: none; }

.gl-cta--hide-arrow .arrow-right-long,
.gl-cta--hide-arrow .gl-icon {
  display: none; }

.gl-cta--margin-left-top {
  margin-left: 1%;
  margin-top: 1%; }

.gl-cta--margin-right-top {
  margin-right: 1%;
  margin-top: 1%; }

.thumbnail__wrapper {
  position: relative;
  margin: auto;
  width: 120px;
  height: 80px;
  display: block;
  background-color: #ebedee;
  cursor: pointer; }

.thumbnail__wrapper:hover .image__wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 2;
  background-image: linear-gradient(#000000ba, #ffffff00); }

.thumbnail__wrapper:hover .thumbnail__action-buttons {
  display: block; }

.thumbnail__wrapper .image__wrapper--video::after {
  content: url("./assets/icons/play-solid.svg");
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.thumbnail__wrapper:hover .image__wrapper:after {
  content: url("./assets/icons/search.svg");
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.thumbnail__wrapper .image__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  margin: auto; }

.list--noitems {
  margin: 20px 0; }
  .list--noitems .gl-callout {
    max-width: 100%;
    text-align: center; }

.thumbnail__action-buttons {
  display: none;
  z-index: 2;
  position: absolute;
  width: auto;
  height: auto;
  padding-top: 5px;
  top: 0; }

.paper-effect {
  background-color: #fff;
  position: relative;
  margin: 2px 10px 3px 4px;
  padding: 0;
  display: inline-block;
  width: auto; }

.paper-effect,
.paper-effect::before,
.paper-effect::after {
  /* Add shadow to distinguish sheets from one another */
  box-shadow: 2px 1px 1px rgba(152, 152, 154, 0.5); }

.paper-effect::before,
.paper-effect::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eee; }

/* Second sheet of paper-effect */
.paper-effect::before {
  left: 3px;
  top: 2px;
  z-index: -1; }

/* Third sheet of paper-effect */
.paper-effect::after {
  left: 6px;
  top: 5px;
  z-index: -2; }

.list--error {
  margin: 20px 0; }
  .list--error .gl-callout {
    max-width: 100%;
    text-align: center; }
    .list--error .gl-callout .callout-error__text {
      padding-top: 20px; }

.gl-modal {
  z-index: 5; }

.main-container {
  margin: 20px; }

.expiration-badge {
  padding: 5px 10px 3px;
  font-size: 10px;
  text-align: center;
  margin-top: 5px;
  display: inline-block;
  text-transform: uppercase; }
  .expiration-badge--expired {
    background: #e63f3f;
    color: #ffffff; }
  .expiration-badge--expires-soon {
    background: #ede734;
    color: #000000; }

.link-button {
  cursor: pointer; }

.ellipsis {
  width: 100%;
  overflow: hidden;
  display: inline-block !important;
  text-overflow: ellipsis;
  white-space: nowrap; }

.ellipsisPreview {
  width: 1000px; }

.previewLoader {
  width: 30vw; }

.capitalize {
  text-transform: capitalize; }

.text-center {
  text-align: center; }

.m-auto {
  margin: auto; }

.linkButton {
  color: #40846f !important;
  cursor: pointer; }

.breakWord {
  word-break: break-all; }

.marginLeft {
  margin-left: 1%; }

.marginTop {
  margin-top: 1%; }

.tooltipWidth {
  width: auto;
  word-break: break-all; }

.usageTooltip {
  width: auto; }

.tippy-tooltip[data-animatefill] {
  background-color: #333; }

.discList {
  list-style-type: disc;
  text-align: left;
  margin-left: 5px;
  word-break: break-all;
  min-width: 100%; }

.showMore .gl-tooltip {
  margin: 0;
  margin-top: 5px; }

.mt-0 {
  margin-top: 0 !important; }

.generic-container {
  position: relative;
  margin: 20px; }

.page-title {
  margin-bottom: 22px; }

.filter-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }

svg {
  pointer-events: none; }

.schedule-change-save .gl-loader {
  width: 20px;
  height: 20px; }

.info-icon {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  margin-top: -5px; }

.custom-checkbox {
  cursor: pointer;
  font-size: 14px; }
  .custom-checkbox__icon {
    margin: 0 14px 0 0;
    vertical-align: middle;
    width: 24px;
    height: 24px; }
  .custom-checkbox__label {
    line-height: 24px; }
  .custom-checkbox--disabled {
    opacity: 0.6; }

/* IE 11 specific css should go here */
@media all and (-ms-high-contrast: none) {
  .modal-items__preview {
    display: block !important; }
  h1,
  h2,
  h3,
  h4 {
    padding-top: 3px !important; }
  .gl-table .gl-table__label {
    position: inherit !important; }
  .generic-container {
    margin: 20px; } }

.tippy-popper {
  max-width: 800px !important; }

.core-green__main {
  color: #40846f; }
