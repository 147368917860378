/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.NavTab {
  padding: 1rem;
  color: #ffffff;
  border-bottom: 3px solid #000000;
  display: inline-block;
  text-transform: uppercase; }

.active .NavTab {
  color: #97dad0 !important;
  border-bottom: 3px solid #97dad0; }
