.header-actions__separator {
  height: 25px;
  margin-bottom: 5px;
  border-left: 1px solid #868080;
}

.arrow-left{
  width: 10px !important;
  height: 15px !important;
  margin-bottom: 5px;
  margin-right: -5px;
}
.header-actions__item {

  a {
    text-decoration: none;
    color: white !important;
  }
}

.header-actions__button--disabled {
  opacity: 0.5;

  .header-actions__item {
    
    &:hover {
      cursor: not-allowed;
      color: white;
      background: black;

      .header-actions__icon {
        fill: white;
      }
    }

    
  }
}

.header-actions__tooltip::after {
  width: max-content;
}
