.react-select {
  &__input {
    input {
      height: auto !important;
      &:focus {
        box-shadow: none;
      }
    }
  }
  &__menu {
    .react-select__group {
      &-heading {
        font-size: 18px;
        background-color: #e6e6e6;
        padding: 5px 10px;
      }
    }
  }
}
