/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.Header {
  background: #000000;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3; }

.logo-container {
  display: flex;
  padding: 1rem; }

.header-right {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column; }

.header-user {
  display: flex;
  text-align: right;
  align-content: flex-end; }
  .header-user__inner {
    display: flex;
    position: absolute;
    top: 10px;
    right: 20px; }
  .header-user__avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 0.7rem; }
  .header-user__name {
    margin-right: 0.7rem; }
  .header-user__ellipsis {
    fill: #ffffff;
    height: 15px;
    width: 15px; }
  .header-user__logout-button {
    cursor: pointer;
    color: #000000;
    padding: 5px;
    display: block;
    width: 100%; }

.header-action-bar {
  flex: 1 0 auto;
  display: flex;
  align-items: flex-end;
  justify-items: end; }

.header-actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  transition-duration: 0.5s; }

.header-actions__item {
  padding: 0.5rem 0.7rem;
  font-size: 0.8rem;
  position: relative; }
  .header-actions__item button {
    cursor: pointer; }
  .header-actions__item:hover .header-submenu, .header-actions__item:active .header-submenu, .header-actions__item:focus .header-submenu {
    visibility: visible;
    opacity: 1;
    display: block; }

.header-actions__item:hover {
  background: #98989a;
  color: #000000;
  cursor: pointer; }
  .header-actions__item:hover .header-actions__icon {
    fill: #000000; }

.header-actions__item-text {
  vertical-align: bottom;
  padding-left: 0.6rem; }

.header-actions__icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: bottom;
  fill: #ffffff; }

.header-actions__icon--smaller {
  height: 20px;
  width: 20px; }

.header-submenu {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  z-index: 2;
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 250px;
  background: #000000;
  color: #ffffff; }
  .header-submenu__item {
    padding: 0.5rem 0.7rem;
    font-size: 0.8rem; }
    .header-submenu__item button {
      cursor: pointer; }
    .header-submenu__item-text {
      vertical-align: bottom;
      padding-left: 0.6rem; }
    .header-submenu__item:hover {
      background: #98989a;
      color: #000000;
      cursor: pointer; }
      .header-submenu__item:hover .header-submenu__icon {
        fill: #000000; }
  .header-submenu__icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: bottom;
    fill: #ffffff; }
    .header-submenu__icon--smaller {
      height: 20px;
      width: 20px; }

.header-user__ellipsis-container button {
  color: black; }
  .header-user__ellipsis-container button:hover {
    cursor: pointer; }

.header-user__ellipsis-container .gl-tooltip__arrow::after {
  top: 6px !important; }
