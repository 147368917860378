.checkboxTreeContainer {
  &__selection-text {
    margin: 10px 0 5px;
  }
  .selection-now-allowed {
    opacity: 0.35;
    pointer-events: none !important;
    cursor: not-allowed;
  }
  .customCheckboxNode {
    &--children {
      margin-left: 1rem;
    }

    .customCheckboxItem {
      display: flex;
      margin: 5px 0;

      &--container {
        display: flex;

        &:hover {
          background: rgba(51, 51, 204, 0.15);
          cursor: pointer;
        }
      }

      &--colapse,
      &--checkbox {
        flex: 0 0 20px;
        height: 20px;

        svg {
          height: 20px;
          width: 20px;
        }
      }

      &--colapse {
        &__clickable:hover {
          cursor: pointer;
        }
      }

      &--label {
        margin-left: 5px;
        &__counter {
          opacity: 0.6;
          margin-left: 5px;
        }
      }
    }
  }
}
