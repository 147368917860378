.slider > .slider-frame > .slider-list {
  width: auto !important;
}

.carousel-slider {
  height: auto;
  min-width: 80vw;
  width: 100%;

  .slider {
    width: 100%;
    text-align: center;
    position: relative;

    .slider-control-bottomcenter,
    .slider-control-centerright,
    .slider-control-centerleft {
      display: none;
    }

    .slider-slide {
      text-align: left;

      & img,
      & video {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  button.button--left {
    left: 5px;
  }

  button.button--right {
    right: 5px;
  }
}
.carousel-counter {
  text-align: center;
  color: grey;
  font-size: smaller;
  padding-bottom: 10px;
}
