/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.custom-pagination {
  display: flex;
  margin-top: 0;
  margin-bottom: 0; }

.current-page {
  font-size: 1.5rem;
  vertical-align: middle; }

.custom-pagination__item {
  color: #000000;
  border-bottom: 3px solid white; }

.custom-pagination__item--active .custom-pagination__link,
.custom-pagination__item:hover {
  border-bottom: 3px solid #000000; }

.custom-pagination__link {
  padding: 0.65rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #000000 !important;
  color: #000000;
  font-weight: 900;
  font-size: 1rem; }

.custom-pagination__link:hover {
  cursor: pointer; }
