@media (max-width: 768px) {
  .add-edit-table .genetic-table-container {
    // overflow: auto !important;
  }
}
.add-edit-table .genetic-table-container {
  height: auto !important;
  max-height: none !important;
  margin-bottom: 5px;
  overflow-y: hidden;
  display: table !important;
}

.frame-edit-modal .gl-modal__dialog {
  width: 100% !important;
  max-width: none !important;
}
.frames-edit-icons {
  width: 22px !important;
  height: 22px !important;
  fill: black !important;
}
.frames-add-edit td:last-child {
  height: 125px !important;
  text-align: center !important;
  vertical-align: middle;
  display: table-cell;
}
.clickable {
  cursor: pointer;
}
.float-right {
  float: right;
}
.frame-edit-modal .gl-modal__main {
  overflow: hidden !important;
}
.scrollable-content {
  overflow-y: auto;
  height: 371px;
  padding-right: 15px;
  margin-bottom: 15px;
}
.collapse-icon {
  justify-content: right !important;
  height: 20px !important;
  width: 30px !important;
  min-height: 20px !important;
  border: none !important;
  margin-top: 13px;
}
.collapse-all-icon {
  margin-top: 0px !important;
}
.margin-10 {
  margin: 10px !important;
}
.right-text-button {
  margin-left: auto;
}
.right-text-button:hover {
  opacity: 0.7;
}
.add-new-frame:hover {
  opacity: 0.7;
}
.edit-frame-table {
  border-color: #ebedee;
  margin-right: 0px;
  display: inline-table;
  padding: 0 10px 0px 0px;
}
.apply-all-table .genetic-table-container {
  height: auto !important;
  max-height: none !important;
  display: table !important;
}
.apply-all-table {
  padding: 5px 0px 0px 10px;
}
.apply-all-table .gl-input {
  min-height: 0px !important;
}
.apply-all-table .gl-table th {
  height: 45px !important;
  padding: 0px 10px;
}
.apply-all-table .gl-table td {
  height: 45px !important;
  padding: 0px 10px;
}
.apply-all-table .gl-input__field {
  min-height: 45px !important;
}
.apply-all-table .gl-dropdown__select {
  height: 45px !important;
  letter-spacing: 1px !important;
  margin-top: 5px !important;
}
.add-edit-table .gl-input {
  min-height: 0px !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.add-edit-table .frame-input-margin-bottom {
  margin-bottom: 0px !important;
  width: 180px;
}

.add-edit-table .frame-input-label .gl-input__label--hoisted {
  background-color: transparent;
}

.add-edit-table .gl-table th {
  height: 45px !important;
  padding: 0px 10px;
}
.add-edit-table .gl-table td {
  height: 45px !important;
  padding: 0px 10px;
  vertical-align: top;
}
.gl-dropdown {
  padding-top: 10px;
}
.delete-icon {
  padding-top: 25px;
}
.add-edit-table .gl-dropdown__select {
  height: 45px !important;
  letter-spacing: 1px !important;
  margin-top: 5px !important;
}
.add-edit-table .gl-input__field {
  border: 1px solid #ebedee !important;
  min-height: 45px !important;
}

.add-edit-table .gl-dropdown__select,
.gl-dropdown__option {
  border-color: #ebedee;
}
.add-edit-table .edited-field .gl-dropdown__select {
  box-shadow: 0 1px 0 0 #00aa55 !important;
}
.add-edit-table .no-selection .gl-dropdown__select {
  box-shadow: 0 1px 0 0 #e63f3f !important;
}
// .add-edit-table .gl-form-hint--error {
//   position: fixed;
// }
.apply-all-table .gl-input__field {
  border: 1px solid #ebedee !important;
}
.apply-all-table .gl-dropdown__select,
.gl-dropdown__option {
  border-color: #ebedee;
}
.selected-channels {
  margin: 5px 10px !important;
  font-weight: normal;
}
.apply-all-button {
  margin: 10px 0px;
}
.number-of-frames {
  margin-left: 10px !important;
  font-size: 16px !important;
  font-weight: normal;
}
.apply-all-collapse-icon {
  background-color: #f7f7f7 !important;
}
.player-headers {
  width: auto;
}
.player-headers:hover {
  opacity: 0.7;
}
.collapse-button {
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 5px;
  display: flex;
}
.expand-collape-label {
  padding-right: 5px;
}
.add-icon {
  margin-right: 10px;
  margin-bottom: 2px;
}
.add-new-frame-header {
  margin: 15px 0px 0px 15px !important;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.channel-data {
  margin-top: 20px;
}
.customized-modal {
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.4);
}
.customized-modal-body {
  width: 500px;
  background: white;
  border: 1px solid;
}
.customized-modal-container {
  padding: 15px;
}
.apply-all-table .frames-add-edit th {
  display: none;
}
.disabled-icon {
  opacity: 0.2;
  cursor: auto;
}
.warning-icon {
  margin-top: 13px !important;
  margin-left: 6px !important;
}
.dimension-input .gl-form-item {
  margin: 0px 10px !important;
}
.dimension-input .gl-input {
  min-height: auto;
  margin-bottom: 0px;
  width: min-content;
}
.dimension-label {
  margin: 25px 0px;
}
.frame-color-icon {
  width: 15px;
  height: 15px;
  margin-left: -5px;
  margin-right: 10px;
  margin-top: 30px;
}
.custom-screen-size .gl-checkbox__text {
  margin-left: 10px;
}
.custom-screen-size-container .gl-form-item {
  margin: 0 !important;
}
.custom-screen-size-container {
  margin: auto 10px !important;
}
.dimension-input .gl-form-item {
  width: 160px;
}
.frames-add-edit .generic-table__column--name {
  width: 250px;
}

.frames-add-edit .frame-input-label {
  width: 180px;
}