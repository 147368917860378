/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.playlist-row__playlist-type {
  font-size: 0.6rem; }

a {
  color: #0071ae !important; }

div.gl-form-item > div > label {
  font-size: 14px; }

.step-buttons--hide-arrow .arrow-right-long,
.step-buttons--hide-arrow .gl-icon {
  display: none; }

.step-buttons__icon {
  height: 20px;
  width: 20px;
  margin-left: 5px; }

.cd-breadcrumb,
.cd-multi-steps {
  padding: 0.5em 1em;
  background-color: #edeff0;
  border-radius: 0.25em; }

.cd-breadcrumb::after,
.cd-multi-steps::after {
  clear: both;
  content: '';
  display: table; }

.cd-breadcrumb li,
.cd-multi-steps li {
  display: inline-block;
  float: left;
  margin: 0.5em 0; }

.cd-breadcrumb li::after,
.cd-multi-steps li::after {
  /* this is the separator between items */
  display: inline-block;
  content: '\00bb';
  margin: 0 0.6em;
  color: #959fa5; }

.cd-breadcrumb li:last-of-type::after,
.cd-multi-steps li:last-of-type::after {
  /* hide separator after the last item */
  display: none; }

.cd-breadcrumb li > *,
.cd-multi-steps li > * {
  /* single step */
  display: inline-block;
  font-size: 1.4rem;
  color: #2c3f4c; }

.cd-breadcrumb li.current > *,
.cd-multi-steps li.current > * {
  /* selected step */
  color: #96c03d; }

.no-touch .cd-breadcrumb a:hover,
.no-touch .cd-multi-steps a:hover {
  /* steps already visited */
  color: #96c03d; }

@media only screen and (min-width: 768px) {
  .cd-breadcrumb,
  .cd-multi-steps {
    padding: 0 1.2em; }
  .cd-breadcrumb li,
  .cd-multi-steps li {
    margin: 1.2em 0; }
  .cd-breadcrumb li::after,
  .cd-multi-steps li::after {
    margin: 0 1em; }
  .cd-breadcrumb li > *,
  .cd-multi-steps li > * {
    font-size: 1rem; } }

@media only screen and (min-width: 768px) {
  .cd-breadcrumb.triangle {
    /* reset basic style */
    background-color: transparent;
    padding: 0; }
  .cd-breadcrumb.triangle li {
    position: relative;
    padding: 0;
    margin: 4px 4px 4px 0; }
  .cd-breadcrumb.triangle li:last-of-type {
    margin-right: 0; }
  .cd-breadcrumb.triangle li > * {
    position: relative;
    padding: 0.7em 0.8em 0.9em 2.5em;
    color: #2c3f4c;
    background-color: #dff4ef;
    /* the border color is used to style its ::after pseudo-element */
    border-color: #dff4ef; }
  .cd-breadcrumb.triangle li.current > * {
    /* selected step */
    color: #ffffff;
    background-color: #67c5aa;
    border-color: #67c5aa; }
  .cd-breadcrumb.triangle li:first-of-type > * {
    padding-left: 1.6em;
    border-radius: 0.25em 0 0 0.25em; }
  .cd-breadcrumb.triangle li:last-of-type > * {
    padding-right: 1.6em;
    border-radius: 0 0.25em 0.25em 0; }
  .no-touch .cd-breadcrumb.triangle a:hover {
    /* steps already visited */
    color: #ffffff;
    background-color: #2c3f4c;
    border-color: #2c3f4c; }
  .cd-breadcrumb.triangle li::after,
  .cd-breadcrumb.triangle li > *::after {
    /* 
    	li > *::after is the colored triangle after each item
    	li::after is the white separator between two items
    */
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 0;
    width: 0;
    /* 48px is the height of the <a> element */
    border: 24px solid transparent;
    border-right-width: 0;
    border-left-width: 20px; }
  .cd-breadcrumb.triangle li::after {
    /* this is the white separator between two items */
    z-index: 1;
    -webkit-transform: translateX(4px);
    -moz-transform: translateX(4px);
    -ms-transform: translateX(4px);
    -o-transform: translateX(4px);
    transform: translateX(4px);
    border-left-color: #ffffff;
    /* reset style */
    margin: 0; }
  .cd-breadcrumb.triangle li > *::after {
    /* this is the colored triangle after each element */
    z-index: 2;
    border-left-color: inherit; }
  .cd-breadcrumb.triangle li:last-of-type::after,
  .cd-breadcrumb.triangle li:last-of-type > *::after {
    /* hide the triangle after the last step */
    display: none; }
  .cd-breadcrumb.triangle.custom-separator li::after {
    /* reset style */
    background-image: none; }
  .cd-breadcrumb.triangle.custom-icons li::after,
  .cd-breadcrumb.triangle.custom-icons li > *::after {
    /* 50px is the height of the <a> element */
    border-top-width: 25px;
    border-bottom-width: 25px; }
  @-moz-document url-prefix() {
    .cd-breadcrumb.triangle li::after,
    .cd-breadcrumb.triangle li > *::after {
      /* fix a bug on Firefix - tooth edge on css triangle */
      border-left-style: dashed; } } }

.step-buttons {
  width: 50%; }

.step-buttons-list {
  display: flex;
  justify-content: flex-end; }
  .step-buttons-list__item {
    margin-left: 10px; }

.cd-breadcrumb.triangle .current a {
  color: #ffffff !important; }

.cd-breadcrumb.triangle a {
  color: #3a3e4f !important; }

.dashboard-actions {
  margin: 20px;
  display: flex; }

.breadcrumb-button {
  cursor: pointer; }

.breadcrumb-button--disabled {
  cursor: not-allowed; }

.button--viewscreenshot {
  cursor: pointer !important;
  height: auto !important;
  width: 5 !important;
  padding: 0px !important;
  margin-left: 16px !important;
  color: rgba(0, 0, 0, 0.9) !important;
  border: 0 !important;
  min-height: 0px !important;
  background-color: #ffffff00 !important; }

.button--viewscreenshot::after {
  box-shadow: none !important; }

.screenshot-icon {
  margin-top: 5px;
  justify-content: flex-end; }

.screenshot-icon-styling {
  height: 22px !important; }

.display-flex {
  display: flex; }

.frame-icon {
  margin-top: auto;
  margin-left: auto;
  justify-content: flex-end;
  align-items: flex-end; }

.frame-icon .gl-cta--primary::after {
  box-shadow: none !important; }

.frame-icon-styling {
  display: inline-block;
  height: 20px !important; }

.button--viewframeDetails {
  color: rgba(174, 174, 174, 0.9) !important;
  font-size: 14px;
  margin: 4px 0px 0px 15px !important;
  padding: 0px 6px 0px 6px !important;
  cursor: pointer;
  height: 18px !important;
  width: auto !important;
  border: 1px solid rgba(174, 174, 174, 0.9) !important;
  min-height: px !important;
  background-color: #ffffff00 !important; }

.default-cursor {
  cursor: default; }

.schedulename-icon {
  justify-content: flex-start; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.p-2 {
  padding: 0.5rem !important;
  box-shadow: none !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.p-2-name {
  margin-top: 0.5rem; }
