/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
@keyframes highlight {
  0% {
    background-color: lightgreen; }
  100% {
    background-color: white; } }

.highlighter {
  animation: highlight;
  animation-duration: 4s; }

.selected-file {
  margin-top: 2%; }

.image-thumbnail__container {
  margin-right: 1%; }

.uploading__title {
  margin: 1%; }

.uploading__title__browsed {
  text-align: center;
  margin-bottom: 5px; }

.uploading__rejection {
  position: relative;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 5px;
  flex-wrap: wrap;
  border: 1px solid #eee;
  background-color: #ebedee;
  padding: 20px;
  max-width: 580px;
  margin: 0 auto;
  z-index: 999; }
  .uploading__rejection .validation-error {
    margin-bottom: 5px; }
  .uploading__rejection ul {
    color: #e63f3f;
    list-style: square;
    margin-top: 0;
    padding-left: 20px; }

.upload-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }

.uploading-wrapper {
  display: flex;
  margin: 1%;
  flex-wrap: wrap; }

.upload-wrapper__image-thumbnail {
  display: block;
  width: 10%;
  height: auto;
  margin-right: 1%;
  margin-bottom: 1%; }

.upload-wrapper__image-thumbnail--preview {
  height: 320px;
  margin-bottom: 1%; }

.upload-wrapper__image-thumbnail--img {
  width: 100%;
  height: auto; }

.progress-bar {
  position: relative;
  height: 4px;
  border-radius: 1px;
  background: #ebedee; }

.progress-bar__filler {
  text-align: center;
  background: #a7c1b9;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
  padding-top: 10px; }

.drag-and-drop__overlay {
  position: fixed;
  top: 103px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  background-image: linear-gradient(45deg, #a7c1b9 16.67%, #ffffff 16.67%, #ffffff 50%, #a7c1b9 50%, #a7c1b9 66.67%, #ffffff 66.67%, #ffffff 100%);
  background-size: 4.24px 4.24px;
  opacity: 0.7;
  text-align: center;
  color: #000000;
  z-index: 999;
  font-size: 56px; }

.drag-and-drop__overlay--message {
  margin-top: 20%;
  text-transform: uppercase; }

.upload-wrapper__form {
  width: 400px;
  z-index: 1; }

.upload-wrapper__input-wrapper {
  position: relative;
  width: 100%;
  height: 50px; }
  .upload-wrapper__input-wrapper:after {
    content: attr(data-text);
    text-align: center;
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    background: #a7c1b9;
    padding: 10px 15px;
    display: block;
    width: 100%;
    pointer-events: none;
    z-index: 20;
    line-height: 30px;
    color: #ffffff;
    border-radius: 0.25em;
    font-weight: 300; }
  .upload-wrapper__input-wrapper .upload-wrapper__input-wrapper--input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 30px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%; }

.uploading__border {
  right: 0;
  bottom: 0;
  left: 0;
  max-width: unset !important; }
  .uploading__border .gl-box__content {
    height: 100%;
    display: flex; }

.uploading__icon {
  width: 80px;
  height: 80px;
  fill: #a7c1b9; }

.file__icon {
  display: flex;
  width: 48px;
  height: auto;
  vertical-align: bottom;
  fill: #ebedee;
  margin: auto; }

.edit-uploaded-media {
  margin-top: 30px; }

.edit-uploaded-media__label {
  margin-left: 2%;
  font-size: 24px; }

.uploading-wrapper {
  display: flex;
  padding: 20px;
  margin: 0; }

.uploaded-image {
  display: flex;
  align-items: center;
  width: 100%; }
  .uploaded-image__cancel {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
    .uploaded-image__cancel svg {
      width: 15px; }

.uploaded-image__thumb {
  min-height: 48px; }
  .uploaded-image__thumb img {
    width: 48px;
    height: auto; }

.uploaded-image__progress {
  width: 100%; }

.edit-uploaded-media {
  text-align: center;
  margin-bottom: 30px; }

.uploading__container {
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
  padding: 25px;
  cursor: pointer; }

.uploading__dndzone {
  width: 35%;
  border: 3px dashed #ebedee;
  padding: 70px;
  margin-right: 40px;
  height: 100%; }

.uploading__status {
  position: relative;
  width: 50%; }

.uploading__title {
  margin: 0 20px; }

.uploaded-image {
  padding: 20px 0;
  border-bottom: 1px solid #ebedee; }
  .uploaded-image:first-child {
    padding-top: 0; }
  .uploaded-image__name {
    word-break: break-word;
    margin-bottom: 10px; }
  .uploaded-image__details {
    padding: 0 20px;
    width: 90%;
    position: relative;
    text-align: left; }
    .uploaded-image__details .validation-error {
      margin-bottom: 5px; }
  .uploaded-image__upload-stats {
    display: flex;
    margin-top: 10px; }
    .uploaded-image__upload-stats span {
      margin-left: auto; }

.invalid-file-errors {
  list-style-type: disc;
  text-align: left; }
