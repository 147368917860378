.generic-table__label--store,
.generic-table__label--status {
  .generic-table__input {
    min-width: 160px !important;
  }

  .gl-input__label--hoisted {
    // display: none;
    top: -20px !important;
  }
}
.player__description {
  display: block;
  margin-top: 2px;
  margin-bottom: 0;
}
.player__name {
  margin-top: auto;
  margin-bottom: auto;
}
