/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.react-datepicker {
  box-sizing: content-box;
  border: 15px solid #ebedee !important;
  outline: 1px solid #aeaeaea6;
  outline-offset: -15px !important;
  text-transform: capitalize;
  z-index: 1;
  font-weight: normal !important;
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif !important;
  font-style: normal !important; }

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 1px !important; }

.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range, .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range {
  color: #fff !important; }

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
  background-color: #40846f !important; }

.react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
  background-color: #40846f !important; }

.react-datepicker__day--in-selecting-range {
  background-color: rgba(64, 132, 111, 0.5) !important; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #40846f !important; }

.react-datepicker__navigation--previous {
  left: 5px !important;
  top: 8px !important; }

.react-datepicker__navigation--next {
  right: 5px !important;
  top: 8px !important; }

.react-datepicker__triangle {
  margin-top: -25px !important;
  color: #ebedee !important; }

.header_table--size {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.react-datepicker__input-container {
  width: 150px !important;
  margin-top: 5% !important; }

.react-datepicker__close-icon {
  height: 95% !important;
  right: 2% !important;
  padding-top: 2px !important; }

.react-datepicker__close-icon::after {
  background-color: #000000 !important;
  width: 15px !important;
  text-align: center !important;
  content: "\D7";
  padding: 0px !important;
  padding-left: 1.2px !important;
  padding-top: 1.75px !important; }

.generic-table-container {
  position: relative;
  display: flex;
  margin: 2rem 1% 1%;
  margin-left: 0%; }

.generic-table {
  font-size: 13px;
  width: 100%; }
  .generic-table .loader {
    display: table;
    margin: 0 auto;
    margin-top: 40vh; }

.gl-checkbox__input[disabled] {
  cursor: not-allowed !important; }
  .gl-checkbox__input[disabled]:hover {
    border-color: initial; }

.gl-table__label {
  white-space: normal !important; }

.gl-table-flex-auto-aligned {
  flex-grow: 1;
  overflow-x: auto; }

.gl-table__loader--centered {
  text-align: center;
  padding: 1.5rem 0; }

.generic-table__checkbox {
  display: table;
  margin: 0 auto; }

.generic-table__input {
  margin-top: 15px;
  margin-bottom: 0;
  z-index: 1; }

.generic-table__column--error {
  background: rgba(255, 0, 0, 0.3); }

.generic-table__column--warning {
  background: rgba(255, 238, 0, 0.3); }

.generic-table__sort-icons--hoverable button:hover {
  fill: #a7c1b9;
  color: #a7c1b9; }

.generic-table__row--disabled {
  opacity: 0.5;
  background: #eee; }

.generic-table__row--sortable:hover {
  cursor: row-resize; }

.generic-table__row:hover,
.generic-table__row--selected {
  background-color: #d9dcdd;
  border: none;
  box-shadow: inset 0 -2px 0 #98989a; }

.gl-table td.generic-table__column--thumbnail {
  width: 120px;
  max-height: 90px;
  min-height: 80px;
  height: auto;
  text-align: center;
  padding: 5px;
  position: relative;
  display: table-cell; }

.generic-table__column {
  padding: 10px; }
  .generic-table__column--thumbnail img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    min-height: 100%;
    display: block; }
  .generic-table__column--duration, .generic-table__column--createddate, .generic-table__column--lastmodified, .generic-table__column--type, .generic-table__column--size, .generic-table__column--format, .generic-table__column--validfrom, .generic-table__column--validto, .generic-table__column--tags, .generic-table__column--activeon, .generic-table__column--uploaddate {
    text-align: center;
    text-transform: capitalize; }
  .generic-table__column--format {
    text-transform: uppercase; }
  .generic-table__column--tags .tags {
    list-style: none; }

.generic-table__th {
  display: flex; }
  .generic-table__th .gl-input,
  .generic-table__th .gl-input__field {
    min-height: 50px !important;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif !important;
    font-weight: normal !important;
    font-style: normal !important;
    font-size: 13px !important;
    letter-spacing: 2px !important;
    color: #000000 !important;
    opacity: 1 !important;
    padding: 2px 18px 0 18px !important; }
  .generic-table__th :not(.gl-input) > input[type='text'],
  .generic-table__th :not(.gl-input) > input[type='email'],
  .generic-table__th :not(.gl-input) > input[type='password'] {
    padding: 2px 18px 0px 18px !important; }

.generic-table__sort {
  display: flex;
  flex-direction: column;
  padding-right: 0.7rem; }
  .generic-table__sort--center {
    display: flex;
    align-items: center;
    margin: auto; }

.generic-table__sort-icons {
  display: flex;
  flex-direction: column;
  margin: auto; }
  .generic-table__sort-icons button {
    cursor: pointer; }

.generic-table__sort--active {
  fill: #a7c1b9;
  color: #a7c1b9; }

.generic-table__column--action {
  display: flex;
  flex-direction: column; }

.generic-table__button {
  padding: 10px 20px; }

.gl-input__field {
  min-width: 130px; }

.gl-input__field[value=''] + div {
  display: none; }

.table-pagination {
  margin-left: 1%; }

.generic-table__column--name {
  max-width: 400px; }

.checkbox__container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.checkbox__unselect-all {
  letter-spacing: 0;
  position: absolute;
  top: -25px;
  left: 0;
  text-align: left; }
  .checkbox__unselect-all:hover {
    cursor: pointer;
    color: #a7c1b9; }

.autocomplete-list {
  padding: 5px;
  text-align: left;
  text-transform: capitalize;
  font-weight: 500;
  border-bottom: 1px solid #d9dcdd;
  padding-left: 10px;
  padding-right: 10px; }

.column-options {
  flex-grow: 0;
  position: absolute;
  left: 8px;
  z-index: 2;
  top: 30px; }

.generic-table__label--size {
  padding: 0 !important; }

.genetic-table-container {
  position: relative;
  overflow: auto;
  height: 500px; }

.screenshot-icon .gl-icon {
  width: 0px;
  height: 0px;
  margin-left: 0px; }

.gl-table td {
  text-align: left !important; }

.display-flex {
  display: flex; }

.sub-column-header {
  position: relative;
  padding: 0 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000000;
  border-color: #d9dcdd;
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  font-size: 16px; }
