.custom-loader {
  display: flex;
  align-items: center;
  justify-content: center; }
  .custom-loader__text {
    font-size: 1.1rem;
    margin-left: 1rem; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.custom-modal__button-container {
  margin-top: 2rem; }

.custom-modal__button--primary {
  margin-right: 1rem; }

.custom-modal__item {
  height: 40px;
  word-break: break-all; }
  .custom-modal__item td {
    border-bottom: 1px solid #98989a;
    max-width: 100%; }

.custom-modal__item:last-child {
  padding-bottom: 1rem; }

.custom-modal__list {
  margin: 1rem 0;
  width: 100%; }

.marginTop {
  margin-top: 20px; }

.validation-error {
  color: #e63f3f; }

.validation-success {
  color: #00aa55; }

.basic-multi-select {
  min-width: 300px; }

.edit-table-2__column--grey {
  background: #f7f7f7; }

.edit-table-2__column--full-width {
  max-width: 100% !important; }

.edit-table-2 {
  display: flex;
  flex-wrap: wrap; }
  .edit-table-2__column {
    max-width: 50%;
    flex: 1 0 50%;
    flex-basis: 0;
    padding: 0 15px 20px;
    margin-right: 20px;
    margin-top: 20px;
    border: 1px solid #98989a; }

.validity-row {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 1px solid #98989a;
  padding: 15px 15px 5px;
  border-radius: 4px;
  position: relative;
  flex-wrap: wrap; }
  .validity-row__column {
    width: 33%;
    margin-left: 0.25%; }
    .validity-row__column--button {
      margin-bottom: 10px; }
  .validity-row__header {
    position: absolute;
    font-size: 14px;
    top: -11px;
    left: 12px;
    margin-top: 0;
    padding: 2px 5px 1px;
    background-color: #ffffff;
    color: #98989a; }
  .validity-row .gl-tooltip__panel {
    width: 290px; }
    .validity-row .gl-tooltip__panel .react-datepicker {
      border: 1px solid #aeaeae !important;
      border-radius: 0.3rem !important;
      display: inline-block !important;
      outline: 0px !important; }
    .validity-row .gl-tooltip__panel .react-datepicker__navigation--next {
      right: 10px !important;
      top: 8px !important; }
    .validity-row .gl-tooltip__panel .react-datepicker__navigation--previous {
      left: 10px !important;
      top: 8px !important; }
  .validity-row__applyAll {
    margin-top: 20px;
    width: 100%; }
  .validity-row__expandAll {
    display: flex;
    justify-content: flex-end; }
  .validity-row__adminExpandAll {
    display: flex;
    justify-content: space-between; }
    .validity-row__adminExpandAll .expandAll--button {
      margin-top: 1rem;
      white-space: nowrap;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }
    .validity-row__adminExpandAll .expand-collapse-all--buttons {
      display: flex; }

.file-upload__container {
  margin-bottom: 2%; }
  .file-upload__container .file-input {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer; }
    .file-upload__container .file-input:hover {
      background-color: black;
      color: white; }
    .file-upload__container .file-input input[type='file'] {
      display: none; }

.scrollableContent {
  max-height: 340px;
  margin: 0;
  box-sizing: border-box; }

.custom-modal--fullscreen .gl-modal__main {
  height: 580px !important;
  max-height: 100vh !important;
  overflow: hidden; }

.custom-modal--fullscreen .generic-container {
  margin: 0px; }

.custom-modal--fullscreen .selectMediaToPlaylists {
  height: 230px !important; }

.custom-modal--fullscreen .selectMediaToPlaylists-height {
  height: 180px !important; }

.custom-modal--fullscreen .selectsubPlaylistsToPlaylists {
  height: 230px !important; }

.custom-modal--fullscreen .selectsubPlaylistsToPlaylists-height {
  height: 180px !important; }

.custom-modal--fullscreen .selectPlaylistsToAddMedia {
  height: 265px !important; }

.custom-modal--fullscreen .selectPlaylistsToAddMedia-height {
  height: 220px !important; }

.custom-modal--fullscreen .logs__container__filters__item {
  padding: 0px 10px 0px 0px !important; }

.button-styling {
  position: fixed; }

.custom-modal--fullscreen .gl-menu .gl-toggle {
  margin: 0px !important; }

.custom-modal--fullscreen .gl-modal--large .gl-modal__main,
.custom-modal--fullscreen .gl-modal--large .gl-modal__dialog,
.custom-modal--fullscreen .gl-modal__dialog {
  max-height: 90vh;
  max-width: 90vw;
  width: 90vw !important;
  max-width: 90vw !important; }

.custom-modal--fullscreen {
  height: 92vh !important; }

.multi-column-list {
  -webkit-columns: 4;
     -moz-columns: 4;
          columns: 4;
  width: 100%;
  list-style-position: inside;
  overflow-y: auto; }

.multi-column-list-height {
  height: 64px; }

.custom-modal--fullscreen .gl-modal--large .gl-modal__main,
.gl-modal__main {
  overflow-x: hidden; }

.custom-modal--fullscreen .modal-header {
  display: flex;
  align-items: baseline; }

.custom-modal--fullscreen .modal-header .secondary-info {
  padding-left: 14px; }

.custom-modal .generic-table-container {
  margin: 0; }

.custom-modal .gl-callout {
  width: 100%;
  max-width: 100%; }

.custom-modal__thumb {
  width: 30px;
  float: left;
  margin-right: 15px;
  font-size: 10px;
  text-align: center; }

.preview-modal .gl-modal__main {
  position: relative;
  min-height: 90vh;
  height: 90vh;
  overflow: visible; }

.preview-modal .gl-modal__main-content,
.preview-modal .custom-modal {
  width: 100%;
  height: 100%; }

.preview-modal h4 {
  word-break: break-all;
  height: auto;
  display: flex;
  margin: 0;
  padding: 0;
  margin-bottom: 16px; }

.preview-modal img,
.preview-modal video {
  margin: auto;
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: relative; }

.preview-modal .modal-items__wrapper {
  display: flex;
  width: 100%;
  position: relative;
  height: 100%;
  max-height: 100%; }

.preview-modal .modal-items__wrapper-inner {
  display: flex;
  flex-direction: column;
  height: 81vh;
  max-height: 81vh;
  width: 100%; }

.preview-modal .modal-items__preview,
.preview-modal .no-preview-info {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  float: left;
  margin-left: 10px; }

.preview-modal .modal-items__preview-playlist {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  float: left;
  margin-left: 45px;
  width: 40%;
  object-fit: cover; }

.preview-modal .modal-items__description {
  height: auto;
  display: flex;
  flex-direction: column;
  float: right;
  width: 30%;
  height: 500px; }

.preview-modal .modal-items__description-playlist {
  height: auto;
  display: flex;
  flex-direction: column;
  float: right;
  margin-right: 45px;
  height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden; }

.preview-modal__wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 100%; }

.preview-modal .item-number {
  text-align: center;
  position: relative; }

.preview-modal .no-preview-info {
  width: 30%;
  margin-top: 200px;
  margin-left: 200px;
  justify-content: center; }

.preview-modal .no-preview-info p {
  text-align: center;
  vertical-align: middle;
  display: table-cell; }

.modal-items__table .generic-table__column {
  min-width: 240px;
  max-width: 240px;
  height: auto;
  word-break: break-all; }

.gl-input__field:disabled {
  background: #ebedee; }

.adding-items__loader .custom-loader {
  justify-content: left; }
  .adding-items__loader .custom-loader .gl-loader {
    width: 30px;
    height: 30px;
    border-width: 1px; }

.selected-items {
  margin-bottom: 2rem; }
  .selected-items li {
    list-style: disc;
    margin-left: 1em; }

.selected-items-list {
  margin-bottom: 0 !important; }

.custom-modal__name {
  word-break: break-all; }

.marginLeft2 {
  margin-left: 2rem; }

.marginRight {
  margin-right: 1rem; }

.marginLeft {
  margin-left: 1rem; }

.preview-items__tooltip {
  margin-top: 15px; }

.tags__container,
.workgroup__container {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 1px solid #98989a;
  padding: 15px;
  border-radius: 4px;
  position: relative;
  flex-wrap: wrap; }
  .tags__container--button,
  .workgroup__container--button {
    margin-top: 10px; }

.tags__header,
.workgroup__header {
  position: absolute;
  font-size: 14px;
  top: -11px;
  left: 12px;
  margin-top: 0;
  padding: 2px 5px 1px;
  background-color: #ffffff;
  color: #98989a; }

.tags__selectedtags,
.workgroup__selectedtags {
  margin-bottom: 20px; }

.tags__badges,
.workgroup__badges {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  box-sizing: border-box; }

.tags__selections,
.workgroup__selections {
  display: flex;
  width: 65%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 20px;
  border-right: 1px dashed #e6e6e6; }
  .tags__selections.tags__multiple,
  .workgroup__selections.tags__multiple {
    width: 100%;
    padding: 0;
    border: none; }
  .tags__selections .gl-label,
  .workgroup__selections .gl-label {
    width: 100%; }
  .tags__selections .tags__maincategory,
  .workgroup__selections .tags__maincategory {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px; }
  .tags__selections .tags__subcategory,
  .workgroup__selections .tags__subcategory {
    width: 100%; }
    .tags__selections .tags__subcategory .react-select__multi-value,
    .workgroup__selections .tags__subcategory .react-select__multi-value {
      display: none; }
    .tags__selections .tags__subcategory .react-select__clear-indicator,
    .workgroup__selections .tags__subcategory .react-select__clear-indicator {
      display: none; }

.tags__selectedtags,
.workgroup__selectedtags {
  padding-left: 20px;
  width: 35%; }
  .tags__selectedtags.tags__multiple,
  .workgroup__selectedtags.tags__multiple {
    width: 100%;
    border: none;
    padding: 20px 0 0; }

.desc__container textarea {
  border-radius: 4px; }

.desc__header {
  position: absolute;
  font-size: 14px;
  top: -11px;
  left: 12px;
  margin-top: 0;
  padding: 2px 5px 1px;
  background-color: #ffffff;
  color: #98989a; }

.messages-input .gl-form-item {
  width: 90%; }

.messages-input .gl-input {
  min-height: auto; }

.messages-title {
  font-weight: bold; }

.height-auto {
  height: auto; }

.display-flex {
  display: flex;
  width: 100%; }

.padding-right-5 {
  padding-right: 5px; }

.padding-left-5 {
  padding-left: 5px; }

.margin-bottom-15 {
  margin-bottom: 15px; }

.margin-top-15 {
  margin-top: 15px; }

.red-text {
  color: red; }

.uppercase {
  text-transform: uppercase; }

.font-size-14 {
  font-size: 14px; }

.capitalize {
  text-transform: capitalize; }

.screenshot-error-screen .gl-box--urgent {
  margin: auto; }

.screenshot-error-screen {
  text-align: center;
  margin: auto;
  width: 80%;
  height: 100%;
  display: flex; }

.screenshot-content {
  max-height: 450px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center; }

.screenshot-image {
  max-height: 400px; }

.screenshot-image-container {
  overflow: auto;
  height: 350px; }

.width-100 {
  width: 100%; }

.display-flex {
  display: flex; }

.margin-left-auto {
  margin-left: auto; }

.margin-top-15 {
  margin-top: 15px; }

.link-style {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  margin-left: auto; }

@media (min-width: 960px) {
  .gl-modal__dialog .gl-modal__main {
    max-height: 100vh; }
  .gl-modal__dialog {
    margin-right: 10px !important;
    margin-left: 10px !important; } }

.confirm-frame-edit-modal {
  z-index: 6 !important; }

.confirm-frame-edit-modal .gl-modal__main {
  padding: 20px !important; }

.preview-modals-table tr:nth-child(odd) {
  background-color: #dededed0; }

.preview-modals-table .generic-table__column {
  width: 20px;
  height: 2px;
  word-break: break-all; }

.frame-edit-modal .gl-modal__main {
  margin-right: 20px !important;
  margin-left: 20px !important; }

.frame-edit-modal .gl-modal__close {
  margin-right: 20px !important; }

.fixed-buttons {
  position: fixed;
  margin-top: 0px; }

.fixed-buttons-height {
  margin-top: 0px; }

.fixed-buttons-media {
  position: fixed;
  margin-top: 55px; }

.fixed-buttons-media-height {
  margin-top: 0px; }

.select-items-header {
  margin-bottom: 10px; }

.selected-media {
  margin-top: 2%;
  width: 50%;
  float: left; }

.selected-sub-playlists {
  margin-top: 2%;
  width: 50%;
  float: left; }

.addition-summary {
  margin-top: 10%;
  clear: both; }

.addition-summary-table {
  max-height: 200px !important;
  height: 349px !important; }

.truncate-items-list {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold; }

.marginLeftValidityDates {
  margin-left: -3.9% !important; }

.validity-row-input .gl-checkbox__text {
  margin-left: 3%; }

.leftAlignEditModal {
  margin-left: -15px !important; }

.marginLeftTags {
  margin-left: -1% !important; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.custom-pagination {
  display: flex;
  margin-top: 0;
  margin-bottom: 0; }

.current-page {
  font-size: 1.5rem;
  vertical-align: middle; }

.custom-pagination__item {
  color: #000000;
  border-bottom: 3px solid white; }

.custom-pagination__item--active .custom-pagination__link,
.custom-pagination__item:hover {
  border-bottom: 3px solid #000000; }

.custom-pagination__link {
  padding: 0.65rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #000000 !important;
  color: #000000;
  font-weight: 900;
  font-size: 1rem; }

.custom-pagination__link:hover {
  cursor: pointer; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.react-datepicker {
  box-sizing: content-box;
  border: 15px solid #ebedee !important;
  outline: 1px solid #aeaeaea6;
  outline-offset: -15px !important;
  text-transform: capitalize;
  z-index: 1;
  font-weight: normal !important;
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif !important;
  font-style: normal !important; }

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 1px !important; }

.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range, .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range {
  color: #fff !important; }

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
  background-color: #40846f !important; }

.react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
  background-color: #40846f !important; }

.react-datepicker__day--in-selecting-range {
  background-color: rgba(64, 132, 111, 0.5) !important; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #40846f !important; }

.react-datepicker__navigation--previous {
  left: 5px !important;
  top: 8px !important; }

.react-datepicker__navigation--next {
  right: 5px !important;
  top: 8px !important; }

.react-datepicker__triangle {
  margin-top: -25px !important;
  color: #ebedee !important; }

.header_table--size {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.react-datepicker__input-container {
  width: 150px !important;
  margin-top: 5% !important; }

.react-datepicker__close-icon {
  height: 95% !important;
  right: 2% !important;
  padding-top: 2px !important; }

.react-datepicker__close-icon::after {
  background-color: #000000 !important;
  width: 15px !important;
  text-align: center !important;
  content: "\D7";
  padding: 0px !important;
  padding-left: 1.2px !important;
  padding-top: 1.75px !important; }

.generic-table-container {
  position: relative;
  display: flex;
  margin: 2rem 1% 1%;
  margin-left: 0%; }

.generic-table {
  font-size: 13px;
  width: 100%; }
  .generic-table .loader {
    display: table;
    margin: 0 auto;
    margin-top: 40vh; }

.gl-checkbox__input[disabled] {
  cursor: not-allowed !important; }
  .gl-checkbox__input[disabled]:hover {
    border-color: currentColor;
    border-color: initial; }

.gl-table__label {
  white-space: normal !important; }

.gl-table-flex-auto-aligned {
  flex-grow: 1;
  overflow-x: auto; }

.gl-table__loader--centered {
  text-align: center;
  padding: 1.5rem 0; }

.generic-table__checkbox {
  display: table;
  margin: 0 auto; }

.generic-table__input {
  margin-top: 15px;
  margin-bottom: 0;
  z-index: 1; }

.generic-table__column--error {
  background: rgba(255, 0, 0, 0.3); }

.generic-table__column--warning {
  background: rgba(255, 238, 0, 0.3); }

.generic-table__sort-icons--hoverable button:hover {
  fill: #a7c1b9;
  color: #a7c1b9; }

.generic-table__row--disabled {
  opacity: 0.5;
  background: #eee; }

.generic-table__row--sortable:hover {
  cursor: row-resize; }

.generic-table__row:hover,
.generic-table__row--selected {
  background-color: #d9dcdd;
  border: none;
  box-shadow: inset 0 -2px 0 #98989a; }

.gl-table td.generic-table__column--thumbnail {
  width: 120px;
  max-height: 90px;
  min-height: 80px;
  height: auto;
  text-align: center;
  padding: 5px;
  position: relative;
  display: table-cell; }

.generic-table__column {
  padding: 10px; }
  .generic-table__column--thumbnail img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    min-height: 100%;
    display: block; }
  .generic-table__column--duration, .generic-table__column--createddate, .generic-table__column--lastmodified, .generic-table__column--type, .generic-table__column--size, .generic-table__column--format, .generic-table__column--validfrom, .generic-table__column--validto, .generic-table__column--tags, .generic-table__column--activeon, .generic-table__column--uploaddate {
    text-align: center;
    text-transform: capitalize; }
  .generic-table__column--format {
    text-transform: uppercase; }
  .generic-table__column--tags .tags {
    list-style: none; }

.generic-table__th {
  display: flex; }
  .generic-table__th .gl-input,
  .generic-table__th .gl-input__field {
    min-height: 50px !important;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif !important;
    font-weight: normal !important;
    font-style: normal !important;
    font-size: 13px !important;
    letter-spacing: 2px !important;
    color: #000000 !important;
    opacity: 1 !important;
    padding: 2px 18px 0 18px !important; }
  .generic-table__th :not(.gl-input) > input[type='text'],
  .generic-table__th :not(.gl-input) > input[type='email'],
  .generic-table__th :not(.gl-input) > input[type='password'] {
    padding: 2px 18px 0px 18px !important; }

.generic-table__sort {
  display: flex;
  flex-direction: column;
  padding-right: 0.7rem; }
  .generic-table__sort--center {
    display: flex;
    align-items: center;
    margin: auto; }

.generic-table__sort-icons {
  display: flex;
  flex-direction: column;
  margin: auto; }
  .generic-table__sort-icons button {
    cursor: pointer; }

.generic-table__sort--active {
  fill: #a7c1b9;
  color: #a7c1b9; }

.generic-table__column--action {
  display: flex;
  flex-direction: column; }

.generic-table__button {
  padding: 10px 20px; }

.gl-input__field {
  min-width: 130px; }

.gl-input__field[value=''] + div {
  display: none; }

.table-pagination {
  margin-left: 1%; }

.generic-table__column--name {
  max-width: 400px; }

.checkbox__container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.checkbox__unselect-all {
  letter-spacing: 0;
  position: absolute;
  top: -25px;
  left: 0;
  text-align: left; }
  .checkbox__unselect-all:hover {
    cursor: pointer;
    color: #a7c1b9; }

.autocomplete-list {
  padding: 5px;
  text-align: left;
  text-transform: capitalize;
  font-weight: 500;
  border-bottom: 1px solid #d9dcdd;
  padding-left: 10px;
  padding-right: 10px; }

.column-options {
  flex-grow: 0;
  position: absolute;
  left: 8px;
  z-index: 2;
  top: 30px; }

.generic-table__label--size {
  padding: 0 !important; }

.genetic-table-container {
  position: relative;
  overflow: auto;
  height: 500px; }

.screenshot-icon .gl-icon {
  width: 0px;
  height: 0px;
  margin-left: 0px; }

.gl-table td {
  text-align: left !important; }

.display-flex {
  display: flex; }

.sub-column-header {
  position: relative;
  padding: 0 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000000;
  border-color: #d9dcdd;
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  font-size: 16px; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.playlist-row__playlist-type {
  font-size: 0.6rem; }

a {
  color: #0071ae !important; }

div.gl-form-item > div > label {
  font-size: 14px; }

.step-buttons--hide-arrow .arrow-right-long,
.step-buttons--hide-arrow .gl-icon {
  display: none; }

.step-buttons__icon {
  height: 20px;
  width: 20px;
  margin-left: 5px; }

.cd-breadcrumb,
.cd-multi-steps {
  padding: 0.5em 1em;
  background-color: #edeff0;
  border-radius: 0.25em; }

.cd-breadcrumb::after,
.cd-multi-steps::after {
  clear: both;
  content: '';
  display: table; }

.cd-breadcrumb li,
.cd-multi-steps li {
  display: inline-block;
  float: left;
  margin: 0.5em 0; }

.cd-breadcrumb li::after,
.cd-multi-steps li::after {
  /* this is the separator between items */
  display: inline-block;
  content: '\BB';
  margin: 0 0.6em;
  color: #959fa5; }

.cd-breadcrumb li:last-of-type::after,
.cd-multi-steps li:last-of-type::after {
  /* hide separator after the last item */
  display: none; }

.cd-breadcrumb li > *,
.cd-multi-steps li > * {
  /* single step */
  display: inline-block;
  font-size: 1.4rem;
  color: #2c3f4c; }

.cd-breadcrumb li.current > *,
.cd-multi-steps li.current > * {
  /* selected step */
  color: #96c03d; }

.no-touch .cd-breadcrumb a:hover,
.no-touch .cd-multi-steps a:hover {
  /* steps already visited */
  color: #96c03d; }

@media only screen and (min-width: 768px) {
  .cd-breadcrumb,
  .cd-multi-steps {
    padding: 0 1.2em; }
  .cd-breadcrumb li,
  .cd-multi-steps li {
    margin: 1.2em 0; }
  .cd-breadcrumb li::after,
  .cd-multi-steps li::after {
    margin: 0 1em; }
  .cd-breadcrumb li > *,
  .cd-multi-steps li > * {
    font-size: 1rem; } }

@media only screen and (min-width: 768px) {
  .cd-breadcrumb.triangle {
    /* reset basic style */
    background-color: transparent;
    padding: 0; }
  .cd-breadcrumb.triangle li {
    position: relative;
    padding: 0;
    margin: 4px 4px 4px 0; }
  .cd-breadcrumb.triangle li:last-of-type {
    margin-right: 0; }
  .cd-breadcrumb.triangle li > * {
    position: relative;
    padding: 0.7em 0.8em 0.9em 2.5em;
    color: #2c3f4c;
    background-color: #dff4ef;
    /* the border color is used to style its ::after pseudo-element */
    border-color: #dff4ef; }
  .cd-breadcrumb.triangle li.current > * {
    /* selected step */
    color: #ffffff;
    background-color: #67c5aa;
    border-color: #67c5aa; }
  .cd-breadcrumb.triangle li:first-of-type > * {
    padding-left: 1.6em;
    border-radius: 0.25em 0 0 0.25em; }
  .cd-breadcrumb.triangle li:last-of-type > * {
    padding-right: 1.6em;
    border-radius: 0 0.25em 0.25em 0; }
  .no-touch .cd-breadcrumb.triangle a:hover {
    /* steps already visited */
    color: #ffffff;
    background-color: #2c3f4c;
    border-color: #2c3f4c; }
  .cd-breadcrumb.triangle li::after,
  .cd-breadcrumb.triangle li > *::after {
    /* 
    	li > *::after is the colored triangle after each item
    	li::after is the white separator between two items
    */
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 0;
    width: 0;
    /* 48px is the height of the <a> element */
    border: 24px solid transparent;
    border-right-width: 0;
    border-left-width: 20px; }
  .cd-breadcrumb.triangle li::after {
    /* this is the white separator between two items */
    z-index: 1;
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
    border-left-color: #ffffff;
    /* reset style */
    margin: 0; }
  .cd-breadcrumb.triangle li > *::after {
    /* this is the colored triangle after each element */
    z-index: 2;
    border-left-color: inherit; }
  .cd-breadcrumb.triangle li:last-of-type::after,
  .cd-breadcrumb.triangle li:last-of-type > *::after {
    /* hide the triangle after the last step */
    display: none; }
  .cd-breadcrumb.triangle.custom-separator li::after {
    /* reset style */
    background-image: none; }
  .cd-breadcrumb.triangle.custom-icons li::after,
  .cd-breadcrumb.triangle.custom-icons li > *::after {
    /* 50px is the height of the <a> element */
    border-top-width: 25px;
    border-bottom-width: 25px; }
  @-moz-document url-prefix() {
    .cd-breadcrumb.triangle li::after,
    .cd-breadcrumb.triangle li > *::after {
      /* fix a bug on Firefix - tooth edge on css triangle */
      border-left-style: dashed; } } }

.step-buttons {
  width: 50%; }

.step-buttons-list {
  display: flex;
  justify-content: flex-end; }
  .step-buttons-list__item {
    margin-left: 10px; }

.cd-breadcrumb.triangle .current a {
  color: #ffffff !important; }

.cd-breadcrumb.triangle a {
  color: #3a3e4f !important; }

.dashboard-actions {
  margin: 20px;
  display: flex; }

.breadcrumb-button {
  cursor: pointer; }

.breadcrumb-button--disabled {
  cursor: not-allowed; }

.button--viewscreenshot {
  cursor: pointer !important;
  height: auto !important;
  width: 5 !important;
  padding: 0px !important;
  margin-left: 16px !important;
  color: rgba(0, 0, 0, 0.9) !important;
  border: 0 !important;
  min-height: 0px !important;
  background-color: #ffffff00 !important; }

.button--viewscreenshot::after {
  box-shadow: none !important; }

.screenshot-icon {
  margin-top: 5px;
  justify-content: flex-end; }

.screenshot-icon-styling {
  height: 22px !important; }

.display-flex {
  display: flex; }

.frame-icon {
  margin-top: auto;
  margin-left: auto;
  justify-content: flex-end;
  align-items: flex-end; }

.frame-icon .gl-cta--primary::after {
  box-shadow: none !important; }

.frame-icon-styling {
  display: inline-block;
  height: 20px !important; }

.button--viewframeDetails {
  color: rgba(174, 174, 174, 0.9) !important;
  font-size: 14px;
  margin: 4px 0px 0px 15px !important;
  padding: 0px 6px 0px 6px !important;
  cursor: pointer;
  height: 18px !important;
  width: auto !important;
  border: 1px solid rgba(174, 174, 174, 0.9) !important;
  min-height: px !important;
  background-color: #ffffff00 !important; }

.default-cursor {
  cursor: default; }

.schedulename-icon {
  justify-content: flex-start; }

.justify-content-end {
  justify-content: flex-end !important; }

.p-2 {
  padding: 0.5rem !important;
  box-shadow: none !important; }

.d-flex {
  display: flex !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.p-2-name {
  margin-top: 0.5rem; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.table-option__container {
  left: -6px;
  position: absolute; }
  .table-option__container .gl-link:not(.gl-cta--disabled):focus:not(:active),
  .table-option__container .gl-link:not(.gl-cta--disabled):hover:not(:active) .gl-tooltip__button {
    background: none !important; }
  .table-option__container .gl-tooltip__button {
    width: 10px; }
    .table-option__container .gl-tooltip__button:hover, .table-option__container .gl-tooltip__button:active {
      background: none !important; }
      .table-option__container .gl-tooltip__button:hover .header-user__ellipsis, .table-option__container .gl-tooltip__button:active .header-user__ellipsis {
        fill: #a7c1b9; }
  .table-option__container .header-user__ellipsis {
    fill: black; }
  .table-option__container .table-options__row {
    display: flex;
    margin-bottom: 7px; }
    .table-option__container .table-options__row.disabled {
      opacity: 0.3;
      cursor: not-allowed; }
    .table-option__container .table-options__row--zero-column, .table-option__container .table-options__row--first-column, .table-option__container .table-options__row--second-column, .table-option__container .table-options__row--third-column {
      display: flex;
      justify-content: center;
      align-items: center; }
    .table-option__container .table-options__row--zero-column {
      flex: 0 0 8px;
      margin-right: -2%;
      -webkit-margin-before: 0.6ch;
              margin-block-start: 0.6ch; }
    .table-option__container .table-options__row--first-column {
      flex: 0 0 38px;
      font-family: monospace; }
    .table-option__container .table-options__row--second-column {
      flex: 0 0 40px; }
    .table-option__container .table-options__row--third-column {
      flex: 0 0 auto; }

.while-sorting {
  z-index: 9999;
  display: flex;
  opacity: 0.5; }
  .while-sorting .table-options__row--zero-column,
  .while-sorting .table-options__row--first-column,
  .while-sorting .table-options__row--second-column,
  .while-sorting .table-options__row--third-column {
    display: flex;
    justify-content: center;
    align-items: center; }
  .while-sorting .table-options__row--zero-column {
    flex: 0 0 18px; }
  .while-sorting .table-options__row--first-column {
    flex: 0 0 38px; }
  .while-sorting .table-options__row--second-column {
    flex: 0 0 40px; }
  .while-sorting .table-options__row--third-column {
    flex: 0 0 auto; }

.badge {
  background-color: #e6e6e6;
  border-radius: 2px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  position: relative; }
  .badge--outline {
    background-color: #ffffff;
    border: 1px solid #000000;
    margin: 0 5px 5px 0;
    padding: 0;
    border-radius: 0;
    cursor: pointer; }
    .badge--outline .badge__label {
      padding-right: 10px; }
    .badge--outline .badge__remove {
      min-height: 25px;
      border-radius: 0;
      border-left: 1px solid #000; }
    .badge--outline.selected .badge__label {
      font-weight: 600; }
    .badge--outline.selected:after {
      display: block; }
    .badge--outline:after {
      bottom: 0px;
      content: '';
      display: none;
      height: 1px;
      left: -1px;
      position: absolute;
      width: calc(100% + 2px);
      background-color: #000; }
    .badge--outline:hover {
      -webkit-transition: all 0.1s cubic-bezier(0.3, 0, 0.45, 1);
      transition: all 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
      .badge--outline:hover:after {
        display: block; }
  .badge__label {
    border-radius: 2px;
    color: #333333;
    font-size: 85%;
    overflow: hidden;
    padding: 3px;
    padding-left: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box; }
  .badge__remove {
    align-items: center;
    border-radius: 2px;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    min-height: 24px; }
    .badge__remove:hover {
      background-color: #ffbdad;
      cursor: pointer; }
      .badge__remove:hover svg {
        fill: #de350b; }

.checkboxTreeContainer__selection-text {
  margin: 10px 0 5px; }

.checkboxTreeContainer .selection-now-allowed {
  opacity: 0.35;
  pointer-events: none !important;
  cursor: not-allowed; }

.checkboxTreeContainer .customCheckboxNode--children {
  margin-left: 1rem; }

.checkboxTreeContainer .customCheckboxNode .customCheckboxItem {
  display: flex;
  margin: 5px 0; }
  .checkboxTreeContainer .customCheckboxNode .customCheckboxItem--container {
    display: flex; }
    .checkboxTreeContainer .customCheckboxNode .customCheckboxItem--container:hover {
      background: rgba(51, 51, 204, 0.15);
      cursor: pointer; }
  .checkboxTreeContainer .customCheckboxNode .customCheckboxItem--colapse, .checkboxTreeContainer .customCheckboxNode .customCheckboxItem--checkbox {
    flex: 0 0 20px;
    height: 20px; }
    .checkboxTreeContainer .customCheckboxNode .customCheckboxItem--colapse svg, .checkboxTreeContainer .customCheckboxNode .customCheckboxItem--checkbox svg {
      height: 20px;
      width: 20px; }
  .checkboxTreeContainer .customCheckboxNode .customCheckboxItem--colapse__clickable:hover {
    cursor: pointer; }
  .checkboxTreeContainer .customCheckboxNode .customCheckboxItem--label {
    margin-left: 5px; }
    .checkboxTreeContainer .customCheckboxNode .customCheckboxItem--label__counter {
      opacity: 0.6;
      margin-left: 5px; }

.react-select__input input {
  height: auto !important; }
  .react-select__input input:focus {
    box-shadow: none; }

.react-select__menu .react-select__group-heading {
  font-size: 18px;
  background-color: #e6e6e6;
  padding: 5px 10px; }

.withTooltip {
  position: relative;
  display: inline-block;
  width: auto; }

.withTooltip[type='button'] {
  cursor: pointer;
  height: 20px; }

.withTooltip svg {
  width: 20px; }

.withTooltip:before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
  z-index: 99;
  opacity: 0; }

/* Tooltip text */
.withTooltip:after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  -webkit-transform: translateX(-50%) translateY(-100%);
          transform: translateX(-50%) translateY(-100%);
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
  color: #fff;
  font-size: 14px;
  min-width: 80px;
  border-radius: 5px;
  pointer-events: none;
  padding: 4px 4px;
  z-index: 99;
  opacity: 0;
  white-space: normal;
  word-wrap: break-word; }

/* Show the tooltip text when you mouse over the tooltip container */
.withTooltip:hover::before,
.withTooltip:hover::after {
  opacity: 1; }

.action-button {
  padding: 0 5px; }

.slider > .slider-frame > .slider-list {
  width: auto !important; }

.carousel-slider {
  height: auto;
  min-width: 80vw;
  width: 100%; }
  .carousel-slider .slider {
    width: 100%;
    text-align: center;
    position: relative; }
    .carousel-slider .slider .slider-control-bottomcenter,
    .carousel-slider .slider .slider-control-centerright,
    .carousel-slider .slider .slider-control-centerleft {
      display: none; }
    .carousel-slider .slider .slider-slide {
      text-align: left; }
      .carousel-slider .slider .slider-slide img,
      .carousel-slider .slider .slider-slide video {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%; }
  .carousel-slider button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto; }
  .carousel-slider button.button--left {
    left: 5px; }
  .carousel-slider button.button--right {
    right: 5px; }

.carousel-counter {
  text-align: center;
  color: grey;
  font-size: smaller;
  padding-bottom: 10px; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.drag-and-drop__overlay__media {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: -webkit-linear-gradient(45deg, #a7c1b9 16.67%, #ffffff 16.67%, #ffffff 50%, #a7c1b9 50%, #a7c1b9 66.67%, #ffffff 66.67%, #ffffff 100%);
  background-image: linear-gradient(45deg, #a7c1b9 16.67%, #ffffff 16.67%, #ffffff 50%, #a7c1b9 50%, #a7c1b9 66.67%, #ffffff 66.67%, #ffffff 100%);
  background-size: 4.24px 4.24px;
  opacity: 0.7;
  text-align: center;
  color: #000000;
  z-index: 999;
  font-size: 56px;
  margin-top: -1%; }

.media-tags {
  font-size: inherit;
  line-height: inherit;
  list-style: none; }

.generic-table__column--showtags ul li {
  display: block !important; }

.drag-and-drop__overlay--message__media {
  margin-top: 20%;
  text-transform: uppercase; }

.generic-table__label--store .generic-table__input,
.generic-table__label--status .generic-table__input {
  min-width: 160px !important; }

.generic-table__label--store .gl-input__label--hoisted,
.generic-table__label--status .gl-input__label--hoisted {
  top: -20px !important; }

.player__description {
  display: block;
  margin-top: 2px;
  margin-bottom: 0; }

.player__name {
  margin-top: auto;
  margin-bottom: auto; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.date-hidden .gl-tooltip__button {
  cursor: not-allowed;
  pointer-events: none;
  color: #c0c0c0;
  background-color: #ffffff;
  visibility: hidden !important; }

.gl-tooltip--disabled {
  opacity: 0.6; }

.player-info {
  background: #d9dcdd;
  width: 100%;
  border: 1px solid #d9dcdd;
  border-bottom: none; }
  .player-info__column {
    padding: 15px; }
  .player-info__checkbox-column {
    max-width: 10px; }

.playlist-info {
  margin-bottom: 30px;
  width: 100%;
  border-collapse: collapse; }
  .playlist-info--disabled {
    opacity: 0.6; }
  .playlist-info .playlist-info__header {
    background-color: #ebedee;
    border-color: #d9dcdd;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #000000;
    text-align: center;
    font-size: 13px; }
    .playlist-info .playlist-info__header--left {
      text-align: left; }
  .playlist-info__button--disabled {
    opacity: 0.6; }
  .playlist-info__column {
    padding: 15px;
    border: 1px solid #ebedee;
    text-align: center; }
    .playlist-info__column .date-hidden {
      cursor: not-allowed;
      pointer-events: none;
      color: #c0c0c0;
      background-color: #ffffff;
      visibility: hidden !important; }
    .playlist-info__column .react-datepicker__time-container {
      width: 95px; }
      .playlist-info__column .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width: 93px; }
    .playlist-info__column .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 100px; }
    .playlist-info__column--left {
      text-align: left; }
    .playlist-info__column .gl-tooltip__panel--large {
      width: 530px; }
      .playlist-info__column .gl-tooltip__panel--large .react-datepicker {
        padding: 0; }
      .playlist-info__column .gl-tooltip__panel--large .react-datepicker__time-container--with-today-button {
        right: -100px; }
  .playlist-info__checkbox-column {
    max-width: 10px; }
  .playlist-info__column--center {
    display: flex;
    justify-content: left;
    align-items: center; }
    .playlist-info__column--center .gl-tooltip__button {
      margin: 0; }
  .playlist-info__select-day {
    padding: 5px 10px;
    border: 1px solid #000000;
    background: #ffffff;
    margin: 0 10px 10px; }
    .playlist-info__select-day--selected {
      color: #ffffff;
      background: #000000; }
  .playlist-info--archived {
    opacity: 0.8; }
  .playlist-info .react-datepicker__day-name,
  .playlist-info .react-datepicker__day,
  .playlist-info .react-datepicker__time-name {
    width: 48px !important; }
  .playlist-info .react-datepicker--time-only {
    margin-right: 30px; }

.playlist-info__column-checkbox {
  margin-left: 20px; }

.player-info__column--status {
  text-align: right; }
  .player-info__column--status .gl-cta {
    margin-left: 20px; }

.schedule-playlist-info {
  margin-bottom: 30px;
  width: 100%;
  border-collapse: collapse; }
  .schedule-playlist-info--disabled {
    opacity: 0.6; }
  .schedule-playlist-info .playlist-info__header {
    background-color: #ebedee;
    border-color: #d9dcdd;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #000000;
    text-align: center;
    font-size: 13px; }
    .schedule-playlist-info .playlist-info__header--left {
      text-align: left; }
  .schedule-playlist-info__button--disabled {
    opacity: 0.6; }
  .schedule-playlist-info__column .date-hidden {
    cursor: not-allowed;
    pointer-events: none;
    color: #c0c0c0;
    background-color: #ffffff;
    visibility: hidden !important; }
  .schedule-playlist-info__column .react-datepicker__time-container {
    width: 95px; }
    .schedule-playlist-info__column .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 93px; }
  .schedule-playlist-info__column .react-datepicker__day {
    width: 48px !important; }
  .schedule-playlist-info__column .react-datepicker {
    border: 1px solid #aeaeae !important;
    border-radius: 0.3rem !important;
    display: inline-block !important;
    outline: 0px !important; }
  .schedule-playlist-info__column .react-datepicker__navigation--next {
    right: 10px !important;
    top: 8px !important; }
  .schedule-playlist-info__column .react-datepicker__navigation--previous {
    left: 10px !important;
    top: 8px !important; }
  .schedule-playlist-info__column .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 100px; }
  .schedule-playlist-info__column--left {
    text-align: left; }
  .schedule-playlist-info__column .gl-tooltip__panel--large {
    width: 530px; }
    .schedule-playlist-info__column .gl-tooltip__panel--large .react-datepicker__time-container--with-today-button {
      right: -100px; }
  .schedule-playlist-info__checkbox-column {
    max-width: 10px; }
  .schedule-playlist-info__column--center {
    display: flex;
    justify-content: left;
    align-items: center; }
    .schedule-playlist-info__column--center .gl-tooltip__button {
      margin: 0; }
  .schedule-playlist-info__select-day {
    padding: 5px 10px;
    border: 1px solid #000000;
    background: #ffffff;
    margin: 0 10px 10px; }
    .schedule-playlist-info__select-day--selected {
      color: #ffffff;
      background: #000000; }
  .schedule-playlist-info--archived {
    opacity: 0.8; }
  .schedule-playlist-info .react-datepicker__day-name,
  .schedule-playlist-info .react-datepicker__day,
  .schedule-playlist-info .react-datepicker__time-name {
    width: 48px !important; }
  .schedule-playlist-info .react-datepicker--time-only {
    margin-right: 30px; }

.player-container {
  position: relative;
  margin-bottom: 15px; }
  .player-container .gl-callout {
    width: 100%;
    max-width: 100%;
    text-align: center; }

.scheduling-needs-attention {
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  background: rgba(255, 194, 194, 0.9);
  z-index: 100;
  display: flex;
  text-align: center;
  padding: 20px 0;
  margin: 0 20px;
  color: #000; }
  .scheduling-needs-attention .gl-checkbox,
  .scheduling-needs-attention .gl-form-item {
    display: inline-block;
    opacity: 1; }
  .scheduling-needs-attention div {
    margin: auto;
    flex: 1 1; }

.react-select__placeholder {
  white-space: nowrap !important; }

.react-select__value-container {
  min-width: 70px; }

.react-select__value-container {
  width: 70px; }

.react-select__option {
  text-align: left !important; }

.player-description {
  margin-top: 10px;
  font-size: 18px;
  font-weight: normal; }

.player-sections-header {
  font-size: 22px;
  margin-bottom: 20px !important; }

.player-sections-header-inactive {
  margin-top: 30px !important; }

.player-status__column {
  font-size: 16px;
  padding: 0px !important; }

.padding-right {
  padding-right: 10px !important; }

.gl-toggle .gl-menu__item {
  border: none !important;
  border-bottom: 1px solid #d9dcdd !important; }

.margin-top-5 {
  margin-top: 5px; }

.select-frame {
  margin-top: 15px;
  font-weight: normal;
  font-style: inherit;
  margin-right: 15px;
  text-transform: uppercase;
  color: gr;
  font-size: 13px;
  letter-spacing: 2px; }

.status-dot {
  height: 21px;
  width: 21px;
  margin-right: 12px;
  border-radius: 50%;
  display: inline-block; }

.status-active {
  background-color: #00aa55; }

.status-inactive {
  background-color: #e32b2b; }

.status-unknown {
  background-color: #767677; }

.status-unhealthy {
  background-color: #ede734; }

.margin-auto-l-r {
  margin-left: auto;
  margin-right: auto; }

.margin-top-40 {
  margin-top: 50px !important; }

.gl-toggle {
  margin: 0 0 15px 0 !important; }

.gl-form-item {
  margin: auto !important; }

.checkbox-styling-no-center .gl-form-item {
  margin: 0px !important;
  padding-right: 10px; }

.checkbox-styling-no-center {
  font-size: 14px;
  display: inline-flex; }

.gl-checkbox__input {
  margin: 0px !important; }

.deactivate-section .gl-form-item {
  padding-bottom: 10px; }

.deactivate-section .gl-checkbox__text {
  padding-left: 10px !important; }

.scheduling-table-step3, .schedule-details-table .genetic-table-container {
  height: auto !important;
  max-height: none !important;
  margin-bottom: 5px;
  overflow: inherit !important; }

.scheduling-table-step3 {
  overflow: none !important; }

.deactivate-button {
  margin: auto 30px auto 10px !important; }

.scheduleDetails .generic-table__label--action, .scheduleDetails2 .generic-table__label--action {
  width: 10% !important; }

.scheduleDetails .generic-table__label--playlistname, .scheduleDetails2 .generic-table__label--playlistname {
  width: 30% !important; }

.scheduleDetails .generic-table__label--startdate, .scheduleDetails2 .generic-table__label--startdate,
.scheduleDetails .generic-table__label--enddate, .scheduleDetails2 .generic-table__label--enddate,
.scheduleDetails .generic-table__label--playforever, .scheduleDetails2 .generic-table__label--playforever,
.scheduleDetails .generic-table__label--takeover, .scheduleDetails2 .generic-table__label--takeover {
  width: 15% !important; }

.schedule-playlist-info__column .gl-tooltip {
  margin-left: 0px !important; }

.scheduleDetails .generic-table__column--playlistname, .scheduleDetails2 .generic-table__column--playlistname,
.scheduleDetails .generic-table__column--startdate, .scheduleDetails2 .generic-table__column--startdate,
.scheduleDetails .generic-table__column--enddate, .scheduleDetails2 .generic-table__column--enddate,
.scheduleDetails .generic-table__column--playforever, .scheduleDetails2 .generic-table__column--playforever,
.scheduleDetails .generic-table__column--takeover, .scheduleDetails2 .generic-table__column--takeover {
  padding-left: 15px; }

.frame-names-toggle .gl-toggle {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important; }

.tooltip-apply-button {
  display: flex !important; }

.apply-button {
  margin-right: 5px !important; }

.validation-success {
  color: #00aa55; }

.player-name-styling {
  margin: 0px !important;
  padding: 10px 0px !important;
  color: black;
  font-size: 20px; }

.temporary-save-button {
  height: 50px;
  margin-right: 18px; }

.schedule-warning .gl-checkbox__input {
  margin: 0 14px 0 0 !important; }

.margin-right-15 {
  margin-right: 15px !important; }

.add-edit-table .genetic-table-container {
  height: auto !important;
  max-height: none !important;
  margin-bottom: 5px;
  overflow-y: hidden;
  display: table !important; }

.frame-edit-modal .gl-modal__dialog {
  width: 100% !important;
  max-width: none !important; }

.frames-edit-icons {
  width: 22px !important;
  height: 22px !important;
  fill: black !important; }

.frames-add-edit td:last-child {
  height: 125px !important;
  text-align: center !important;
  vertical-align: middle;
  display: table-cell; }

.clickable {
  cursor: pointer; }

.float-right {
  float: right; }

.frame-edit-modal .gl-modal__main {
  overflow: hidden !important; }

.scrollable-content {
  overflow-y: auto;
  height: 371px;
  padding-right: 15px;
  margin-bottom: 15px; }

.collapse-icon {
  justify-content: right !important;
  height: 20px !important;
  width: 30px !important;
  min-height: 20px !important;
  border: none !important;
  margin-top: 13px; }

.collapse-all-icon {
  margin-top: 0px !important; }

.margin-10 {
  margin: 10px !important; }

.right-text-button {
  margin-left: auto; }

.right-text-button:hover {
  opacity: 0.7; }

.add-new-frame:hover {
  opacity: 0.7; }

.edit-frame-table {
  border-color: #ebedee;
  margin-right: 0px;
  display: inline-table;
  padding: 0 10px 0px 0px; }

.apply-all-table .genetic-table-container {
  height: auto !important;
  max-height: none !important;
  display: table !important; }

.apply-all-table {
  padding: 5px 0px 0px 10px; }

.apply-all-table .gl-input {
  min-height: 0px !important; }

.apply-all-table .gl-table th {
  height: 45px !important;
  padding: 0px 10px; }

.apply-all-table .gl-table td {
  height: 45px !important;
  padding: 0px 10px; }

.apply-all-table .gl-input__field {
  min-height: 45px !important; }

.apply-all-table .gl-dropdown__select {
  height: 45px !important;
  letter-spacing: 1px !important;
  margin-top: 5px !important; }

.add-edit-table .gl-input {
  min-height: 0px !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.add-edit-table .frame-input-margin-bottom {
  margin-bottom: 0px !important;
  width: 180px; }

.add-edit-table .frame-input-label .gl-input__label--hoisted {
  background-color: transparent; }

.add-edit-table .gl-table th {
  height: 45px !important;
  padding: 0px 10px; }

.add-edit-table .gl-table td {
  height: 45px !important;
  padding: 0px 10px;
  vertical-align: top; }

.gl-dropdown {
  padding-top: 10px; }

.delete-icon {
  padding-top: 25px; }

.add-edit-table .gl-dropdown__select {
  height: 45px !important;
  letter-spacing: 1px !important;
  margin-top: 5px !important; }

.add-edit-table .gl-input__field {
  border: 1px solid #ebedee !important;
  min-height: 45px !important; }

.add-edit-table .gl-dropdown__select,
.gl-dropdown__option {
  border-color: #ebedee; }

.add-edit-table .edited-field .gl-dropdown__select {
  box-shadow: 0 1px 0 0 #00aa55 !important; }

.add-edit-table .no-selection .gl-dropdown__select {
  box-shadow: 0 1px 0 0 #e63f3f !important; }

.apply-all-table .gl-input__field {
  border: 1px solid #ebedee !important; }

.apply-all-table .gl-dropdown__select,
.gl-dropdown__option {
  border-color: #ebedee; }

.selected-channels {
  margin: 5px 10px !important;
  font-weight: normal; }

.apply-all-button {
  margin: 10px 0px; }

.number-of-frames {
  margin-left: 10px !important;
  font-size: 16px !important;
  font-weight: normal; }

.apply-all-collapse-icon {
  background-color: #f7f7f7 !important; }

.player-headers {
  width: auto; }

.player-headers:hover {
  opacity: 0.7; }

.collapse-button {
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 5px;
  display: flex; }

.expand-collape-label {
  padding-right: 5px; }

.add-icon {
  margin-right: 10px;
  margin-bottom: 2px; }

.add-new-frame-header {
  margin: 15px 0px 0px 15px !important; }

.disabled {
  cursor: not-allowed;
  opacity: 0.7; }

.channel-data {
  margin-top: 20px; }

.customized-modal {
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.4); }

.customized-modal-body {
  width: 500px;
  background: white;
  border: 1px solid; }

.customized-modal-container {
  padding: 15px; }

.apply-all-table .frames-add-edit th {
  display: none; }

.disabled-icon {
  opacity: 0.2;
  cursor: auto; }

.warning-icon {
  margin-top: 13px !important;
  margin-left: 6px !important; }

.dimension-input .gl-form-item {
  margin: 0px 10px !important; }

.dimension-input .gl-input {
  min-height: auto;
  margin-bottom: 0px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content; }

.dimension-label {
  margin: 25px 0px; }

.frame-color-icon {
  width: 15px;
  height: 15px;
  margin-left: -5px;
  margin-right: 10px;
  margin-top: 30px; }

.custom-screen-size .gl-checkbox__text {
  margin-left: 10px; }

.custom-screen-size-container .gl-form-item {
  margin: 0 !important; }

.custom-screen-size-container {
  margin: auto 10px !important; }

.dimension-input .gl-form-item {
  width: 160px; }

.frames-add-edit .generic-table__column--name {
  width: 250px; }

.frames-add-edit .frame-input-label {
  width: 180px; }

.AdminPanel {
  position: relative; }
  .AdminPanel--header {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0; }
  .AdminPanel .gl-icon {
    display: none; }

.edit-workgroup-border {
  border: solid 1px black; }

h5 {
  margin: 1rem 0 !important; }

.user-created-success-message {
  margin-top: 5vh;
  color: #00aa55; }

.user-created-failure-message {
  margin-top: 5vh;
  color: #e63f3f; }

.container-row-input-mail {
  width: 100%; }

.user-deletion-status-table {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.metadata-buttons__button {
  margin-right: 30px !important; }

.gl-table td {
  text-align: center; }

.meta-status.validation-error {
  color: #ffffff;
  background: #e63f3f; }

.meta-status.validation-success {
  color: #ffffff;
  background: #00aa55; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.player-status__icon {
  margin-right: 20px; }
  .player-status__icon title {
    display: none; }
  .player-status__icon--error {
    fill: #e63f3f; }
  .player-status__icon--success {
    fill: #00aa55; }
  .player-status__icon--warning {
    fill: #ede734; }

.player-status__column {
  display: flex;
  align-items: center;
  padding: 0 35px 0 0; }
  .player-status__column svg {
    margin-right: 20px;
    width: 30px; }
  .player-status__column--active {
    color: #00aa55; }
    .player-status__column--active svg {
      fill: #00aa55; }
  .player-status__column--inactive, .player-status__column--overdue {
    color: #ede734; }
    .player-status__column--inactive svg, .player-status__column--overdue svg {
      fill: #ede734; }

.player-status__playback {
  font-weight: bold; }
  .player-status__playback .health__link {
    cursor: pointer; }
    .player-status__playback .health__link:hover, .player-status__playback .health__link:focus {
      text-decoration: underline; }
  .player-status__playback--healthy {
    color: #00aa55; }
  .player-status__playback--unhealthy, .player-status__playback--overdue {
    color: #e63f3f; }

.player-status-table {
  height: auto !important; }

.health__link {
  cursor: pointer; }
  .health__link:hover, .health__link:focus {
    text-decoration: underline; }

.playlist-selected__header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .playlist-selected__header .inputs-wrapper {
    display: flex; }
    .playlist-selected__header .inputs-wrapper > div:not(:first-child) {
      margin-left: 0.5rem; }
  .playlist-selected__header .basic-multi-select {
    max-width: 440px; }

.playlist-selected__header-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-bottom: 1%; }
  .playlist-selected__header-inputs h4 {
    margin-bottom: 0;
    word-break: break-all; }

.playlist-selected__header--data span {
  margin-right: 15px; }

.playlist-selected__icon {
  cursor: pointer;
  margin-left: 0.5rem; }

.playlist-selected_input {
  min-width: 300px;
  width: 25vw; }

.playlist-selected .table-option__container {
  top: 23px; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.NavTab {
  padding: 1rem;
  color: #ffffff;
  border-bottom: 3px solid #000000;
  display: inline-block;
  text-transform: uppercase; }

.active .NavTab {
  color: #97dad0 !important;
  border-bottom: 3px solid #97dad0; }

.navtab-container {
  display: flex;
  align-items: flex-end;
  min-width: 650px; }

.header-actions__separator {
  height: 25px;
  margin-bottom: 5px;
  border-left: 1px solid #868080; }

.arrow-left {
  width: 10px !important;
  height: 15px !important;
  margin-bottom: 5px;
  margin-right: -5px; }

.header-actions__item a {
  text-decoration: none;
  color: white !important; }

.header-actions__button--disabled {
  opacity: 0.5; }
  .header-actions__button--disabled .header-actions__item:hover {
    cursor: not-allowed;
    color: white;
    background: black; }
    .header-actions__button--disabled .header-actions__item:hover .header-actions__icon {
      fill: white; }

.header-actions__tooltip::after {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
.Header {
  background: #000000;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3; }

.logo-container {
  display: flex;
  padding: 1rem; }

.header-right {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column; }

.header-user {
  display: flex;
  text-align: right;
  align-content: flex-end; }
  .header-user__inner {
    display: flex;
    position: absolute;
    top: 10px;
    right: 20px; }
  .header-user__avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 0.7rem; }
  .header-user__name {
    margin-right: 0.7rem; }
  .header-user__ellipsis {
    fill: #ffffff;
    height: 15px;
    width: 15px; }
  .header-user__logout-button {
    cursor: pointer;
    color: #000000;
    padding: 5px;
    display: block;
    width: 100%; }

.header-action-bar {
  flex: 1 0 auto;
  display: flex;
  align-items: flex-end;
  justify-items: end; }

.header-actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s; }

.header-actions__item {
  padding: 0.5rem 0.7rem;
  font-size: 0.8rem;
  position: relative; }
  .header-actions__item button {
    cursor: pointer; }
  .header-actions__item:hover .header-submenu, .header-actions__item:active .header-submenu, .header-actions__item:focus .header-submenu {
    visibility: visible;
    opacity: 1;
    display: block; }

.header-actions__item:hover {
  background: #98989a;
  color: #000000;
  cursor: pointer; }
  .header-actions__item:hover .header-actions__icon {
    fill: #000000; }

.header-actions__item-text {
  vertical-align: bottom;
  padding-left: 0.6rem; }

.header-actions__icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: bottom;
  fill: #ffffff; }

.header-actions__icon--smaller {
  height: 20px;
  width: 20px; }

.header-submenu {
  display: none;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 2;
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 250px;
  background: #000000;
  color: #ffffff; }
  .header-submenu__item {
    padding: 0.5rem 0.7rem;
    font-size: 0.8rem; }
    .header-submenu__item button {
      cursor: pointer; }
    .header-submenu__item-text {
      vertical-align: bottom;
      padding-left: 0.6rem; }
    .header-submenu__item:hover {
      background: #98989a;
      color: #000000;
      cursor: pointer; }
      .header-submenu__item:hover .header-submenu__icon {
        fill: #000000; }
  .header-submenu__icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: bottom;
    fill: #ffffff; }
    .header-submenu__icon--smaller {
      height: 20px;
      width: 20px; }

.header-user__ellipsis-container button {
  color: black; }
  .header-user__ellipsis-container button:hover {
    cursor: pointer; }

.header-user__ellipsis-container .gl-tooltip__arrow::after {
  top: 6px !important; }

/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
@-webkit-keyframes highlight {
  0% {
    background-color: lightgreen; }
  100% {
    background-color: white; } }
@keyframes highlight {
  0% {
    background-color: lightgreen; }
  100% {
    background-color: white; } }

.highlighter {
  -webkit-animation: highlight;
          animation: highlight;
  -webkit-animation-duration: 4s;
          animation-duration: 4s; }

.selected-file {
  margin-top: 2%; }

.image-thumbnail__container {
  margin-right: 1%; }

.uploading__title {
  margin: 1%; }

.uploading__title__browsed {
  text-align: center;
  margin-bottom: 5px; }

.uploading__rejection {
  position: relative;
  display: flex;
  margin-bottom: 5px;
  flex-wrap: wrap;
  border: 1px solid #eee;
  background-color: #ebedee;
  padding: 20px;
  max-width: 580px;
  margin: 0 auto;
  z-index: 999; }
  .uploading__rejection .validation-error {
    margin-bottom: 5px; }
  .uploading__rejection ul {
    color: #e63f3f;
    list-style: square;
    margin-top: 0;
    padding-left: 20px; }

.upload-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }

.uploading-wrapper {
  display: flex;
  margin: 1%;
  flex-wrap: wrap; }

.upload-wrapper__image-thumbnail {
  display: block;
  width: 10%;
  height: auto;
  margin-right: 1%;
  margin-bottom: 1%; }

.upload-wrapper__image-thumbnail--preview {
  height: 320px;
  margin-bottom: 1%; }

.upload-wrapper__image-thumbnail--img {
  width: 100%;
  height: auto; }

.progress-bar {
  position: relative;
  height: 4px;
  border-radius: 1px;
  background: #ebedee; }

.progress-bar__filler {
  text-align: center;
  background: #a7c1b9;
  height: 100%;
  border-radius: inherit;
  -webkit-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
  padding-top: 10px; }

.drag-and-drop__overlay {
  position: fixed;
  top: 103px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  background-image: -webkit-linear-gradient(45deg, #a7c1b9 16.67%, #ffffff 16.67%, #ffffff 50%, #a7c1b9 50%, #a7c1b9 66.67%, #ffffff 66.67%, #ffffff 100%);
  background-image: linear-gradient(45deg, #a7c1b9 16.67%, #ffffff 16.67%, #ffffff 50%, #a7c1b9 50%, #a7c1b9 66.67%, #ffffff 66.67%, #ffffff 100%);
  background-size: 4.24px 4.24px;
  opacity: 0.7;
  text-align: center;
  color: #000000;
  z-index: 999;
  font-size: 56px; }

.drag-and-drop__overlay--message {
  margin-top: 20%;
  text-transform: uppercase; }

.upload-wrapper__form {
  width: 400px;
  z-index: 1; }

.upload-wrapper__input-wrapper {
  position: relative;
  width: 100%;
  height: 50px; }
  .upload-wrapper__input-wrapper:after {
    content: attr(data-text);
    text-align: center;
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    background: #a7c1b9;
    padding: 10px 15px;
    display: block;
    width: 100%;
    pointer-events: none;
    z-index: 20;
    line-height: 30px;
    color: #ffffff;
    border-radius: 0.25em;
    font-weight: 300; }
  .upload-wrapper__input-wrapper .upload-wrapper__input-wrapper--input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 30px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%; }

.uploading__border {
  right: 0;
  bottom: 0;
  left: 0;
  max-width: unset !important; }
  .uploading__border .gl-box__content {
    height: 100%;
    display: flex; }

.uploading__icon {
  width: 80px;
  height: 80px;
  fill: #a7c1b9; }

.file__icon {
  display: flex;
  width: 48px;
  height: auto;
  vertical-align: bottom;
  fill: #ebedee;
  margin: auto; }

.edit-uploaded-media {
  margin-top: 30px; }

.edit-uploaded-media__label {
  margin-left: 2%;
  font-size: 24px; }

.uploading-wrapper {
  display: flex;
  padding: 20px;
  margin: 0; }

.uploaded-image {
  display: flex;
  align-items: center;
  width: 100%; }
  .uploaded-image__cancel {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
    .uploaded-image__cancel svg {
      width: 15px; }

.uploaded-image__thumb {
  min-height: 48px; }
  .uploaded-image__thumb img {
    width: 48px;
    height: auto; }

.uploaded-image__progress {
  width: 100%; }

.edit-uploaded-media {
  text-align: center;
  margin-bottom: 30px; }

.uploading__container {
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
  padding: 25px;
  cursor: pointer; }

.uploading__dndzone {
  width: 35%;
  border: 3px dashed #ebedee;
  padding: 70px;
  margin-right: 40px;
  height: 100%; }

.uploading__status {
  position: relative;
  width: 50%; }

.uploading__title {
  margin: 0 20px; }

.uploaded-image {
  padding: 20px 0;
  border-bottom: 1px solid #ebedee; }
  .uploaded-image:first-child {
    padding-top: 0; }
  .uploaded-image__name {
    word-break: break-word;
    margin-bottom: 10px; }
  .uploaded-image__details {
    padding: 0 20px;
    width: 90%;
    position: relative;
    text-align: left; }
    .uploaded-image__details .validation-error {
      margin-bottom: 5px; }
  .uploaded-image__upload-stats {
    display: flex;
    margin-top: 10px; }
    .uploaded-image__upload-stats span {
      margin-left: auto; }

.invalid-file-errors {
  list-style-type: disc;
  text-align: left; }

.full-height {
  height: 100vh;
}


.logs__container__filter_toggle {
  display: flex;
  justify-content: flex-end; }

.logs__container__filters {
  margin-bottom: 40px;
  border: 1px solid #ebedee;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column; }
  .logs__container__filters--loading {
    width: 100%;
    text-align: center;
    margin-top: 10px; }
  .logs__container__filters--hidden {
    display: none; }
  .logs__container__filters--error {
    padding: 20px; }
  .logs__container__filters__refine {
    width: 100%;
    margin: 0 !important;
    padding: 10px;
    display: flex;
    align-items: center;
    font-weight: normal;
    justify-content: flex-end; }
    .logs__container__filters__refine--buttons {
      text-align: center; }
      .logs__container__filters__refine--buttons button:last-child {
        margin-left: 15px; }
      .logs__container__filters__refine--buttons .gl-loader {
        margin-top: 20px; }
  .logs__container__filters__item {
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #ebedee; }
    .logs__container__filters__item--user {
      order: 2; }
    .logs__container__filters__item--operationType {
      order: 3; }
    .logs__container__filters__item--playlistName {
      border-bottom: none;
      order: 5; }
    .logs__container__filters__item--scheduleType {
      order: 4; }
    .logs__container__filters__item--playerName {
      order: 1; }
    .logs__container__filters__item .gl-label {
      font-size: 1rem; }
    .logs__container__filters__item .react-select__input input {
      height: 30px !important; }

.schedulingLogs {
  margin-top: 0; }
  .schedulingLogs .generic-table__column--operationtype, .schedulingLogs .generic-table__column--scheduletype {
    text-transform: capitalize; }
  .schedulingLogs .generic-table__column--createtimestamp {
    max-width: 190px; }

.activity-log-title {
  margin-bottom: 0px !important; }

.health-logs__container .gl-table__scrollable .generic-table__row td {
  white-space: normal;
  text-align: left; }

.health-logs__container .gl-table__scrollable .generic-table__column--description {
  max-width: 700px; }

.health-logs__container .gl-table__scrollable .generic-table__column--details li {
  font-size: 13px; }

/**
 * adidas
 * 1. Support
 * 2. Imports
 * 3. Core
 */
/**
 * 1. Support
 */
/* stylelint-disable declaration-property-value-blacklist  */
/**
 * Shared mixins
 * 1. Plumbing
 * 2. Porcelain
 */
/**
 * 1. Plumbing
 * Internal mixins, only used by foundations
 */
/**
 * Font face manager
 * @param {string} $font-name - The font family name. First element is used if this value is a list.
 * @param {string} $file-path - The file path to the font family.
 * @param {string} $font-weight [normal] - The font weight of the font family.
 * @param {string} $font-style [normal] - The font style of the font family.
 * @param {map} $font-extra-type - Contains an extra font type definition *format* and *type*.
 */
/**
 * 2. Porcelain
 * Public mixins
 */
/**
 * Font definition
 * @param {string} $fontFamily - The name of the font family you want to use.
 * @param {string} $weight [null] - Weight for selected font family.
 * @param {number} $size [null] - Size for selected font family.
 * @param {number} $lineHeight [null] - Line height for selected font family.
 */
/**
 * Pick the specific font from the body typeface
 * @param {string} $variant [null] - weight variant
 */
/*
 * Pick the specific font from the heading typeface
 * Doesn't change font-size, letter-spacing, line-height or text-transform.
 * Should only be used on HTML headings elements (h1, h2, h3, h4 or h5)
 * @param {string} $variant
 */
/*
 * Label
 * @param {string} $variant
 * @param {string} $weight
 * @param {bool} $condensed
 */
/**
 * Responsive breakpoint manager
 * @param {string} $breakpoint - The breakpoint for the element. Check available [breakpoints](#grid-variable-breakpoints)
 * @param {string} $direction [$media-direction] - The default `$media-direction` will be used.
 * @param {string} $custom-breakpoint [null] - Only use if extremely necessary.
 */
/**
 * Themification mixin
 * For providing separate styling per theme
 * @param {string} $attr [null] - Theme
 * @example
 *   // Will only generate CSS in Reebok CSS.
 *   @include themify('reebok') { color: green; }
 */
/**
 * 2. Imports
 */
/**
 * adidas specific color mappings
 */
/**
 * 1. Color mappings
 */
/**
 * 1.1 Global colors
 */
/**
* 1.2. Neutral Colors
*/
/**
 * 1.3. Brand colors
*/
/**
 * 2. Secondary Colors
 */
/**
 * 3. Generic common colors
 */
/**
 * 4. Validation colors
 */
/**
* General Typography Configuration
*
* 1. Configuration
* 2. Variables Assignments
* 3. Mappings
*/
/**
* 1. Configuration
*/
/**
* 2. Variables Assignments
* 2.1 Body
*/
/**
* 2.2 Headings
*/
/**
* 2.2.1 Headings Variants
*/
/**
* 2.3 Prices
*/
/**
* 2.4 Labels
*/
/**
* 2.6 Bold
*/
/**
* 3. Mappings
*/
/**
 * adidas specific mappings for gl-buttons
 */
/**
 * adidas Theme Definitions
 */
/**
 * 3. Core
 */
/**
 * Core
 *
 * The config folder holds configuration definitions to be used across our foundations.
 * The base folder holds boilerplate content. It holds the styles to use in any context, either apps, .com, creative services crm, etc..
 * The components folder holds all your micro layout files. Your styles for buttons and navigation and similar page components.
 * Misc styles for external or fetched content that we are not able to style directly.
 *
 * 1. Config
 * 2. Base
 * 3. Components
 * 4. Misc
 */
/**
 * 1. Config
 */
/**
 * General Animations Variables
 */
/**
 * General Grid Specification Variables
 */
/**
 * 2.  Base
 */
/* stylelint-disable declaration-no-important  */
/**
 * General Helpers
 * 1. Visibility
 * 2. Positioning
 *  2.1. Text Alignments
 * 3. Vertical spacing
 * 4. No margin
 * 5. screen readers
 */
/**
 * 1. Visibility
 */
/**
 * Use `.gl-hidden-s` to hide any element in small devices such as mobiles.
 */
@media (max-width: 599px) {
  .gl-hidden-s {
    display: none !important; } }

/**
 * Use `.gl-hidden-s-m` to hide any element in small and medium devices such as mobiles and tablets.
 */
@media (max-width: 959px) {
  .gl-hidden-s-m {
    display: none !important; } }

/**
 * Use `.gl-hidden-m` to hide any element on medium devices such as tablets.
 */
@media (min-width: 600px) {
  .gl-hidden-m {
    display: none !important; } }

/**
 * Use `.gl-hidden-l` to hide any element on large devices such as desktops.
 */
@media (min-width: 960px) {
  .gl-hidden-l {
    display: none !important; } }

/**
 * Use `.gl-hidden-xl` to hide any element on extra large devices such as desktops.
 */
@media (min-width: 1280px) {
  .gl-hidden-xl {
    display: none !important; } }

/**
 * Use `.gl-hidden-hg` to hide any element on huge devices such as uncommon desktop sizes.
 */
@media (min-width: 1600px) {
  .gl-hidden-hg {
    display: none !important; } }

/**
 * 2. Positioning
 * 2.1. Text Alignments
 */
.gl-text-center {
  text-align: center; }

.gl-text-start {
  text-align: left;
  text-align: start;
  /* stylelint-disable-line declaration-block-no-duplicate-properties */ }

.gl-text-end {
  text-align: right;
  text-align: end;
  /* stylelint-disable-line declaration-block-no-duplicate-properties */ }

/**
 * Deprecated classes since v2.0.4
 */
.gl-text-left {
  text-align: left; }

.gl-text-right {
  text-align: right; }

/**
 * 3. Vertical spacing

  avoid using old vertical spacing, refer to new vertical spacing
 */
.gl-vspacing-s {
  margin-bottom: 10px; }
  @media (min-width: 600px) {
    .gl-vspacing-s-small {
      margin-bottom: 10px; }
    .gl-vspacing-s-medium {
      margin-bottom: 20px; }
    .gl-vspacing-s-large {
      margin-bottom: 40px; }
    .gl-vspacing-s-huge {
      margin-bottom: 80px; } }

.gl-vspacing-m {
  margin-bottom: 20px; }
  @media (min-width: 960px) {
    .gl-vspacing-m-small {
      margin-bottom: 10px; }
    .gl-vspacing-m-medium {
      margin-bottom: 20px; }
    .gl-vspacing-m-large {
      margin-bottom: 40px; }
    .gl-vspacing-m-huge {
      margin-bottom: 80px; } }

.gl-vspacing-l {
  margin-bottom: 40px; }
  @media (min-width: 1280px) {
    .gl-vspacing-l-small {
      margin-bottom: 10px; }
    .gl-vspacing-l-medium {
      margin-bottom: 20px; }
    .gl-vspacing-l-large {
      margin-bottom: 40px; }
    .gl-vspacing-l-huge {
      margin-bottom: 80px; } }

.gl-vspacing-xl {
  margin-bottom: 80px; }
  @media (min-width: 1600px) {
    .gl-vspacing-xl-small {
      margin-bottom: 10px; }
    .gl-vspacing-xl-medium {
      margin-bottom: 20px; }
    .gl-vspacing-xl-large {
      margin-bottom: 40px; }
    .gl-vspacing-xl-huge {
      margin-bottom: 80px; } }

.gl-vspacing-all-small {
  margin-bottom: 10px; }

.gl-vspacing-all-medium {
  margin-bottom: 20px; }

.gl-vspacing-all-large {
  margin-bottom: 40px; }

.gl-vspacing-all-huge {
  margin-bottom: 80px; }

/**
* 4. No margin
*/
.gl-no-margin-bottom {
  margin-bottom: 0 !important; }

/**
 * 5. screen readers
 * Only display content to screen readers
 * See: https://a11yproject.com/posts/how-to-hide-content/
 */
.gl-screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/**
 * Reset
 * 1. Body Styles
 * 2. Input Styles
 * 3. Heading Styles
 * 4. Tag Styles
 */
*,
*:after,
*:before {
  box-sizing: border-box; }

/**
 * 1. Body Styles
 */
html,
body {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  /**
   * Prevent "centering jumps" when there is enough content loaded in the page
   * to have a vertical scroll
   */
  overflow-y: scroll; }

/**
 * 2. Input Styles
 */
input:focus,
select:focus,
textarea:focus,
button:focus,
[tabindex]:focus {
  outline: none !important;
  /* stylelint-disable-line declaration-no-important */ }

a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  box-shadow: none;
  outline: none; }

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
  -webkit-appearance: none; }

button {
  border: none;
  background: none;
  padding: 0;
  font: inherit;
  text-align: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit; }

button,
input,
optgroup,
select,
textarea {
  margin: 0; }

fieldset {
  border: none; }

form {
  width: 100%; }

input:focus,
textarea:focus {
  outline: none; }
  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    opacity: 0; }
  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
    opacity: 0; }
  input:focus:-ms-input-placeholder,
  textarea:focus:-ms-input-placeholder {
    opacity: 0; }
  input:focus::-ms-input-placeholder,
  textarea:focus::-ms-input-placeholder {
    opacity: 0; }
  input:focus::placeholder,
  textarea:focus::placeholder {
    opacity: 0; }

input::-ms-clear {
  width: 0;
  height: 0; }

/**
 * 3. Headings Styles
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  /* stylelint-disable-line declaration-property-value-blacklist */ }

/**
 * 4. Tag Styles
 */
p {
  margin: 0;
  padding: 0; }

i,
em {
  font-style: normal;
  /* stylelint-disable-line declaration-property-value-blacklist */ }

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none; }

a {
  cursor: pointer;
  text-decoration: none; }

/**
 * Theme Fonts
 * 1. Loaded Fonts
 */
/**
* 1. Loaded Fonts
*/
@font-face {
  font-family: "AdihausDIN";
  src: url(/static/media/AdihausDIN-Regular.6de878f0.woff2) format("woff2"), url(/static/media/AdihausDIN-Regular.e9631e3f.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "AdihausDIN";
  src: url(/static/media/AdihausDIN-Bold.3f0410cf.woff2) format("woff2"), url(/static/media/AdihausDIN-Bold.b0819b08.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "AdineuePRO";
  src: url(/static/media/adineuePRO-Regular.49942dd1.woff2) format("woff2"), url(/static/media/adineuePRO-Regular.46c5c8bc.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "AdineuePRO";
  src: url(/static/media/adineuePRO-Bold.0a14c4b6.woff2) format("woff2"), url(/static/media/adineuePRO-Bold.87faf648.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "AdineuePRO";
  src: url(/static/media/adineuePRO-Black.42d5a77a.woff2) format("woff2"), url(/static/media/adineuePRO-Black.0f255504.woff) format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

/* stylelint-disable declaration-property-value-blacklist, no-duplicate-selectors*/
/**
 * Typography
 *
 * 1. Body
 * 1.1. Default Styles
 * 1.2. Weight Variations
 * 2. Headings
 * 2.1. Default Styles
 * 2.2. Weight Variations
 * 2.4. Drop shadow Variation
 * 3. Labels
 * 3.1. Default Styles
 * 3.2. Size Variations
 * 3.3. Weight Variations
 * 4. Lists
 * 4.1. Unordered list
 * 4.2. Ordered list
 * 5. Prices
 * 5.1. Default Styles
 */
/**
 * 1. Body
 * 1.1. Default Styles
 */
body,
html {
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px; }

a {
  color: inherit; }

p {
  line-height: 22px;
  margin-bottom: 16px; }

strong,
.gl-strong {
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal; }

/**
 * 1.2. Weight Variations
 */
.gl-body--large {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 18px; }

.gl-body--small {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 14px; }

/**
 * 2. Headings
 * 2.1. Default Styles
 */
h1,
h2,
h3,
h4,
h5,
h6,
.gl-heading {
  font-family: "AdineuePRO", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 1.5px; }

/**
 * 2.2. Sizes Variations
 */
.gl-heading--xxl,
h1 {
  font-size: 56px;
  line-height: 48px; }
  @media (min-width: 1280px) {
    .gl-heading--xxl,
    h1 {
      font-size: 66px;
      line-height: 56px; } }

.gl-heading--xl,
h2 {
  font-size: 44px;
  line-height: 38px; }
  @media (min-width: 1280px) {
    .gl-heading--xl,
    h2 {
      font-size: 52px;
      line-height: 44px; } }

.gl-heading--l,
h3 {
  font-size: 36px;
  line-height: 32px; }
  @media (min-width: 1280px) {
    .gl-heading--l,
    h3 {
      font-size: 42px;
      line-height: 38px; } }

.gl-heading--m,
h4 {
  font-size: 26px;
  line-height: 24px; }
  @media (min-width: 1280px) {
    .gl-heading--m,
    h4 {
      font-size: 30px;
      line-height: 28px; } }

.gl-heading--s,
h5 {
  font-size: 18px;
  line-height: 16px; }
  @media (min-width: 1280px) {
    .gl-heading--s,
    h5 {
      font-size: 20px;
      line-height: 18px; } }

/**
 * 2.3. Weight Variations
 */
.gl-heading {
  /**
   * 2.4. Drop shadow Variation
   */ }
  .gl-heading--regular {
    font-family: "AdineuePRO", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal; }
  .gl-heading--black {
    font-family: "AdineuePRO", Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-style: normal; }
  .gl-heading--drop-shadow {
    text-shadow: 1px 1px #ffffff; }

/**
 * 3. Labels
 * 3.1. Default Styles
 */
.gl-label {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal; }
  .gl-label--small {
    font-size: 11px;
    line-height: 15px; }
  .gl-label--medium {
    font-size: 12px;
    line-height: 16px; }
  .gl-label--large {
    font-size: 13px;
    line-height: 17px; }
  .gl-label--extra-large {
    font-size: 14px;
    line-height: 18px; }
  .gl-label--bold {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700; }
  .gl-label--condensed {
    letter-spacing: .5px; }

/**
 * 4. Lists
 * 4.1. Default Styles
 */
ol,
ul,
.gl-list {
  font-size: 16px;
  line-height: 22px; }

.gl-list {
  list-style: disc inside; }
  .gl-list li, .gl-list__item {
    padding-left: 12px; }

/**
 * 4.2.  Ordered list.
 */
.gl-list-ordered {
  list-style: decimal inside; }
  .gl-list-ordered li, .gl-list-ordered__item {
    padding-left: 12px; }

/**
 * 5. Prices
 * 5.1. Default Styles
 */
.gl-price {
  display: flex;
  flex-wrap: wrap;
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 16px; }
  .gl-price--small {
    font-size: 12px; }
  .gl-price__value {
    color: #000000;
    margin-right: 5px; }
    .gl-price__value--crossed {
      color: #98989a;
      text-decoration: line-through; }
    .gl-price__value--sale {
      color: #e63f3f; }
    .gl-price__value:last-child {
      margin-right: 0; }

/**
 * General Grid Specification Styles
 * 1. Container
 * 2. Rows
 * 3. Columns
 *  3.1. Base Columns
 *  3.2. Medium Columns
 *  3.3. Large Columns
 *  3.4. Huge Columns
 * 4. Offsets
 *  4.1. Base Offsets
 *  4.2. Medium Offsets
 *  4.3. Large Offsets
 *  4.4. Huge Offsets
 * 5. No Gutters
 * 6. Vertical Alignments
 * 7. Stack Alignments
 */
*,
*:after,
*:before {
  box-sizing: border-box; }

/**
 * 1. Container
 */
.container {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  @media (min-width: 960px) {
    .container {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (min-width: 1280px) {
    .container {
      width: 1280px; } }
  @media (min-width: 1600px) {
    .container {
      width: 1600px; } }

/**
 * 2. Rows
 */
.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

/**
 * 3. Columns
 */
[class*='col-s'],
[class*='col-m'],
[class*='col-l'],
[class*='col-xl'],
[class*='col-hg'] {
  padding: 0 15px;
  float: left; }

/* stylelint-disable declaration-no-important  */
/**
 * 3.1. Base Columns
 */
.col-s-1 {
  width: 8.33333%; }

.col-s-2 {
  width: 16.66667%; }

.col-s-3 {
  width: 25%; }

.col-s-4 {
  width: 33.33333%; }

.col-s-5 {
  width: 41.66667%; }

.col-s-6 {
  width: 50%; }

.col-s-7 {
  width: 58.33333%; }

.col-s-8 {
  width: 66.66667%; }

.col-s-9 {
  width: 75%; }

.col-s-10 {
  width: 83.33333%; }

.col-s-11 {
  width: 91.66667%; }

.col-s-12 {
  width: 100%; }

/**
 * 3.2. Medium Columns
 */
@media (min-width: 600px) {
  .col-m-1 {
    width: 8.33333%; }
  .col-m-2 {
    width: 16.66667%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33333%; }
  .col-m-5 {
    width: 41.66667%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33333%; }
  .col-m-8 {
    width: 66.66667%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33333%; }
  .col-m-11 {
    width: 91.66667%; }
  .col-m-12 {
    width: 100%; } }

/**
 * 3.3. Large Columns
 */
@media (min-width: 960px) {
  .col-l-1 {
    width: 4.16667%; }
  .col-l-2 {
    width: 8.33333%; }
  .col-l-3 {
    width: 12.5%; }
  .col-l-4 {
    width: 16.66667%; }
  .col-l-5 {
    width: 20.83333%; }
  .col-l-6 {
    width: 25%; }
  .col-l-7 {
    width: 29.16667%; }
  .col-l-8 {
    width: 33.33333%; }
  .col-l-9 {
    width: 37.5%; }
  .col-l-10 {
    width: 41.66667%; }
  .col-l-11 {
    width: 45.83333%; }
  .col-l-12 {
    width: 50%; }
  .col-l-13 {
    width: 54.16667%; }
  .col-l-14 {
    width: 58.33333%; }
  .col-l-15 {
    width: 62.5%; }
  .col-l-16 {
    width: 66.66667%; }
  .col-l-17 {
    width: 70.83333%; }
  .col-l-18 {
    width: 75%; }
  .col-l-19 {
    width: 79.16667%; }
  .col-l-20 {
    width: 83.33333%; }
  .col-l-21 {
    width: 87.5%; }
  .col-l-22 {
    width: 91.66667%; }
  .col-l-23 {
    width: 95.83333%; }
  .col-l-24 {
    width: 100%; } }

/**
 * 3.4. Extra large Columns
 */
@media (min-width: 1280px) {
  .col-xl-1 {
    width: 4.16667%; }
  .col-xl-2 {
    width: 8.33333%; }
  .col-xl-3 {
    width: 12.5%; }
  .col-xl-4 {
    width: 16.66667%; }
  .col-xl-5 {
    width: 20.83333%; }
  .col-xl-6 {
    width: 25%; }
  .col-xl-7 {
    width: 29.16667%; }
  .col-xl-8 {
    width: 33.33333%; }
  .col-xl-9 {
    width: 37.5%; }
  .col-xl-10 {
    width: 41.66667%; }
  .col-xl-11 {
    width: 45.83333%; }
  .col-xl-12 {
    width: 50%; }
  .col-xl-13 {
    width: 54.16667%; }
  .col-xl-14 {
    width: 58.33333%; }
  .col-xl-15 {
    width: 62.5%; }
  .col-xl-16 {
    width: 66.66667%; }
  .col-xl-17 {
    width: 70.83333%; }
  .col-xl-18 {
    width: 75%; }
  .col-xl-19 {
    width: 79.16667%; }
  .col-xl-20 {
    width: 83.33333%; }
  .col-xl-21 {
    width: 87.5%; }
  .col-xl-22 {
    width: 91.66667%; }
  .col-xl-23 {
    width: 95.83333%; }
  .col-xl-24 {
    width: 100%; } }

/**
 * 3.5. Huge Columns
 */
@media (min-width: 1600px) {
  .col-hg-1 {
    width: 4.16667%; }
  .col-hg-2 {
    width: 8.33333%; }
  .col-hg-3 {
    width: 12.5%; }
  .col-hg-4 {
    width: 16.66667%; }
  .col-hg-5 {
    width: 20.83333%; }
  .col-hg-6 {
    width: 25%; }
  .col-hg-7 {
    width: 29.16667%; }
  .col-hg-8 {
    width: 33.33333%; }
  .col-hg-9 {
    width: 37.5%; }
  .col-hg-10 {
    width: 41.66667%; }
  .col-hg-11 {
    width: 45.83333%; }
  .col-hg-12 {
    width: 50%; }
  .col-hg-13 {
    width: 54.16667%; }
  .col-hg-14 {
    width: 58.33333%; }
  .col-hg-15 {
    width: 62.5%; }
  .col-hg-16 {
    width: 66.66667%; }
  .col-hg-17 {
    width: 70.83333%; }
  .col-hg-18 {
    width: 75%; }
  .col-hg-19 {
    width: 79.16667%; }
  .col-hg-20 {
    width: 83.33333%; }
  .col-hg-21 {
    width: 87.5%; }
  .col-hg-22 {
    width: 91.66667%; }
  .col-hg-23 {
    width: 95.83333%; }
  .col-hg-24 {
    width: 100%; } }

/**
 * 4. Offsets
 */
/**
 * 4.1. Base Offsets
 */
.offset-s-0 {
  margin-left: 0; }

.offset-s-1 {
  margin-left: 8.33333%; }

.offset-s-2 {
  margin-left: 16.66667%; }

.offset-s-3 {
  margin-left: 25%; }

.offset-s-4 {
  margin-left: 33.33333%; }

.offset-s-5 {
  margin-left: 41.66667%; }

.offset-s-6 {
  margin-left: 50%; }

.offset-s-7 {
  margin-left: 58.33333%; }

.offset-s-8 {
  margin-left: 66.66667%; }

.offset-s-9 {
  margin-left: 75%; }

.offset-s-10 {
  margin-left: 83.33333%; }

.offset-s-11 {
  margin-left: 91.66667%; }

.offset-s-12 {
  margin-left: 100%; }

/**
 * 4.2. Medium Offsets
 */
@media (min-width: 600px) {
  .offset-m-0 {
    margin-left: 0; }
  .offset-m-1 {
    margin-left: 8.33333%; }
  .offset-m-2 {
    margin-left: 16.66667%; }
  .offset-m-3 {
    margin-left: 25%; }
  .offset-m-4 {
    margin-left: 33.33333%; }
  .offset-m-5 {
    margin-left: 41.66667%; }
  .offset-m-6 {
    margin-left: 50%; }
  .offset-m-7 {
    margin-left: 58.33333%; }
  .offset-m-8 {
    margin-left: 66.66667%; }
  .offset-m-9 {
    margin-left: 75%; }
  .offset-m-10 {
    margin-left: 83.33333%; }
  .offset-m-11 {
    margin-left: 91.66667%; }
  .offset-m-12 {
    margin-left: 100%; } }

/**
 * 4.3. Large Offsets
 */
@media (min-width: 960px) {
  .offset-l-0 {
    margin-left: 0; }
  .offset-l-1 {
    margin-left: 4.16667%; }
  .offset-l-2 {
    margin-left: 8.33333%; }
  .offset-l-3 {
    margin-left: 12.5%; }
  .offset-l-4 {
    margin-left: 16.66667%; }
  .offset-l-5 {
    margin-left: 20.83333%; }
  .offset-l-6 {
    margin-left: 25%; }
  .offset-l-7 {
    margin-left: 29.16667%; }
  .offset-l-8 {
    margin-left: 33.33333%; }
  .offset-l-9 {
    margin-left: 37.5%; }
  .offset-l-10 {
    margin-left: 41.66667%; }
  .offset-l-11 {
    margin-left: 45.83333%; }
  .offset-l-12 {
    margin-left: 50%; }
  .offset-l-13 {
    margin-left: 54.16667%; }
  .offset-l-14 {
    margin-left: 58.33333%; }
  .offset-l-15 {
    margin-left: 62.5%; }
  .offset-l-16 {
    margin-left: 66.66667%; }
  .offset-l-17 {
    margin-left: 70.83333%; }
  .offset-l-18 {
    margin-left: 75%; }
  .offset-l-19 {
    margin-left: 79.16667%; }
  .offset-l-20 {
    margin-left: 83.33333%; }
  .offset-l-21 {
    margin-left: 87.5%; }
  .offset-l-22 {
    margin-left: 91.66667%; }
  .offset-l-23 {
    margin-left: 95.83333%; }
  .offset-l-24 {
    margin-left: 100%; } }

/**
 * 4.4. Extra large Offsets
 */
@media (min-width: 1280px) {
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; }
  .offset-xl-24 {
    margin-left: 100%; } }

/**
 * 4.5. Huge Offsets
 */
@media (min-width: 1600px) {
  .offset-hg-0 {
    margin-left: 0; }
  .offset-hg-1 {
    margin-left: 4.16667%; }
  .offset-hg-2 {
    margin-left: 8.33333%; }
  .offset-hg-3 {
    margin-left: 12.5%; }
  .offset-hg-4 {
    margin-left: 16.66667%; }
  .offset-hg-5 {
    margin-left: 20.83333%; }
  .offset-hg-6 {
    margin-left: 25%; }
  .offset-hg-7 {
    margin-left: 29.16667%; }
  .offset-hg-8 {
    margin-left: 33.33333%; }
  .offset-hg-9 {
    margin-left: 37.5%; }
  .offset-hg-10 {
    margin-left: 41.66667%; }
  .offset-hg-11 {
    margin-left: 45.83333%; }
  .offset-hg-12 {
    margin-left: 50%; }
  .offset-hg-13 {
    margin-left: 54.16667%; }
  .offset-hg-14 {
    margin-left: 58.33333%; }
  .offset-hg-15 {
    margin-left: 62.5%; }
  .offset-hg-16 {
    margin-left: 66.66667%; }
  .offset-hg-17 {
    margin-left: 70.83333%; }
  .offset-hg-18 {
    margin-left: 75%; }
  .offset-hg-19 {
    margin-left: 79.16667%; }
  .offset-hg-20 {
    margin-left: 83.33333%; }
  .offset-hg-21 {
    margin-left: 87.5%; }
  .offset-hg-22 {
    margin-left: 91.66667%; }
  .offset-hg-23 {
    margin-left: 95.83333%; }
  .offset-hg-24 {
    margin-left: 100%; } }

/**
 * 5. No Gutters
 */
.no-gutters {
  padding-right: 0;
  padding-left: 0; }

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

.no-gutters-s {
  padding-right: 0;
  padding-left: 0; }
  @media (min-width: 960px) {
    .no-gutters-s {
      padding-left: 15px;
      padding-right: 15px; } }

@media (min-width: 960px) {
  .no-gutters-l {
    padding-right: 0;
    padding-left: 0; } }

/**
 * 6. Vertical Alignments
 */
.gl-align-items-start {
  align-items: flex-start !important; }

.gl-align-items-center {
  align-items: center !important; }

.gl-align-items-end {
  align-items: flex-end !important; }

/**
 * 7. Stack Alignments
 */
.stack {
  margin-top: 30px;
  padding-bottom: 30px; }
  @media (min-width: 960px) {
    .stack {
      margin-top: 60px;
      padding-bottom: 60px; } }

/**
 * 3.  Components
 */
/**
 * Alerts
 * 1. General Styles
 * 2. Specific Alert Styles
 * 2.1. Info
 * 2.2. Warning
 * 2.3. Error
 * 2.4. Success
 */
/**
 * 1. General Styles
 */
.gl-alert {
  padding: 25px;
  color: #000000;
  position: relative;
  /* Content Styling */
  /**
   * 2. Specific Alert Styles
   */
  /**
   * 2.1. Alert info
   */
  /**
   * 2.2. Alert & Error warning
   */
  /**
   * 2.4. Alert success
   */ }
  .gl-alert__content p {
    margin-bottom: 0;
    line-height: 1.5em; }
  .gl-alert__content div:last-child p:last-child {
    margin-bottom: 0; }
  .gl-alert__title {
    text-transform: uppercase;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0; }
    @media (min-width: 1280px) {
      .gl-alert__title {
        font-size: 20px;
        line-height: 18px; } }
  .gl-alert[class^='icon-'] {
    display: none; }
  .gl-alert--info {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #000000; }
    .gl-alert--info:after {
      border-top-color: #ffffff; }
  .gl-alert--warning, .gl-alert--error {
    color: #ffffff;
    border: 1px solid #000000;
    background-color: #000000; }
  .gl-alert--success {
    color: #000000;
    background-color: #ebedee;
    border: 1px solid #000000; }
    .gl-alert--success:after {
      border-top-color: #ebedee; }

/* stylelint-disable declaration-property-value-blacklist */
/**
 * Buttons
 *
 * 0. Desktop CTA hover mixin
 * 1. Shared Styles
 * 2. Primary CTAs
 * 3. Secondary CTAs
 * 4. Tertiary CTAs
 * 5. Icon CTAs
 * 6. Loaders
 * 7. Disabled
 * 8. Specific Button Styles
 * 8.1 Paypal Button
 * 8.2 App store Button
 * 8.3 Play store Button
 * 9. Link
 */
/**
 * 0. Desktop CTA hover mixin
 * Only with a mouse to avoid the hover styles to stay visible after tap
 * No hover styles on disabled CTA
 * Duplicate hover styles on focus for minimal a11y but should be custom
 */
/**
 * 1. Shared Styles
 */
/**
 * 2. Primary CTAs
 */
.gl-cta {
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 50px;
  padding: 0 21px;
  line-height: 50px;
  background-color: #000000;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), border 0.1s cubic-bezier(0.3, 0, 0.45, 1), background 0.1s cubic-bezier(0.3, 0, 0.45, 1), -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), border 0.1s cubic-bezier(0.3, 0, 0.45, 1), background 0.1s cubic-bezier(0.3, 0, 0.45, 1), -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1), color 0.1s cubic-bezier(0.3, 0, 0.45, 1), border 0.1s cubic-bezier(0.3, 0, 0.45, 1), background 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1), color 0.1s cubic-bezier(0.3, 0, 0.45, 1), border 0.1s cubic-bezier(0.3, 0, 0.45, 1), background 0.1s cubic-bezier(0.3, 0, 0.45, 1), -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: 700;
  /**
   * 3. Secondary CTAs
   */
  /**
   * 4. Tertiary CTAs
   */
  /**
   * 5. Icon CTAs
   */
  /**
   * 6. Loader
   */
  /**
   * 7. Disabled
   */
  /**
   * 8. Specific Button Styles
   */
  /**
   * 8.1. Paypal Button
   */
  /**
   * 8.2. App store Button
   */
  /**
   * 8.3. Play store Button
   */ }
  .gl-cta:not(.gl-cta--disabled):active {
    -webkit-transform: scale(0.97);
            transform: scale(0.97); }
  @media (pointer: fine) {
    .gl-cta:not(.gl-cta--disabled):focus:not(:active), .gl-cta:not(.gl-cta--disabled):hover:not(:active) {
      color: #98989a;
      text-decoration: none; } }
  @supports (-moz-appearance: none) {
    .gl-cta:not(.gl-cta--disabled):focus:not(:active), .gl-cta:not(.gl-cta--disabled):hover:not(:active) {
      color: #98989a;
      text-decoration: none; } }
  .gl-cta--primary {
    height: 50px;
    -webkit-transform: translate(-3px, -3px);
            transform: translate(-3px, -3px); }
    .gl-cta--primary::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 1px #000000;
      -webkit-transform: translate(3px, 3px);
              transform: translate(3px, 3px);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
      transition: -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
      transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
      transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1), -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-cta--primary:not(.gl-cta--disabled):active {
      -webkit-transform: translate(0, 0) scale(1);
              transform: translate(0, 0) scale(1); }
      .gl-cta--primary:not(.gl-cta--disabled):active:after {
        -webkit-transform: translate(0, 0) scale(1);
                transform: translate(0, 0) scale(1); }
    .gl-cta--primary.gl-cta--disabled:after {
      box-shadow: inset 0 0 0 1px #98989a; }
  .gl-cta--primary-light {
    height: 50px;
    background-color: #ffffff;
    color: #000000;
    border-color: #ffffff; }
    .gl-cta--primary-light::after {
      box-shadow: inset 0 0 0 1px #ffffff; }
    @media (pointer: fine) {
      .gl-cta--primary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--primary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        border-color: #ffffff;
        background-color: none; } }
    @supports (-moz-appearance: none) {
      .gl-cta--primary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--primary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        border-color: #ffffff;
        background-color: none; } }
    .gl-cta--primary-light:after {
      border-color: #ffffff; }
  .gl-cta--full-width {
    width: 100%;
    justify-content: space-between; }
  .gl-cta--secondary {
    border: 1.01565px solid currentColor;
    color: #000000;
    border-color: #000000;
    height: 50px;
    background-color: #ffffff; }
    @media (pointer: fine) {
      .gl-cta--secondary:not(.gl-cta--disabled):focus:not(:active), .gl-cta--secondary:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        background-color: none; } }
    @supports (-moz-appearance: none) {
      .gl-cta--secondary:not(.gl-cta--disabled):focus:not(:active), .gl-cta--secondary:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        background-color: none; } }
    .gl-cta--secondary:after {
      content: normal; }
  .gl-cta--secondary-light {
    height: 50px;
    color: #ffffff;
    border-color: #ffffff;
    background-color: #000000; }
    @media (pointer: fine) {
      .gl-cta--secondary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--secondary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        border-color: #ffffff;
        background-color: none; } }
    @supports (-moz-appearance: none) {
      .gl-cta--secondary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--secondary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        border-color: #ffffff;
        background-color: none; } }
    .gl-cta--secondary-light:after {
      border-color: #000000; }
  .gl-cta--tertiary {
    margin-left: -2px;
    margin-right: -2px;
    padding: 2px;
    min-height: 0;
    color: #000000;
    background-color: transparent;
    max-width: 100%;
    white-space: unset;
    text-align: left;
    line-height: 13px;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700; }
    .gl-cta--tertiary::before {
      content: '';
      display: block;
      position: absolute;
      left: 2px;
      bottom: 2px;
      width: calc(100% - (2px * 2));
      border-bottom-width: 1.01565px;
      border-bottom-style: solid;
      border-color: #000000;
      -webkit-transition: 0.2s cubic-bezier(0.3, 0, 0.45, 1) border-color;
      transition: 0.2s cubic-bezier(0.3, 0, 0.45, 1) border-color; }
    @media (pointer: fine) {
      .gl-cta--tertiary:not(.gl-cta--disabled):focus:not(:active), .gl-cta--tertiary:not(.gl-cta--disabled):hover:not(:active) {
        color: #ffffff;
        background-color: #000000; }
        .gl-cta--tertiary:not(.gl-cta--disabled):focus:not(:active):before, .gl-cta--tertiary:not(.gl-cta--disabled):hover:not(:active):before {
          border-color: #ffffff; } }
    @supports (-moz-appearance: none) {
      .gl-cta--tertiary:not(.gl-cta--disabled):focus:not(:active), .gl-cta--tertiary:not(.gl-cta--disabled):hover:not(:active) {
        color: #ffffff;
        background-color: #000000; }
        .gl-cta--tertiary:not(.gl-cta--disabled):focus:not(:active):before, .gl-cta--tertiary:not(.gl-cta--disabled):hover:not(:active):before {
          border-color: #ffffff; } }
    .gl-cta--tertiary.gl-cta--loading::before {
      right: 30px;
      width: auto; }
    .gl-cta--tertiary.gl-cta--loading .gl-cta__loading-spinner {
      margin-left: 10px; }
  .gl-cta--tertiary-light {
    color: #ffffff; }
    .gl-cta--tertiary-light::before {
      border-color: #ffffff; }
    @media (pointer: fine) {
      .gl-cta--tertiary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--tertiary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #000000;
        background-color: #ffffff; }
        .gl-cta--tertiary-light:not(.gl-cta--disabled):focus:not(:active):before, .gl-cta--tertiary-light:not(.gl-cta--disabled):hover:not(:active):before {
          border-color: #000000; } }
    @supports (-moz-appearance: none) {
      .gl-cta--tertiary-light:not(.gl-cta--disabled):focus:not(:active), .gl-cta--tertiary-light:not(.gl-cta--disabled):hover:not(:active) {
        color: #000000;
        background-color: #ffffff; }
        .gl-cta--tertiary-light:not(.gl-cta--disabled):focus:not(:active):before, .gl-cta--tertiary-light:not(.gl-cta--disabled):hover:not(:active):before {
          border-color: #000000; } }
  .gl-cta--icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    padding: 0;
    color: #000000;
    background-color: #ffffff;
    border: 1.01565px solid #000000;
    letter-spacing: 0;
    line-height: 47px; }
    .gl-cta--icon.gl-cta--no-border {
      border: 0; }
    @media (pointer: fine) {
      .gl-cta--icon:not(.gl-cta--disabled):focus:not(:active), .gl-cta--icon:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        background-color: none; } }
    @supports (-moz-appearance: none) {
      .gl-cta--icon:not(.gl-cta--disabled):focus:not(:active), .gl-cta--icon:not(.gl-cta--disabled):hover:not(:active) {
        color: #98989a;
        background-color: none; } }
    .gl-cta--icon:after {
      content: normal; }
    .gl-cta--icon .gl-cta__icon {
      margin: 0; }
    .gl-cta--icon .gl-cta__loading-spinner {
      margin-left: 0; }
  .gl-cta__icon {
    flex-shrink: 0;
    margin-left: 20px; }
  .gl-cta--loading {
    align-items: center; }
    .gl-cta--loading .gl-cta__icon {
      display: none; }
  .gl-cta__loading-spinner {
    display: none;
    align-items: center;
    border-radius: 50%;
    margin: 0 0 0 20px;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation: loader 1.3s infinite linear;
            animation: loader 1.3s infinite linear;
    background: none;
    border-color: transparent transparent transparent currentColor;
    border-style: solid;
    width: 20px;
    height: 20px;
    border-width: 2px;
    justify-content: center; }
    .gl-cta--loading .gl-cta__loading-spinner {
      display: inline-flex; }
  .gl-cta--disabled {
    cursor: not-allowed;
    background-color: #98989a; }
  .gl-cta--paypal {
    color: #000000;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    line-height: 48px; }
    @media (pointer: fine) {
      .gl-cta--paypal:not(.gl-cta--disabled):focus:not(:active), .gl-cta--paypal:not(.gl-cta--disabled):hover:not(:active) {
        border: 1px solid #e2e2e2;
        background-color: #e2e2e2; } }
    @supports (-moz-appearance: none) {
      .gl-cta--paypal:not(.gl-cta--disabled):focus:not(:active), .gl-cta--paypal:not(.gl-cta--disabled):hover:not(:active) {
        border: 1px solid #e2e2e2;
        background-color: #e2e2e2; } }
    .gl-cta--paypal:before {
      content: '';
      width: 100%;
      min-width: 100px;
      height: 48px;
      background: url(/static/media/icon-paypal-logo.6275bb5d.svg) no-repeat left; }
  .gl-cta--app-store {
    padding: 0;
    background-color: transparent; }
    .gl-cta--app-store:before {
      content: '';
      min-width: 151px;
      height: 50px;
      background: url(/static/media/img-app-store-cta.2928664f.svg) no-repeat left;
      background-size: contain; }
  .gl-cta--play-store {
    padding: 0;
    background-color: transparent; }
    .gl-cta--play-store:before {
      content: '';
      min-width: 170px;
      height: 50px;
      background: url(/static/media/img-play-store-cta.d755f4a5.svg) no-repeat left;
      background-size: contain; }

/**
* 9. Link
*/
.gl-link {
  display: inline;
  vertical-align: bottom;
  padding-left: 1px;
  padding-right: 1px;
  color: #000000;
  text-decoration: underline;
  cursor: pointer;
  -webkit-transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
  @media (pointer: fine) {
    .gl-link:not(.gl-cta--disabled):focus:not(:active), .gl-link:not(.gl-cta--disabled):hover:not(:active) {
      color: #ffffff;
      background-color: #000000; } }
  @supports (-moz-appearance: none) {
    .gl-link:not(.gl-cta--disabled):focus:not(:active), .gl-link:not(.gl-cta--disabled):hover:not(:active) {
      color: #ffffff;
      background-color: #000000; } }
  .gl-link:active {
    color: #ffffff;
    background-color: #000000; }
  .gl-link .gl-icon {
    position: relative;
    top: 1px;
    margin-right: 7px; }
  .gl-link--light {
    color: #ffffff; }
    @media (pointer: fine) {
      .gl-link--light:not(.gl-cta--disabled):focus:not(:active), .gl-link--light:not(.gl-cta--disabled):hover:not(:active) {
        color: #000000;
        background-color: #ffffff; } }
    @supports (-moz-appearance: none) {
      .gl-link--light:not(.gl-cta--disabled):focus:not(:active), .gl-link--light:not(.gl-cta--disabled):hover:not(:active) {
        color: #000000;
        background-color: #ffffff; } }
    .gl-link--light:active {
      color: #000000;
      background-color: #ffffff; }

/**
 * Badges
 * 1. General Styles
 * 2. Badges Sizes
 * 3. Badges Variances
 */
/**
 * 1. General Styles
 */
.gl-badge {
  font-size: 12px;
  color: #000000;
  background-color: #ffffff;
  padding: 1px 2px;
  text-align: center;
  display: inline-block;
  line-height: 1.1em;
  position: absolute;
  left: 7px;
  top: 7px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-transform: translate(-100%, 0) rotate(270deg);
          transform: translate(-100%, 0) rotate(270deg);
  -webkit-transform-origin: right top;
          transform-origin: right top;
  /**
   * 2. Badges Sizes
   */
  /**
   * 3. Variations
   */ }
  @media (min-width: 960px) {
    .gl-badge {
      left: 10px;
      top: 10px; } }
  .gl-badge--small {
    line-height: 11px;
    font-size: 11px;
    padding: 3px;
    letter-spacing: 2px; }
    @media (min-width: 960px) {
      .gl-badge--small {
        font-size: 12px;
        padding: 4px; } }
  .gl-badge--large {
    padding: 4px;
    font-size: 13px; }
    @media (min-width: 960px) {
      .gl-badge--large {
        top: 0;
        left: 0;
        -webkit-transform: none;
                transform: none;
        padding: 7px;
        margin: 30px 30px 30px 0; } }
  .gl-badge--urgent {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal; }
  .gl-badge--semi-urgent {
    font-style: italic; }
  .gl-badge--inactive {
    color: #98989a; }

/**
 * Box
 */
.gl-box {
  position: relative;
  background-color: #ebedee;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden; }
  @media (min-width: 600px) {
    .gl-box {
      margin-left: 0;
      margin-right: 0; } }
  .gl-box__content {
    position: relative;
    padding: 30px 20px 35px; }
  .gl-box--urgent {
    padding: 15px;
    background: url(/static/media/bg-pattern-dashed.ec0169c5.svg) left top repeat;
    background-size: 7px; }
    .gl-box--urgent .gl-box__content {
      padding: 30px 20px 35px;
      background-color: #ffffff; }
  .gl-box--very-urgent {
    color: #ffffff;
    background-color: #000000; }

/**
 * Callouts
 */
.gl-callout {
  max-width: 523px; }
  .gl-callout .gl-box__content p:last-child {
    margin-bottom: 0; }
  .gl-callout--top::before, .gl-callout--top::after, .gl-callout--bottom::before, .gl-callout--bottom::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none; }
  .gl-callout--left::before, .gl-callout--right::before {
    height: 20px;
    background: #ffffff; }
  .gl-callout--left::after, .gl-callout--right::after {
    height: 20px;
    background: #ffffff; }
  .gl-callout--top::before, .gl-callout--top::after {
    top: 0; }
  .gl-callout--top.gl-box--urgent {
    padding-top: 35px; }
  .gl-callout--top:not(.gl-box--urgent) .gl-box__content {
    padding-top: 50px; }
  .gl-callout--bottom::before, .gl-callout--bottom::after {
    top: auto;
    bottom: 0; }
  .gl-callout--bottom::before {
    -webkit-transform: skew(30deg);
            transform: skew(30deg); }
  .gl-callout--bottom::after {
    -webkit-transform: skew(-30deg);
            transform: skew(-30deg); }
  .gl-callout--bottom:not(.gl-box--urgent) .gl-callout .gl-box__content {
    padding-bottom: 50px; }
  .gl-callout--bottom.gl-box--urgent {
    padding-bottom: 35px; }
  .gl-callout--left::before {
    left: -10px;
    width: 45px;
    height: 20px;
    background: #ffffff;
    -webkit-transform: skew(-30deg);
            transform: skew(-30deg); }
  .gl-callout--left::after {
    left: 46px;
    right: 0;
    height: 20px;
    width: calc(100% - 40px);
    background: #ffffff;
    -webkit-transform: skew(30deg);
            transform: skew(30deg); }
  .gl-callout--right::before {
    left: -6px;
    width: calc(100% - 40px); }
  .gl-callout--right::after {
    left: auto;
    right: -10px;
    width: 45px; }

/**
 * Carousel
 */
.gl-carousel__content {
  position: relative; }

.gl-carousel__container {
  overflow: hidden; }

.gl-carousel--overflow-visible .gl-carousel__container {
  overflow: visible; }

.gl-carousel__row {
  position: relative;
  margin-left: -0.5px;
  margin-right: -0.5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.gl-carousel__slider {
  display: flex;
  width: 100%; }
  .gl-carousel__slider--dragging {
    cursor: -webkit-grab;
    cursor: grab; }

.gl-carousel__item-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 0.5px;
  margin: 0; }

.gl-carousel__prev-next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 50%;
  padding: 5px;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1), opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1);
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1), opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1);
  background-color: transparent;
  color: #000000; }
  .gl-carousel__prev-next-button:hover {
    background-color: #000000;
    color: #ffffff; }
  .gl-carousel__prev-next-button--hidden {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1), visibility 0.2s cubic-bezier(0.3, 0, 0.45, 1) 0.2s;
    transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1), visibility 0.2s cubic-bezier(0.3, 0, 0.45, 1) 0.2s; }
  .gl-carousel__prev-next-button--next {
    right: -15px; }
    @media (min-width: 600px) {
      .gl-carousel__prev-next-button--next {
        right: -20px; } }
  .gl-carousel__prev-next-button--prev {
    left: -15px; }
    @media (min-width: 600px) {
      .gl-carousel__prev-next-button--prev {
        left: -20px; } }
    .gl-carousel__prev-next-button--prev .gl-carousel__prev-next-icon {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.gl-carousel--show-page-indicator .gl-carousel__prev-next-button {
  top: calc(50% - (30px / 2)); }

/**
 * Comparison Bar
 * 1. General Styles
 */
/**
 * 1. General Styles
 */
.gl-comparison-bar {
  position: relative; }
  .gl-comparison-bar__bg {
    height: 4px;
    background-color: #d9dcdd;
    overflow: hidden;
    position: relative; }
  .gl-comparison-bar__indicator {
    z-index: 3;
    width: 49px;
    background-color: #000000;
    height: 4px;
    position: absolute;
    top: 0; }
  .gl-comparison-bar__bar {
    z-index: 1; }
  .gl-comparison-bar__step-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .gl-comparison-bar__steps {
    width: 4px;
    height: 4px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    z-index: 2; }
    .gl-comparison-bar__steps--second {
      left: 25%;
      margin-left: -2px; }
    .gl-comparison-bar__steps--third {
      left: 50%;
      margin-left: -2px; }
    .gl-comparison-bar__steps--fourth {
      left: 75%;
      margin-left: -2px; }
  .gl-comparison-bar__label--first {
    text-align: left; }
  .gl-comparison-bar__label--second, .gl-comparison-bar__label--third, .gl-comparison-bar__label--fourth {
    text-align: center; }
  .gl-comparison-bar__label--last {
    text-align: right; }
  .gl-comparison-bar__label-wrapper {
    display: flex;
    justify-content: space-between;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    margin-top: 5px; }
  .gl-comparison-bar__caption {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 14px;
    margin-top: 10px; }
  .gl-comparison-bar__title {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    margin-bottom: 7px;
    text-align: left; }
  .gl-comparison-bar--triangle .gl-comparison-bar__bg {
    overflow: visible; }
  .gl-comparison-bar--triangle .gl-comparison-bar__indicator {
    width: 0;
    background-color: transparent;
    height: auto;
    border: 10px solid transparent;
    border-bottom-width: 20px;
    border-top: 20px solid #000000;
    top: -8px; }
  .gl-comparison-bar--triangle .gl-comparison-bar__label-wrapper {
    margin-top: 10px; }

/**
* Dividers
*/
.gl-divider {
  border: 0;
  margin: 0 0 20px 0;
  border-bottom: 1px;
  border-bottom-color: #d9dcdd;
  border-bottom-style: solid; }
  .gl-divider--dark {
    border-bottom-color: #98989a; }

/* stylelint-disable declaration-property-value-blacklist, function-url-quotes */
/**
 * Form
 * 1. Shared
 * 2. Checkbox
 * 3. Recaptcha
 */
/**
 * 1. Shared
 */
/*
 * General
 */
.gl-form__element {
  display: flex;
  min-height: 80px;
  margin-bottom: 10px; }
  .gl-form__element--checkbox {
    min-height: 54px; }
  .gl-form__element .gl-form-item {
    -ms-flex: 1; }

.gl-form-item {
  position: relative; }
  .gl-form-item--inline {
    display: inline-block; }
  .gl-form-item__input {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    min-height: 50px;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.01565px solid #98989a;
    padding: 0 18px 0 18px;
    width: 100%;
    margin: 0; }
  .gl-form-item__input-error {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAAh9JREFUWAm1lz9LAzEUwN87LNShIFQpujlYQah+BKl1cZdqUQSXfgLBoUtXXXTuIriL4uLU4mdQ8As4irOHtY15jyZ6vVwvuT9Zrk3u8vu9l0suQZBF7B7VYDS+hmLxEJ9uPqguryIarU0YwxV4hSb2bz+R4T+jvgQuAeArzBcbeUkwfASSJRYB8UVK7HgcOcMpZlGDL78v9k6lTLZlEvmA4dS1EDITw3OP0s6Ra172EjpyIcoaA/gAa6UOsgxFLCPnDOg7shkODae06yLh1VITe70hC+QlEQcnrhbIWkKP+XTaJ5ETj0pAgCr4BUw5HLZwo0BaCRd4pEBSCVf4TAFXiSTwWAFbiaRwK4E4Cfj2l+XaPpArW3CRmXrbqR9TCc0C001UZ5wdiG+yqZIUTv1aC9DNRglq0OVvhdNVMT+cBKgvlvD9Zxn1RqBvxEe5tu/T8hqoj/njxbSHm/3hiqyshBoErML7cCFUH1PhlAFRP94CkHuHwAv3n+D+AbPOgBFOaadNjC7un3IrASMc8J7GnHZQaSRihyASXi0dqBfOPDvshmOmgA1cZT+pRKSACzyNhFEgAq63UQpourpmIiSQBq6EXCQCAmm+agqurrYSWiBLuIsEC+QBt5XAPOE2Eh4fFANru/snVYGirnzWNK2Yvn/m0SmVD4r8dPZwJRWSQLyD7fUOt4vGSVnUW5ei3S6oB/K60uyQrAvR7c4R4xf8a+Jlpa4VCQAAAABJRU5ErkJggg==);
    background-position: calc(100% - 18px) 17px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding-right: 38px;
    border-color: #e63f3f;
    box-shadow: 0 1px #e63f3f; }
    .gl-form-item__input-error:focus {
      border-style: solid; }
  .gl-form-item__input::-webkit-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  .gl-form-item__input::-moz-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  .gl-form-item__input:-ms-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  .gl-form-item__input::-ms-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  .gl-form-item__input::placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }

.gl-form-label {
  font-size: 11px;
  top: -9px;
  left: 17px;
  margin-top: 0;
  padding: 2px 5px 1px;
  background-color: #ffffff;
  letter-spacing: 1px;
  color: #000;
  position: absolute;
  text-transform: uppercase;
  pointer-events: none;
  z-index: 1; }
  .gl-form-item--error .gl-form-label {
    color: #e63f3f; }

.gl-form-label-hint {
  color: #e63f3f;
  display: none;
  margin-left: 3px; }
  .gl-form-label-hint:after {
    content: '*'; }
  .gl-form-item--required .gl-form-label-hint {
    display: inline-block; }

.gl-form-hint,
.gl-form-message {
  font-size: 14px;
  line-height: 20px;
  padding-left: 21px; }

.gl-form-hint {
  color: #98989a; }

.gl-form-hint--error,
.gl-form-message {
  font-weight: bold;
  color: #e63f3f;
  display: none; }
  .gl-form-item--error .gl-form-hint--error, .gl-form-hint--error--show, .gl-form-item--error
  .gl-form-message,
  .gl-form-message--show {
    display: block; }

/**
 * 2. Checkbox
 */
.gl-checkbox .gl-form-message,
.gl-checkbox .gl-form-hint {
  padding-left: 0; }

.gl-checkbox__label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 24px;
  margin-bottom: 0;
  cursor: pointer; }

.gl-checkbox__input {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin: 0 14px 0 0;
  color: #98989a;
  opacity: 0;
  cursor: pointer;
  pointer-events: all; }

.gl-checkbox__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  color: #ffffff;
  background-color: #ffffff;
  pointer-events: none;
  box-shadow: inset 0 0 0 2px #98989a;
  -webkit-transition: box-shadow 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  transition: box-shadow 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
  .gl-form-item--has-focus .gl-checkbox__icon {
    border-color: #000000; }

.gl-checkbox__text {
  display: inline-flex;
  padding-left: 1px;
  padding-right: 1px;
  -webkit-transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
  .gl-form-item--has-focus .gl-checkbox__text {
    background-color: #000000;
    color: #ffffff; }

.gl-checkbox--checked .gl-checkbox__icon {
  background-color: #000000;
  box-shadow: inset 0 0 0 2px #000000; }

.gl-checkbox:hover .gl-checkbox__icon {
  box-shadow: inset 0 0 0 2px #000000; }

:not(.gl-checkbox) > label > input[type='checkbox'] {
  flex-shrink: 0;
  color: #98989a;
  width: 24px;
  height: 24px;
  margin: 0 15px 0 0;
  cursor: pointer;
  opacity: 0;
  left: 0;
  pointer-events: all; }
  :not(.gl-checkbox) > label > input[type='checkbox']:checked ~ .gl-checkbox__icon,
  :not(.gl-checkbox) > label > input[type='checkbox']:checked ~ i {
    font-size: 14px;
    background-color: #000000;
    border-color: #000000; }
  :not(.gl-checkbox) > label > input[type='checkbox'] ~ .gl-checkbox__icon,
  :not(.gl-checkbox) > label > input[type='checkbox'] ~ i {
    font-size: 0;
    background-color: #ffffff;
    color: #ffffff;
    border: 2px solid #98989a;
    width: 24px;
    height: 24px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    position: absolute; }

/**
 * 3. Recaptcha
 */
.gl-recaptcha {
  min-height: 101px; }

.gl-input {
  position: relative;
  min-height: calc(50px + 30px);
  margin-bottom: 10px; }
  .gl-input__field {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    min-height: 50px;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.01565px solid #98989a;
    padding: 0 18px 0 18px;
    width: 100%;
    margin: 0; }
    .gl-input__field--with-icon {
      padding-right: 50px; }
    .gl-form-item--has-focus .gl-input__field {
      border-bottom: 1.01565px solid #000000;
      box-shadow: 0 1px 0 0 #000000; }
  .gl-input__label {
    display: flex;
    font-size: 16px;
    letter-spacing: 1px;
    /* stylelint-disable-line  */
    color: #98989a;
    position: absolute;
    pointer-events: none;
    text-transform: uppercase;
    left: 21px;
    top: 14px;
    -webkit-transition: .2s ease all;
    transition: .2s ease all; }
    .gl-input__label--hoisted {
      font-size: 14px;
      top: -11px;
      left: 17px;
      margin-top: 0;
      padding: 2px 5px 1px;
      background-color: #ffffff; }
  .gl-form-item--success .gl-input__icon {
    color: #00aa55; }
  .gl-form-item--success .gl-input__field {
    border-bottom: 1.01565px solid #00aa55;
    box-shadow: 0 1px 0 0 #00aa55; }
  .gl-form-item--error .gl-input__icon {
    color: #e63f3f; }
  .gl-form-item--error .gl-input__field {
    border-bottom: 1.01565px solid #e63f3f;
    box-shadow: 0 1px 0 0 #e63f3f; }
  .gl-input__icon {
    position: absolute;
    top: 14px;
    right: 20px; }

/**
  * Legacy
  * TODO remove when all inputs are using the Gl component
  */
:not(.gl-input) > input[type='text'],
:not(.gl-input) > input[type='email'],
:not(.gl-input) > input[type='password'],
:not(.gl-input) > input[type='tel'] {
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  height: 50px;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border: 1.01565px solid #98989a;
  padding: 0 18px;
  width: 100%;
  margin: 0;
  -webkit-transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), border 0.1s cubic-bezier(0.3, 0, 0.45, 1), box-shadow 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), border 0.1s cubic-bezier(0.3, 0, 0.45, 1), box-shadow 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
  :not(.gl-input) > input[type='text']:focus,
  :not(.gl-input) > input[type='email']:focus,
  :not(.gl-input) > input[type='password']:focus,
  :not(.gl-input) > input[type='tel']:focus {
    border-color: #000000;
    box-shadow: 0 1px #000000; }
  .gl-form-item--error :not(.gl-input) > input[type='text'], .gl-form-item--error
  :not(.gl-input) > input[type='email'], .gl-form-item--error
  :not(.gl-input) > input[type='password'], .gl-form-item--error
  :not(.gl-input) > input[type='tel'] {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAAh9JREFUWAm1lz9LAzEUwN87LNShIFQpujlYQah+BKl1cZdqUQSXfgLBoUtXXXTuIriL4uLU4mdQ8As4irOHtY15jyZ6vVwvuT9Zrk3u8vu9l0suQZBF7B7VYDS+hmLxEJ9uPqguryIarU0YwxV4hSb2bz+R4T+jvgQuAeArzBcbeUkwfASSJRYB8UVK7HgcOcMpZlGDL78v9k6lTLZlEvmA4dS1EDITw3OP0s6Ra172EjpyIcoaA/gAa6UOsgxFLCPnDOg7shkODae06yLh1VITe70hC+QlEQcnrhbIWkKP+XTaJ5ETj0pAgCr4BUw5HLZwo0BaCRd4pEBSCVf4TAFXiSTwWAFbiaRwK4E4Cfj2l+XaPpArW3CRmXrbqR9TCc0C001UZ5wdiG+yqZIUTv1aC9DNRglq0OVvhdNVMT+cBKgvlvD9Zxn1RqBvxEe5tu/T8hqoj/njxbSHm/3hiqyshBoErML7cCFUH1PhlAFRP94CkHuHwAv3n+D+AbPOgBFOaadNjC7un3IrASMc8J7GnHZQaSRihyASXi0dqBfOPDvshmOmgA1cZT+pRKSACzyNhFEgAq63UQpourpmIiSQBq6EXCQCAmm+agqurrYSWiBLuIsEC+QBt5XAPOE2Eh4fFANru/snVYGirnzWNK2Yvn/m0SmVD4r8dPZwJRWSQLyD7fUOt4vGSVnUW5ei3S6oB/K60uyQrAvR7c4R4xf8a+Jlpa4VCQAAAABJRU5ErkJggg==);
    background-position: calc(100% - 18px) 17px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding-right: 38px;
    border-color: #e63f3f;
    box-shadow: 0 1px #e63f3f; }
    .gl-form-item--error :not(.gl-input) > input[type='text']:focus, .gl-form-item--error
    :not(.gl-input) > input[type='email']:focus, .gl-form-item--error
    :not(.gl-input) > input[type='password']:focus, .gl-form-item--error
    :not(.gl-input) > input[type='tel']:focus {
      border-style: solid; }
  :not(.gl-input) > input[type='text']::-webkit-input-placeholder,
  :not(.gl-input) > input[type='email']::-webkit-input-placeholder,
  :not(.gl-input) > input[type='password']::-webkit-input-placeholder,
  :not(.gl-input) > input[type='tel']::-webkit-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  :not(.gl-input) > input[type='text']::-moz-placeholder,
  :not(.gl-input) > input[type='email']::-moz-placeholder,
  :not(.gl-input) > input[type='password']::-moz-placeholder,
  :not(.gl-input) > input[type='tel']::-moz-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  :not(.gl-input) > input[type='text']:-ms-input-placeholder,
  :not(.gl-input) > input[type='email']:-ms-input-placeholder,
  :not(.gl-input) > input[type='password']:-ms-input-placeholder,
  :not(.gl-input) > input[type='tel']:-ms-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  :not(.gl-input) > input[type='text']::-ms-input-placeholder,
  :not(.gl-input) > input[type='email']::-ms-input-placeholder,
  :not(.gl-input) > input[type='password']::-ms-input-placeholder,
  :not(.gl-input) > input[type='tel']::-ms-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  :not(.gl-input) > input[type='text']::placeholder,
  :not(.gl-input) > input[type='email']::placeholder,
  :not(.gl-input) > input[type='password']::placeholder,
  :not(.gl-input) > input[type='tel']::placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  :not(.gl-input) > input[type='text']::-webkit-input-placeholder, :not(.gl-input) > input[type='text']:-ms-input-placeholder, :not(.gl-input) > input[type='text']::-ms-input-placeholder, :not(.gl-input) > input[type='text']:-moz-placeholder,
  :not(.gl-input) > input[type='email']::-webkit-input-placeholder,
  :not(.gl-input) > input[type='email']:-ms-input-placeholder,
  :not(.gl-input) > input[type='email']::-ms-input-placeholder,
  :not(.gl-input) > input[type='email']:-moz-placeholder,
  :not(.gl-input) > input[type='password']::-webkit-input-placeholder,
  :not(.gl-input) > input[type='password']:-ms-input-placeholder,
  :not(.gl-input) > input[type='password']::-ms-input-placeholder,
  :not(.gl-input) > input[type='password']:-moz-placeholder,
  :not(.gl-input) > input[type='tel']::-webkit-input-placeholder,
  :not(.gl-input) > input[type='tel']:-ms-input-placeholder,
  :not(.gl-input) > input[type='tel']::-ms-input-placeholder,
  :not(.gl-input) > input[type='tel']:-moz-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000; }
  :not(.gl-input) > input[type='text'].gl-form-item__input,
  :not(.gl-input) > input[type='email'].gl-form-item__input,
  :not(.gl-input) > input[type='password'].gl-form-item__input,
  :not(.gl-input) > input[type='tel'].gl-form-item__input {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    min-height: 50px;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.01565px solid #98989a;
    padding: 0 18px 0 18px;
    width: 100%;
    margin: 0; }
  :not(.gl-input) > input[type='text'].gl-form-item__input-error,
  :not(.gl-input) > input[type='email'].gl-form-item__input-error,
  :not(.gl-input) > input[type='password'].gl-form-item__input-error,
  :not(.gl-input) > input[type='tel'].gl-form-item__input-error {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAAh9JREFUWAm1lz9LAzEUwN87LNShIFQpujlYQah+BKl1cZdqUQSXfgLBoUtXXXTuIriL4uLU4mdQ8As4irOHtY15jyZ6vVwvuT9Zrk3u8vu9l0suQZBF7B7VYDS+hmLxEJ9uPqguryIarU0YwxV4hSb2bz+R4T+jvgQuAeArzBcbeUkwfASSJRYB8UVK7HgcOcMpZlGDL78v9k6lTLZlEvmA4dS1EDITw3OP0s6Ra172EjpyIcoaA/gAa6UOsgxFLCPnDOg7shkODae06yLh1VITe70hC+QlEQcnrhbIWkKP+XTaJ5ETj0pAgCr4BUw5HLZwo0BaCRd4pEBSCVf4TAFXiSTwWAFbiaRwK4E4Cfj2l+XaPpArW3CRmXrbqR9TCc0C001UZ5wdiG+yqZIUTv1aC9DNRglq0OVvhdNVMT+cBKgvlvD9Zxn1RqBvxEe5tu/T8hqoj/njxbSHm/3hiqyshBoErML7cCFUH1PhlAFRP94CkHuHwAv3n+D+AbPOgBFOaadNjC7un3IrASMc8J7GnHZQaSRihyASXi0dqBfOPDvshmOmgA1cZT+pRKSACzyNhFEgAq63UQpourpmIiSQBq6EXCQCAmm+agqurrYSWiBLuIsEC+QBt5XAPOE2Eh4fFANru/snVYGirnzWNK2Yvn/m0SmVD4r8dPZwJRWSQLyD7fUOt4vGSVnUW5ei3S6oB/K60uyQrAvR7c4R4xf8a+Jlpa4VCQAAAABJRU5ErkJggg==);
    background-position: calc(100% - 18px) 17px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding-right: 38px;
    border-color: #e63f3f;
    box-shadow: 0 1px #e63f3f; }
    :not(.gl-input) > input[type='text'].gl-form-item__input-error:focus,
    :not(.gl-input) > input[type='email'].gl-form-item__input-error:focus,
    :not(.gl-input) > input[type='password'].gl-form-item__input-error:focus,
    :not(.gl-input) > input[type='tel'].gl-form-item__input-error:focus {
      border-style: solid; }
  :not(.gl-input) > input[type='text'].gl-form-item__input::-webkit-input-placeholder,
  :not(.gl-input) > input[type='email'].gl-form-item__input::-webkit-input-placeholder,
  :not(.gl-input) > input[type='password'].gl-form-item__input::-webkit-input-placeholder,
  :not(.gl-input) > input[type='tel'].gl-form-item__input::-webkit-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  :not(.gl-input) > input[type='text'].gl-form-item__input::-moz-placeholder,
  :not(.gl-input) > input[type='email'].gl-form-item__input::-moz-placeholder,
  :not(.gl-input) > input[type='password'].gl-form-item__input::-moz-placeholder,
  :not(.gl-input) > input[type='tel'].gl-form-item__input::-moz-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  :not(.gl-input) > input[type='text'].gl-form-item__input:-ms-input-placeholder,
  :not(.gl-input) > input[type='email'].gl-form-item__input:-ms-input-placeholder,
  :not(.gl-input) > input[type='password'].gl-form-item__input:-ms-input-placeholder,
  :not(.gl-input) > input[type='tel'].gl-form-item__input:-ms-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  :not(.gl-input) > input[type='text'].gl-form-item__input::-ms-input-placeholder,
  :not(.gl-input) > input[type='email'].gl-form-item__input::-ms-input-placeholder,
  :not(.gl-input) > input[type='password'].gl-form-item__input::-ms-input-placeholder,
  :not(.gl-input) > input[type='tel'].gl-form-item__input::-ms-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  :not(.gl-input) > input[type='text'].gl-form-item__input::placeholder,
  :not(.gl-input) > input[type='email'].gl-form-item__input::placeholder,
  :not(.gl-input) > input[type='password'].gl-form-item__input::placeholder,
  :not(.gl-input) > input[type='tel'].gl-form-item__input::placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }

/**
 * Dropdown
 */
.gl-dropdown {
  position: relative;
  display: block;
  cursor: pointer; }
  .gl-dropdown__select, .gl-dropdown__option {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.01565px solid #98989a;
    padding: 0 18px;
    width: 100%;
    margin: 0; }
  .gl-dropdown__select {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    cursor: pointer;
    -webkit-transition: border 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: border 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-dropdown__select:focus, .gl-dropdown__select:hover {
      border-color: #000000; }
  .gl-dropdown__select-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .gl-dropdown__select-icon {
    flex-shrink: 0;
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1), -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    margin-left: 10px; }
  .gl-dropdown__options {
    background-color: #ffffff;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 195px;
    overflow: auto;
    border: 1.01565px solid #98989a;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.1s cubic-bezier(0.3, 0, 0.45, 1), visibility 0.1s cubic-bezier(0.3, 0, 0.45, 1) 0.1s;
    transition: opacity 0.1s cubic-bezier(0.3, 0, 0.45, 1), visibility 0.1s cubic-bezier(0.3, 0, 0.45, 1) 0.1s; }
    .gl-dropdown__options--squared {
      padding: 20px 10px; }
    .gl-dropdown__options--with-before .gl-square-list {
      padding-top: 16px; }
    .gl-dropdown__options--with-after .gl-square-list {
      padding-bottom: 16px; }
  .gl-dropdown__option {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0;
    border-bottom: 1.01565px solid #ebedee;
    cursor: pointer;
    -webkit-transition: background 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: background 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-dropdown__option:focus, .gl-dropdown__option:hover {
      background-color: #ebedee; }
    .gl-dropdown__option--selected {
      font-weight: 700; }
  .gl-dropdown__item:last-child .gl-dropdown__option:last-of-type {
    border-bottom: 0; }
  .gl-dropdown__select-element {
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: block; }
    @media (min-width: 600px) {
      .gl-dropdown__select-element {
        display: none; } }
  .gl-dropdown--open .gl-dropdown__select {
    border-color: #000000; }
  .gl-dropdown--open .gl-dropdown__select-icon {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1); }
  .gl-dropdown--open .gl-dropdown__options {
    border-color: #000000;
    border-top-color: #ebedee;
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.1s cubic-bezier(0.3, 0, 0.45, 1), visibility 0.1s cubic-bezier(0.3, 0, 0.45, 1) 0s;
    transition: opacity 0.1s cubic-bezier(0.3, 0, 0.45, 1), visibility 0.1s cubic-bezier(0.3, 0, 0.45, 1) 0s; }
  .gl-dropdown--disabled {
    cursor: not-allowed; }
  .gl-dropdown--disabled .gl-dropdown__select {
    color: #8c8c8c;
    border-color: #8c8c8c; }
  .gl-dropdown--small .gl-dropdown__select, .gl-dropdown--small .gl-dropdown__option {
    padding-left: 15px;
    padding-right: 15px; }
  .gl-dropdown--small .gl-dropdown__select-icon {
    margin-left: 5px; }
  .gl-dropdown--no-max-height .gl-dropdown__options {
    max-height: none; }

/**
 * GlIcon
 */
.gl-icon {
  display: inline-block;
  vertical-align: text-top;
  width: 24px;
  height: 24px; }
  .gl-icon--light {
    color: #ffffff; }
  .gl-icon--size-communication {
    width: 19px;
    height: 19px; }

/**
 * Loader
 * 1. General Styles
 */
/**
 * 1. General Styles
 */
.gl-loader {
  content: '';
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  box-sizing: border-box;
  -webkit-animation: loader 1.3s infinite linear;
          animation: loader 1.3s infinite linear;
  background: none;
  border-color: transparent transparent transparent #ffffff;
  border-style: solid;
  width: 90px;
  height: 90px;
  border-width: 4px; }
  .gl-loader--black {
    border-color: transparent transparent transparent #000000; }

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/**
 * Menu
 */
.gl-menu {
  padding: 0;
  margin: 0; }
  .gl-menu__item {
    position: relative;
    list-style: none; }
    .gl-menu__item--active {
      font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-style: normal;
      z-index: 1;
      position: relative; }
      .gl-menu__item--active .gl-menu__element {
        color: #000000; }
  .gl-menu__element {
    width: 100%;
    cursor: pointer;
    -webkit-transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-menu__element:focus, .gl-menu__element:hover {
      color: #000000; }

/**
 * Modal
 * 1. General Styles
 * 2. Active
 * 3. Variations
 */
/**
 * 1. General Styles
 */
.gl-modal {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: fixed;
  z-index: 2;
  width: 100vw;
  left: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;
  touch-action: none;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0s linear 0.3s, visibility 0s linear 0.3s;
  transition: opacity 0s linear 0.3s, visibility 0s linear 0.3s;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  /**
   * 2. Active / visible
   */
  /**
   * 3. Variations
   */ }
  @media (min-width: 960px) {
    .gl-modal {
      justify-content: center;
      align-items: center; } }
  .gl-modal__overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    pointer-events: all;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1) 0.1s, visibility 0s linear 0.3s;
    transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1) 0.1s, visibility 0s linear 0.3s; }
  .gl-modal__dialog, .gl-modal__main {
    max-height: 90vh; }
    @media (min-width: 960px) {
      .gl-modal__dialog, .gl-modal__main {
        max-height: 92vh; } }
  .gl-modal__dialog {
    position: relative;
    max-width: 90vw;
    pointer-events: all;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1), visibility 0s linear 0.2s;
    transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1), visibility 0s linear 0.2s; }
    @media (min-width: 960px) {
      .gl-modal__dialog {
        margin-right: 30px;
        margin-left: 30px; } }
  .gl-modal__main {
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    background-color: #ffffff;
    padding: 30px 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    @media (min-width: 960px) {
      .gl-modal__main {
        border-left: 1px solid #000000;
        border-bottom: 1px solid #000000;
        padding: 20px 15px; } }
  .gl-modal__title {
    padding-right: 15px; }
    @media (min-width: 960px) {
      .gl-modal__title {
        padding-right: auto; } }
  .gl-modal__close {
    position: absolute;
    top: 5px;
    right: 5px;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #000000;
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1), -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-modal__close:active {
      -webkit-transform: translate(50%, -50%) scale(0.94);
              transform: translate(50%, -50%) scale(0.94); }
  .gl-modal--active {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0s, visibility 0s;
    transition: opacity 0s, visibility 0s; }
  .gl-modal--active .gl-modal__overlay {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1), visibility 0s;
    transition: opacity 0.2s cubic-bezier(0.3, 0, 0.45, 1), visibility 0s; }
  .gl-modal--active .gl-modal__dialog {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.45s cubic-bezier(0.3, 0, 0.45, 1) 0.1s, visibility 0s;
    transition: opacity 0.45s cubic-bezier(0.3, 0, 0.45, 1) 0.1s, visibility 0s; }
  @media (min-width: 960px) {
    .gl-modal--small .gl-modal__main, .gl-modal--small .gl-modal__dialog {
      max-height: 80vh; } }
  @media (min-width: 960px) {
    .gl-modal--small .gl-modal__main {
      padding: 58px 30px; } }
  @media (min-width: 960px) {
    .gl-modal--large .gl-modal__main, .gl-modal--large .gl-modal__dialog {
      max-height: 80vh; } }
  @media (min-width: 960px) {
    .gl-modal--large .gl-modal__dialog {
      max-width: 1200px; } }
  @media (max-width: 959px) {
    .gl-modal--mobile-full .gl-modal__dialog {
      /* Fixed position makes sure the 100% height accounts for the URL bar on mobile Chrome,
        * instead of flowing under the bottom of the screen.
        * https://developers.google.com/web/updates/2016/12/url-bar-resizing
        */
      position: fixed;
      max-width: 100vw;
      height: auto;
      bottom: 0; }
    .gl-modal--mobile-full .gl-modal__main {
      border-right: 0; }
    .gl-modal--mobile-full .gl-modal__close {
      top: 0;
      right: 15px;
      -webkit-transform: translate3d(0, -50%, 0);
              transform: translate3d(0, -50%, 0); }
      .gl-modal--mobile-full .gl-modal__close:hover {
        will-change: transform; }
      .gl-modal--mobile-full .gl-modal__close:active {
        -webkit-transform: translate3d(0, -50%, 0) scale(0.94);
                transform: translate3d(0, -50%, 0) scale(0.94); } }
  .gl-modal--left .gl-modal__dialog,
  .gl-modal--right .gl-modal__dialog {
    margin-right: 0;
    margin-left: 0; }
  .gl-modal--left .gl-modal__main,
  .gl-modal--right .gl-modal__main {
    border-bottom: 0; }
    @media (min-width: 960px) {
      .gl-modal--left .gl-modal__main,
      .gl-modal--right .gl-modal__main {
        padding: 50px 48px 48px 48px; } }
    @media (min-width: 1280px) {
      .gl-modal--left .gl-modal__main,
      .gl-modal--right .gl-modal__main {
        padding: 54px 48px 48px 48px; } }
  .gl-modal--left {
    justify-content: flex-start;
    align-items: flex-end; }
  .gl-modal--left .gl-modal__main {
    border-left: 0;
    padding-right: 35px; }
  .gl-modal--right {
    justify-content: flex-end;
    align-items: flex-end; }
  .gl-modal--right .gl-modal__main {
    border-left: 1px solid #000000;
    border-right: 0;
    padding-left: 35px; }
  .gl-modal--right .gl-modal__close {
    right: auto;
    left: 5px;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0); }
    .gl-modal--right .gl-modal__close:active {
      -webkit-transform: translate3d(-50%, -50%, 0) scale(0.94);
              transform: translate3d(-50%, -50%, 0) scale(0.94); }
  @media (min-width: 960px) {
    .gl-modal .container {
      padding: 0; } }

/**
 * PageIndicator
 * @TODO 2.0: change flex to inline-flex and remove justify-content center on the component
   the positioning should be handled outside of the component
 */
.gl-page-indicator {
  display: flex;
  justify-content: center;
  padding: 0 8px;
  list-style: none;
  color: #000000;
  pointer-events: auto;
  margin-top: 20px; }
  .gl-page-indicator__item {
    display: flex;
    align-items: flex-end;
    height: 12px;
    padding: 4px 3px;
    cursor: pointer; }
  @media (pointer: fine) {
    .gl-page-indicator__item:hover .gl-page-indicator__dot {
      height: 100%; } }
  .gl-page-indicator__dot {
    display: block;
    width: 18px;
    height: 25%;
    background-color: currentColor;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transition: height 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: height 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-page-indicator__dot--selected {
      height: 100%; }
  .gl-page-indicator--light {
    color: #ffffff; }
  .gl-page-indicator--shadow .gl-page-indicator__dot {
    box-shadow: 1px 1px 0 #ffffff; }
  .gl-page-indicator--shadow.gl-page-indicator--light .gl-page-indicator__dot {
    box-shadow: 1px 1px 0 #000000; }
  @media (min-width: 600px) {
    .gl-page-indicator--autoplay {
      color: #ffffff; }
      .gl-page-indicator--autoplay .gl-page-indicator__dot {
        box-shadow: 1px 1px 0 #000000; } }

/**
 * Product cards
 */
/**
  * These variables will be removed after Reebok redesign (End of Q1 2019)
  */
.gl-product-card {
  position: relative;
  width: 100%;
  color: #000000;
  background-color: #ffffff;
  cursor: pointer; }
  @media (min-width: 960px) {
    .gl-product-card {
      border: 1px solid transparent; } }
  .gl-product-card:hover {
    z-index: 1; }
    @media (min-width: 960px) {
      .gl-product-card:hover {
        border-color: #d9dcdd; } }
  .gl-product-card a {
    display: block; }
  .gl-product-card__image, .gl-product-card__image-hover {
    display: block;
    width: 100%; }
  .gl-product-card__image-hover {
    position: absolute;
    top: 0;
    visibility: hidden;
    opacity: 0; }
  .gl-product-card__media {
    background-color: #eceef0; }
    .gl-product-card__media:hover .gl-product-card__image-hover {
      visibility: visible;
      opacity: 1; }
  .gl-product-card__broken-image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    color: #98989a; }
    .gl-product-card__broken-image:before {
      content: '';
      display: block;
      padding-top: 100%; }
  .gl-product-card__wishlist {
    color: #000000;
    position: absolute;
    right: 14px;
    top: 14px; }
    @media (min-width: 960px) {
      .gl-product-card__wishlist {
        right: 16px;
        top: 16px; } }
  .gl-product-card__details {
    padding: 10px;
    overflow: auto;
    clear: both; }
    @media (min-width: 960px) {
      .gl-product-card__details {
        padding: 10px 10px 20px; } }
    .gl-product-card__details a {
      display: block;
      min-height: 75px; }
  .gl-product-card__details-top {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
    min-height: 17px; }
  .gl-product-card__category, .gl-product-card__color {
    font-size: 12px; }
  .gl-product-card__category {
    color: #98989a;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .gl-product-card__details-icons {
    display: flex; }
  .gl-product-card__details-icon {
    position: relative;
    width: 17px;
    height: 17px;
    margin-left: 8px;
    font-size: 0; }
    .gl-product-card__details-icon--personalization, .gl-product-card__details-icon--customization {
      width: 17px;
      height: 17px; }
  .gl-product-card__details-main {
    line-height: normal;
    height: 60px; }
  .gl-product-card__name {
    letter-spacing: .5px;
    margin: 0 0 5px 0;
    max-height: 30px;
    overflow: hidden; }
  .gl-product-card .gl-price {
    font-size: 12px; }
  .gl-product-card__details-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .gl-product-card__reviews {
    color: #98989a;
    display: flex;
    align-items: flex-start;
    font-size: 10px; }
  .gl-product-card__reviews-number {
    font-size: 12px;
    line-height: 1;
    padding-left: 5px; }
  .gl-product-card__color {
    line-height: 1;
    text-transform: lowercase;
    color: #98989a; }
  .gl-product-card .gl-product-card__stars {
    color: #98989a; }
  .gl-product-card .gl-star-rating__star {
    height: 11px;
    width: 11px; }

.gl-product-card-container {
  padding: 0 1px; }
  @media (min-width: 960px) {
    .gl-product-card-container {
      padding: 0 5px; } }
  .gl-product-card-container .gl-product-card__carousel {
    display: none; }

/**
 * Carousel
 */
@media (min-width: 960px) {
  .gl-product-card-container.show-variation-carousel:hover {
    position: relative; }
    .gl-product-card-container.show-variation-carousel:hover .gl-product-card {
      position: absolute;
      left: 5px;
      width: calc(100% - 10px);
      z-index: 1; } }
  @media (min-width: 960px) and (min-width: 960px) {
    .gl-product-card-container.show-variation-carousel:hover .gl-product-card__carousel {
      display: block; } }

.gl-product-card-container.show-variation-carousel .gl-product-card__carousel {
  background: #eceef0;
  position: relative;
  margin: 3px 0 0 0;
  overflow-x: hidden;
  width: 100%;
  padding: 0 20px; }
  .gl-product-card-container.show-variation-carousel .gl-product-card__carousel-item.active, .gl-product-card-container.show-variation-carousel .gl-product-card__carousel-item:hover,
  .gl-product-card-container.show-variation-carousel .gl-product-card__carousel img.active,
  .gl-product-card-container.show-variation-carousel .gl-product-card__carousel img:hover {
    border-bottom: 2px solid #000000; }
  .gl-product-card-container.show-variation-carousel .gl-product-card__carousel img {
    box-sizing: border-box;
    height: auto;
    min-height: 1px;
    width: 100%; }
  .gl-product-card-container.show-variation-carousel .gl-product-card__carousel .gl-carousel__prev-next-button {
    height: 100%;
    margin: 0;
    width: 20px; }

.gl-radio-group .gl-form-message,
.gl-radio-group .gl-form-hint {
  padding-left: 0; }

.gl-radio-input {
  display: flex;
  /*
   * Direction variations
   */ }
  .gl-radio-input__option {
    margin-right: 25px;
    flex-shrink: 0;
    margin-bottom: 0;
    position: relative;
    cursor: pointer; }
  .gl-radio-input__label {
    height: 24px;
    display: inline-flex;
    line-height: 24px; }
    .gl-radio-input__label::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      color: #98989a;
      border: 2px solid currentColor;
      background-color: #ffffff;
      text-transform: none;
      border-radius: 50%;
      cursor: pointer;
      pointer-events: none; }
  .gl-radio-input__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    padding: 0; }
    .gl-radio-input__input:checked ~ .gl-radio-input__label::before {
      color: #000000;
      box-shadow: inset 0 0 0 6px currentColor; }
  .gl-radio-input--horizontal {
    flex-direction: row; }
  .gl-radio-input--vertical {
    flex-direction: column; }
    .gl-radio-input--vertical .gl-radio-input__option {
      margin-right: 0; }
    .gl-radio-input--vertical .gl-radio-input__option + .gl-radio-input__option {
      margin-top: 15px; }

.gl-square-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
  max-height: none;
  padding: 0;
  margin: 0 -5px -5px 0; }
  .gl-square-list .gl-menu__element {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    line-height: 1.1;
    position: relative;
    cursor: pointer;
    height: 40px;
    width: auto;
    min-width: 40px;
    padding: 0 14px;
    margin: 0 5px 5px 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9dcdd;
    -webkit-transition: border 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: border 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-square-list .gl-menu__element::after {
      content: '';
      display: block;
      background-color: #d9dcdd;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -1px;
      left: 0;
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
      transition: -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
      transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);
      transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1), -webkit-transform 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-square-list .gl-menu__element:active, .gl-square-list .gl-menu__element:hover, .gl-square-list .gl-menu__element:focus {
      outline: none; }
      .gl-square-list .gl-menu__element:active::after, .gl-square-list .gl-menu__element:hover::after, .gl-square-list .gl-menu__element:focus::after {
        background-color: #000000;
        -webkit-transform: scaleY(1);
                transform: scaleY(1); }
  .gl-square-list .gl-menu__item--active .gl-menu__element, .gl-square-list .gl-menu__item--active .gl-menu__element:active, .gl-square-list .gl-menu__item--active .gl-menu__element:hover, .gl-square-list .gl-menu__item--active .gl-menu__element:focus {
    border-color: #000000; }
    .gl-square-list .gl-menu__item--active .gl-menu__element::after, .gl-square-list .gl-menu__item--active .gl-menu__element:active::after, .gl-square-list .gl-menu__item--active .gl-menu__element:hover::after, .gl-square-list .gl-menu__item--active .gl-menu__element:focus::after {
      background-color: #000000;
      -webkit-transform: scaleY(1);
              transform: scaleY(1); }
  .gl-square-list--condensed .gl-menu__element {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: .5px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: normal;
    padding: 0 5px; }

/**
 * Star rating
 */
.gl-star-rating {
  display: inline-flex;
  color: #000000; }
  .gl-star-rating__star {
    height: 1em;
    width: 1em;
    fill: #ffffff; }

/**
 * Tables
 * 1. Default Styles
 * 2. Horizontal Tables
 * 3. Animations
 */
/**
 * 1. Default Styles
 */
.gl-table {
  /**
  * 2. Horizontal Tables
  */ }
  .gl-table table {
    border-collapse: collapse; }
  .gl-table th,
  .gl-table td {
    min-width: 80px;
    height: 60px;
    border-style: solid;
    border-width: 1px;
    border-color: #ebedee; }
  .gl-table__content {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; }
  .gl-table .gl-table__label {
    position: relative;
    padding: 0 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #000000;
    text-align: center;
    background-color: #ebedee;
    border-color: #d9dcdd; }
  .gl-table .gl-table__highlight-cell {
    color: #ffffff;
    background-color: #000000;
    border: 2px solid #000000; }
  .gl-table .gl-table__highlight-column {
    border-left: 2px solid #000000;
    border-right: 2px solid #000000; }
  .gl-table .gl-table__highlight-row {
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000; }
  .gl-table .gl-table__highlight-label {
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    background-color: #000000;
    color: #ffffff;
    border: 2px solid #000000; }
  .gl-table .gl-table__row-label {
    color: #000000;
    text-transform: uppercase; }
  .gl-table__select {
    display: block;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer; }
  .gl-table__switcher .icon-arrow-down {
    padding-left: 10px; }
  .gl-table__legend {
    margin: 0; }
  .gl-table--horizontal {
    margin-bottom: 0; }
  .gl-table--horizontal .gl-table__scrollable {
    margin-bottom: 0; }
    .gl-table--horizontal .gl-table__scrollable th,
    .gl-table--horizontal .gl-table__scrollable td {
      white-space: nowrap; }
    .gl-table--horizontal .gl-table__scrollable[data-blink='true'] {
      -webkit-animation: gl-anim-move-x 3s 1;
              animation: gl-anim-move-x 3s 1; }
      .gl-table--horizontal .gl-table__scrollable[data-blink='true']:before {
        background-color: #000000;
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        -webkit-animation: gl-anim-blink 3s 1;
                animation: gl-anim-blink 3s 1; }
  .gl-table--horizontal .gl-table__container {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row; }
  .gl-table--horizontal .gl-table__fixed-columns {
    background-color: #ffffff;
    flex: 0 1 auto;
    white-space: nowrap;
    width: auto; }
    .gl-table--horizontal .gl-table__fixed-columns table {
      width: 100%;
      border-left: none;
      border-right: 2px solid #000000; }
  .gl-table--horizontal .gl-table__scrollable-columns {
    flex: 100% 1 1;
    overflow-x: auto;
    margin-bottom: 0;
    -webkit-overflow-scrolling: touch; }

/**
 * 3. Animations
 */
@-webkit-keyframes gl-anim-move-x {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  50% {
    -webkit-transform: translate3d(-10%, 0, 0);
            transform: translate3d(-10%, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }
@keyframes gl-anim-move-x {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  50% {
    -webkit-transform: translate3d(-10%, 0, 0);
            transform: translate3d(-10%, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes gl-anim-blink {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.2; }
  90% {
    opacity: 0; }
  100% {
    display: none; } }

@keyframes gl-anim-blink {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.2; }
  90% {
    opacity: 0; }
  100% {
    display: none; } }

/**
 * Textareas
 */
.gl-textarea {
  font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border: 1.01565px solid #98989a;
  width: 100%;
  margin: 0;
  -webkit-transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  padding: 15px 31px 15px 21px;
  resize: none; }
  .gl-textarea:focus {
    border-color: #000000;
    box-shadow: 0 1px #000000; }
  .gl-form-item--error .gl-textarea {
    border-color: #e63f3f;
    box-shadow: 0 1px #e63f3f; }
  .gl-textarea::-webkit-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  .gl-textarea::-moz-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  .gl-textarea:-ms-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  .gl-textarea::-ms-input-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  .gl-textarea::placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  .gl-textarea::-webkit-input-placeholder, .gl-textarea:-ms-input-placeholder, .gl-textarea::-ms-input-placeholder, .gl-textarea:-moz-placeholder {
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000; }
  .gl-textarea__label {
    display: flex;
    font-size: 16px;
    letter-spacing: 1px;
    /* stylelint-disable-line  */
    color: #98989a;
    position: absolute;
    pointer-events: none;
    text-transform: uppercase;
    left: 21px;
    top: 14px;
    -webkit-transition: .2s ease all;
    transition: .2s ease all; }
    .gl-form-item--hasFocus .gl-textarea__label,
    .gl-form-item--has-focus .gl-textarea__label {
      font-size: 14px;
      top: -11px;
      left: 17px;
      margin-top: 0;
      padding: 2px 5px 1px;
      background-color: #ffffff; }
  .gl-textarea__icon {
    position: absolute;
    top: 10px;
    right: 10px; }
    .gl-form-item--error .gl-textarea__icon {
      color: #e63f3f; }
    .gl-form-item--success .gl-textarea__icon {
      color: #00aa55; }

/**
 * Time
 * 1. General Styles
 */
.gl-time-progress {
  text-align: center; }
  .gl-time-progress__bar {
    background: url(/static/media/bg-pattern-dashed.ec0169c5.svg) top left repeat;
    background-size: 7px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    opacity: .5; }
  .gl-time-progress .gl-box__content {
    padding: 15px 10px 17.5px; }
  @media (min-width: 600px) {
    .gl-time-progress .gl-box__content {
      padding: 30px 20px 35px; } }

/**
 * Toggle
 */
.gl-toggle {
  display: flex;
  padding: 0;
  margin: 0 0 20px 0; }
  .gl-toggle .gl-menu__item {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    line-height: 16px;
    position: relative;
    list-style: none;
    text-align: center;
    border: 1px solid #d9dcdd;
    margin-left: -1px; }
    .gl-toggle .gl-menu__item:first-child {
      margin-left: 0; }
    .gl-toggle .gl-menu__item--active {
      border-color: #000000;
      box-shadow: inset 0 -2px 0 #000000;
      z-index: 1;
      position: relative; }
      .gl-toggle .gl-menu__item--active .gl-menu__element {
        color: #000000; }
  .gl-toggle .gl-menu__element {
    width: 100%;
    padding: 16px 10px;
    color: #98989a;
    cursor: pointer;
    -webkit-transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
    .gl-toggle .gl-menu__element:focus, .gl-toggle .gl-menu__element:hover {
      color: #000000; }

/**
 * Tooltips
 */
.gl-tooltip {
  position: relative;
  margin-left: 10px;
  display: inline-block;
  /**
    * 2. Tooltip icon
    */ }
  .gl-tooltip__panel {
    position: absolute;
    z-index: 2;
    background-color: #ebedee;
    text-align: left;
    text-align: start;
    /* stylelint-disable-line declaration-block-no-duplicate-properties */
    width: 290px;
    max-width: calc(100vw - 40px); }
    .gl-tooltip__panel--small {
      width: 200px; }
    .gl-tooltip__panel--large {
      width: 500px; }
  .gl-tooltip__arrow {
    position: absolute;
    overflow: hidden;
    width: 22px;
    height: 18px;
    z-index: 3; }
    .gl-tooltip__arrow::after {
      content: '';
      position: absolute;
      bottom: auto;
      display: block;
      width: 0;
      border-style: solid;
      border-width: 0 11px 18px 11px;
      border-color: #ebedee transparent; }
    .gl-tooltip__arrow--right {
      left: -20px;
      -webkit-transform: rotate(-90deg) translateX(-2px);
              transform: rotate(-90deg) translateX(-2px); }
    .gl-tooltip__arrow--left {
      right: -20px;
      -webkit-transform: rotate(90deg) translateX(-2px);
              transform: rotate(90deg) translateX(-2px); }
    .gl-tooltip__arrow--top {
      bottom: -18px;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .gl-tooltip__arrow--bottom {
      top: -18px;
      -webkit-transform: translateX(-1px);
              transform: translateX(-1px); }
  .gl-tooltip__inner {
    padding: 20px;
    min-width: 200px; }
    .gl-tooltip__inner p {
      margin-bottom: 0;
      line-height: 20px; }
  .gl-tooltip__title,
  .gl-tooltip h5 {
    font-size: 18px;
    line-height: 16px;
    font-family: "AdihausDIN", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0;
    margin-bottom: 10px; }
    @media (min-width: 1280px) {
      .gl-tooltip__title,
      .gl-tooltip h5 {
        font-size: 20px;
        line-height: 18px; } }
  .gl-tooltip__button {
    letter-spacing: 0; }
    .gl-tooltip__button p:last-child {
      margin-bottom: 0; }
  .gl-tooltip--with-icon .gl-tooltip__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 2px;
    background-color: #ebedee;
    cursor: pointer;
    -webkit-transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1);
    margin-bottom: 0; }
    .gl-tooltip--with-icon .gl-tooltip__button:focus, .gl-tooltip--with-icon .gl-tooltip__button:hover {
      color: #98989a; }
  .gl-tooltip__icon {
    max-width: 100%;
    max-height: 100%; }

/**
 * 4.  Misc
 */
/**
 * Misc
 * 1. Fetched content
 */
/**
 * 1. Fetched content
 */
.gl-fetched-content ul {
  list-style: disc inside; }
  .gl-fetched-content ul li {
    padding-left: 12px; }

.gl-fetched-content ol {
  list-style: decimal inside; }
  .gl-fetched-content ol li {
    padding-left: 12px; }

.gl-fetched-content a {
  display: inline;
  vertical-align: bottom;
  padding-left: 1px;
  padding-right: 1px;
  color: #000000;
  text-decoration: underline;
  cursor: pointer;
  -webkit-transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1);
  transition: color 0.1s cubic-bezier(0.3, 0, 0.45, 1), background-color 0.1s cubic-bezier(0.3, 0, 0.45, 1); }
  @media (pointer: fine) {
    .gl-fetched-content a:not(.gl-cta--disabled):focus:not(:active), .gl-fetched-content a:not(.gl-cta--disabled):hover:not(:active) {
      color: #ffffff;
      background-color: #000000; } }
  @supports (-moz-appearance: none) {
    .gl-fetched-content a:not(.gl-cta--disabled):focus:not(:active), .gl-fetched-content a:not(.gl-cta--disabled):hover:not(:active) {
      color: #ffffff;
      background-color: #000000; } }
  .gl-fetched-content a:active {
    color: #ffffff;
    background-color: #000000; }
  .gl-fetched-content a .gl-icon {
    position: relative;
    top: 1px;
    margin-right: 7px; }

.gl-fetched-content hr {
  border: 0;
  margin: 0 0 20px 0;
  border-bottom: 1px;
  border-bottom-color: #d9dcdd;
  border-bottom-style: solid;
  border-bottom-color: #98989a; }

.gl-fetched-content h1 {
  font-size: 36px;
  line-height: 32px; }
  @media (min-width: 1280px) {
    .gl-fetched-content h1 {
      font-size: 42px;
      line-height: 38px; } }

.gl-fetched-content h2 {
  font-size: 26px;
  line-height: 24px; }
  @media (min-width: 1280px) {
    .gl-fetched-content h2 {
      font-size: 30px;
      line-height: 28px; } }

.gl-fetched-content h3,
.gl-fetched-content h4 {
  font-size: 18px;
  line-height: 16px; }
  @media (min-width: 1280px) {
    .gl-fetched-content h3,
    .gl-fetched-content h4 {
      font-size: 20px;
      line-height: 18px; } }

body {
  overflow-y: auto; }

.svgiconsprite {
  display: none; }

.gl-cta--hide-arrow .arrow-right-long,
.gl-cta--hide-arrow .gl-icon {
  display: none; }

.gl-cta--margin-left-top {
  margin-left: 1%;
  margin-top: 1%; }

.gl-cta--margin-right-top {
  margin-right: 1%;
  margin-top: 1%; }

.thumbnail__wrapper {
  position: relative;
  margin: auto;
  width: 120px;
  height: 80px;
  display: block;
  background-color: #ebedee;
  cursor: pointer; }

.thumbnail__wrapper:hover .image__wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 2;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000000ba), to(#ffffff00));
  background-image: -webkit-linear-gradient(#000000ba, #ffffff00);
  background-image: linear-gradient(#000000ba, #ffffff00); }

.thumbnail__wrapper:hover .thumbnail__action-buttons {
  display: block; }

.thumbnail__wrapper .image__wrapper--video::after {
  content: url(/static/media/play-solid.72c0b5ac.svg);
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.thumbnail__wrapper:hover .image__wrapper:after {
  content: url(/static/media/search.8297cd8d.svg);
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.thumbnail__wrapper .image__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  margin: auto; }

.list--noitems {
  margin: 20px 0; }
  .list--noitems .gl-callout {
    max-width: 100%;
    text-align: center; }

.thumbnail__action-buttons {
  display: none;
  z-index: 2;
  position: absolute;
  width: auto;
  height: auto;
  padding-top: 5px;
  top: 0; }

.paper-effect {
  background-color: #fff;
  position: relative;
  margin: 2px 10px 3px 4px;
  padding: 0;
  display: inline-block;
  width: auto; }

.paper-effect,
.paper-effect::before,
.paper-effect::after {
  /* Add shadow to distinguish sheets from one another */
  box-shadow: 2px 1px 1px rgba(152, 152, 154, 0.5); }

.paper-effect::before,
.paper-effect::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eee; }

/* Second sheet of paper-effect */
.paper-effect::before {
  left: 3px;
  top: 2px;
  z-index: -1; }

/* Third sheet of paper-effect */
.paper-effect::after {
  left: 6px;
  top: 5px;
  z-index: -2; }

.list--error {
  margin: 20px 0; }
  .list--error .gl-callout {
    max-width: 100%;
    text-align: center; }
    .list--error .gl-callout .callout-error__text {
      padding-top: 20px; }

.gl-modal {
  z-index: 5; }

.main-container {
  margin: 20px; }

.expiration-badge {
  padding: 5px 10px 3px;
  font-size: 10px;
  text-align: center;
  margin-top: 5px;
  display: inline-block;
  text-transform: uppercase; }
  .expiration-badge--expired {
    background: #e63f3f;
    color: #ffffff; }
  .expiration-badge--expires-soon {
    background: #ede734;
    color: #000000; }

.link-button {
  cursor: pointer; }

.ellipsis {
  width: 100%;
  overflow: hidden;
  display: inline-block !important;
  text-overflow: ellipsis;
  white-space: nowrap; }

.ellipsisPreview {
  width: 1000px; }

.previewLoader {
  width: 30vw; }

.capitalize {
  text-transform: capitalize; }

.text-center {
  text-align: center; }

.m-auto {
  margin: auto; }

.linkButton {
  color: #40846f !important;
  cursor: pointer; }

.breakWord {
  word-break: break-all; }

.marginLeft {
  margin-left: 1%; }

.marginTop {
  margin-top: 1%; }

.tooltipWidth {
  width: auto;
  word-break: break-all; }

.usageTooltip {
  width: auto; }

.tippy-tooltip[data-animatefill] {
  background-color: #333; }

.discList {
  list-style-type: disc;
  text-align: left;
  margin-left: 5px;
  word-break: break-all;
  min-width: 100%; }

.showMore .gl-tooltip {
  margin: 0;
  margin-top: 5px; }

.mt-0 {
  margin-top: 0 !important; }

.generic-container {
  position: relative;
  margin: 20px; }

.page-title {
  margin-bottom: 22px; }

.filter-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }

svg {
  pointer-events: none; }

.schedule-change-save .gl-loader {
  width: 20px;
  height: 20px; }

.info-icon {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  margin-top: -5px; }

.custom-checkbox {
  cursor: pointer;
  font-size: 14px; }
  .custom-checkbox__icon {
    margin: 0 14px 0 0;
    vertical-align: middle;
    width: 24px;
    height: 24px; }
  .custom-checkbox__label {
    line-height: 24px; }
  .custom-checkbox--disabled {
    opacity: 0.6; }

/* IE 11 specific css should go here */
@media all and (-ms-high-contrast: none) {
  .modal-items__preview {
    display: block !important; }
  h1,
  h2,
  h3,
  h4 {
    padding-top: 3px !important; }
  .gl-table .gl-table__label {
    position: inherit !important; }
  .generic-container {
    margin: 20px; } }

.tippy-popper {
  max-width: 800px !important; }

.core-green__main {
  color: #40846f; }

